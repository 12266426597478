/* flat css used for rtd preview buttons */

/*
<div class="togglebutton">
  <input id="togglebutton-test" type="checkbox" name="test" />
  <label class="togglebutton__label" for="togglebutton-test"></label>
</div>
*/
.togglebutton__label
{
  display: block;
  color: #fff;
  font-size: 10px;
  line-height: 30px;
  background-color: #6F7490;
  text-transform: uppercase;
  border-radius: 15px;
  position: relative;
  height: 30px;
  width: 60px;
  cursor: pointer;
  transition: background-color 0.2s;
}
.togglebutton__label::after
{
  content: "";
  display: block;
  position: absolute;
  left: 2px;
  top: 2px;
  border-radius: 50%;
  height: 26px;
  width: 26px;
  background-color: #fff;
  transition: left 0.2s;
}
.togglebutton input[type='checkbox'][disabled] + .togglebutton__label
{
  cursor: default;
  opacity: 0.3;
}
.togglebutton input[type='checkbox']:checked + .togglebutton__label
{
  background-color: #ACC000;
}
.togglebutton input[type='checkbox']:checked + .togglebutton__label::after
{
  left: 32px;
}
.togglebutton input[type='checkbox']:not([disabled]) + .togglebutton__label:hover
{
  background-color: #454B6E;
}
.togglebutton input[type='checkbox']:not([disabled]):checked + .togglebutton__label:hover
{
  background-color: #687500;
}


.wh-form__buttongroup
{
  text-align: right;
  padding: 30px 0 20px;
  margin-top: 30px;
  border-top: 1px solid #C5C5C5;
}
.wh-form__buttongroup::after
{
  content: "";
  display: block;
  clear: both;
}

.wh-form__button
{
  min-width: 100px;
}
.wh-form__button + .wh-form__button
{
  margin-left: 15px;
}

.button, .wh-form__button
{
  text-decoration: none;
  color: inherit;
  font: inherit;
  font-weight: 700;
  display: inline-block;
  border-radius: 21px;
  height: 42px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  line-height: 20px;
  padding: 10px 20px;
  background-color: rgba(172, 192, 0, 0.6);  /* $color_color1_light */
  color: #181f4b; /* $color_grey1 */
  text-align: left;
  transition: background-color 0.3s;
  -webkit-appearance: none;
  margin: 0;
  border: 0 none;
  transition: background-color 0.3s;
}
.button--blue
{
  background-color: #181f4b;
  color: #fff;
}

.button:hover, .wh-form__button:hover
{
  background-color: #acc000; /* $color_color1_light */
}

.wh-form__buttonlabel, .button__label
{
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wh-form__button--previous
{
  background-color: transparent;
  padding: 0;
  height: 20px;
  min-width: 0;
  margin-right: 15px;
}
.wh-form__button--previous .wh-form__buttonlabel
{
  text-decoration: underline;
  color: #6F7490; /* $color_grey3 */
}

.wh-form__button--previous:hover
{
  background-color: transparent;
}
.wh-form__button--previous:hover .wh-form__buttonlabel
{
  color: #181f4b; /* $color_grey1 */
}

.button--outline, .cta .button
{
  border: 2px solid #181f4b; /* $color_grey1 */
  color: #181f4b; /* $color_grey1 */
  background-color: transparent;
  font-weight: 700;
  padding: 9px 25px 7px;
  transition: color 0.3s, background-color 0.3s;
}
.button--outline:hover, .cta .button:hover
{
  background-color: #181f4b; /* $color_grey1 */
  color: #fff;
}
