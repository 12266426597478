.homeslideshow {
  position: relative;
  min-height: 570px;
}
@media (max-width: 1189px) {
  .homeslideshow {
    min-height: 48vw;
  }
}
.homeslideshow__slide {
  position: absolute;
  top: 0;
  right: 35px;
  bottom: 0;
  left: 35px;
}
.homeslideshow__slide__container {
  position: absolute;
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: flex-start;
  top: 0;
  right: 50%;
  margin-right: 17px;
  left: 0;
  bottom: 0;
  padding-bottom: 130px;
}
.homeslideshow__slide__container .title {
  font: 700 42px/120% "Noto Serif", sans-serif, serif, Arial;
  max-width: 100%;
}
@media (max-width: 800px) {
  .homeslideshow__slide__container .title {
    font-size: 5.25vw;
  }
}
@media (max-width: 500px) {
  .homeslideshow__slide__container .title {
    font-size: 26.25px;
  }
}
.homeslideshow__slide__container .title + .description {
  margin-top: 25px;
}
.homeslideshow__slide__container .description {
  max-width: 100%;
  font-size: 18px;
  line-height: 140%;
  max-height: 152px;
  flex: none;
  overflow: hidden;
}
.homeslideshow__slide__container .description span {
  display: inline-block;
}
.homeslideshow__slide__image {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: 18px;
  margin-top: -130px;
  transform: translateY(-50%);
  width: 85%;
  background-size: cover;
  border-radius: 50%;
  z-index: -1;
  overflow: hidden;
}
.homeslideshow__slide__image > span {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin-top: 11%;
}
@media (max-width: 1940px) {
  .homeslideshow__slide__image > span {
    margin-left: calc(20.8vw - 42.5%);
  }
}
.homeslideshow__slide__image::before {
  content: "";
  display: block;
  padding-top: 100%;
}
.homeslideshow__slide__navigation {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.homeslideshow__slide__navigation .button {
  margin-right: 20px;
}
.homeslideshow .jumpbuttons {
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 35px;
  bottom: 10px;
  z-index: 1;
  height: 42px;
  padding: 5px 10px;
  background-color: #454b6e;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.homeslideshow .jumpbuttons > button {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font: inherit;
  display: inline-block;
  margin: 0 5px;
  border: 0 none;
  color: #fff;
}
.homeslideshow .jumpbuttons > button.jumpbutton {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid #454b6e;
  transition: border-color 0.3s;
}
.homeslideshow .jumpbuttons > button.jumpbutton:hover, .homeslideshow .jumpbuttons > button.jumpbutton.active, .homeslideshow .jumpbuttons > button.jumpbutton:focus {
  border-color: #fff;
}
.homeslideshow .jumpbuttons > button.next {
  font-size: 24px;
}
.homeslideshow .jumpbuttons > button.next:hover {
  text-shadow: 0 0 1px #fff;
}
.homeslideshow:hover .jumpbuttons > li {
  animation: blink-animation 2s linear infinite;
}
@media (max-width: 750px) {
  .homeslideshow {
    margin-top: 150px;
  }
  .homeslideshow__slide {
    right: 20px;
    left: 20px;
  }
  .homeslideshow__slide__image {
    left: 30%;
    width: 100%;
    margin-top: -200px;
  }
  .homeslideshow__slide__image > span {
    margin-top: 26.5%;
    margin-left: calc(30vw - 50%);
  }
  .homeslideshow__slide__container {
    background-color: #fff;
    border-radius: 0 4px 4px 0;
    top: auto;
    bottom: 0;
    padding: 35px 15px 35px 15px;
    right: 0;
    margin-right: 0;
  }
  .homeslideshow__slide__container .description {
    display: none;
  }
  .homeslideshow__slide__container .button {
    background-color: transparent !important;
    color: #687500;
    padding: 10px 0;
    text-decoration: underline;
  }
  .homeslideshow__slide__navigation {
    width: 100%;
    margin-top: 15px;
    justify-content: space-between;
  }
}
@media (max-width: 465px) {
  .homeslideshow__slide__container {
    padding-left: 0;
  }
}

@keyframes blink-animation {
  50% {
    border-color: #fff;
  }
}