@import url('//fonts.googleapis.com/css2?family=Noto+Serif:wght@400;700&family=Open+Sans:ital,wght@0,400;0,600;0,700;1,400;1,700&display=swap');

html
{
  font: 15px 'Open Sans', sans-serif, serif, Arial !important; /* need to overwrite font on blackboard page */
  color: #181f4b;
}

h1, .heading1
{
  font: 700 42px/120% 'Noto Serif', sans-serif, serif, Arial;
  margin-bottom: 20px;
  hyphens: auto;
}
h2, .heading2
{
  margin-bottom: 10px;
  font-size: 30px;
  line-height: 120%;
  font-weight: 700;
}
h3, .heading3
{
  margin-bottom: 5px;
  font-size: 20px;
  line-height: 125%;
  font-weight: 700;
}
h4, .heading4
{
  font-size: 18px;
  line-height: 140%;
  margin-bottom: 5px;
  font-weight: 700;
}

p a, ul a, ol a, table a, form a
{
  color: inherit;
}

main > p.quote
{
  padding-left: 65px; /* desktop gridgap + 30px */
}
p.quote::before
{
  content: "";
  display: block;
  border-left: 1px solid #ACC000;
  position: absolute;
  top: 0;
  height: 100%;
  padding-left: 35px; /* always have gridgap */
  left: 0;
}

p.quote--withsource
{
  position: relative;
}
.quote__spacer
{
  display: inline-block;
}
.quote__source
{
  padding-left: 10px;
  font: 12px/16px 'Open Sans', sans-serif, serif, Arial;
  display: inline-block;
  position: absolute;
  max-width: calc(100% - 30px);
  bottom: 3px;
  right: 0;
  color: #6F7490;
}


p, .widget
{
  margin-bottom: 30px;
  line-height: 165%;
}

p + ul.unordered, p + ol.ordered
{
  margin-top: -25px; /* compensate p bottom margin */
}

  p.intro
, .sectionstartpage-intro ul.unordered
, .sectionstartpage-intro ol.ordered
{
  font-size: 18px;
  line-height: 140%;
}

  p.intro a
, p.normal a
, p.small a
{
  color: #2d789e;
}

p.small
{
  font-size: 12px;
  line-height: 135%;
}

p.quote
{
  position: relative;
  font: 400 20px/175% 'Noto Serif', sans-serif, serif, Arial;
  padding-left: 30px;
  font-style: italic;
}

sup
{
  vertical-align: top;
  line-height: 100%;
}

sub
{
  vertical-align: bottom;
  line-height: 100%;
}

/* inline images */
.wh-rtd__img
{
  max-width: 100%;
  height: auto;
  border-radius: 4px;
}

  .main__col > ul.unordered
, .main__col > ol.ordered
, .sectionstartpage-intro__col > ul.unordered
, .ectionstartpage-intro__col > ol.ordered
{
  margin-left: 0;
  margin-right: 0;
  padding-left: 10px;
  padding-right: 0;
}

/* lists */
  ul.unordered
, ol.ordered
, ul.unordered ul
, ol.ordered ul
, ul.unordered ol
, ol.ordered ol
{
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 0;
  padding-top: 0;
  line-height: 160%;
  list-style-type: none;
}
  ul.unordered
, ol.ordered
{
  margin-bottom: 20px;
}
  article * ul.unordered
, article * ol.ordered
{
  margin-left: 0;
  margin-right: 0;
  padding-right: 0;
  padding-left: 0;
}
  ul.unordered li
, ol.ordered li
{
  position: relative;
  padding: 0 0 10px 20px;
  min-height: 15px;
}
  ul.unordered ul
, ol.ordered ul
, ul.unordered ol
, ol.ordered ol
{
  margin-top: 15px;
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
}
  ul.unordered li::before
, ol.ordered li::before
{
  color: #181f4b;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
}
  ul.unordered > li
, ol.ordered ul > li
, ul.unordered ul > li
{
  padding-left: 15px;
  padding-bottom: 5px;
  min-height: 15px;
}
  ul.unordered > li::before
, ol.ordered ul > li::before
, ul.unordered ul > li::before
{
  padding-top: 2px;
  content: '•';
  text-align: center;
}
  ol.ordered
, ul.unordered ol
, ol.ordered ol
{
  counter-reset: li;
}
  ol.ordered > li:before
, ul.unordered ol > li:before
, ol.ordered ol > li:before
{
  content: counter(li) ".";
  counter-increment: li;
}

/* tables */
.wh-rtd__tablewrap
{
  overflow-x: auto;
  margin-bottom: 20px;
  max-width: 100%;
}
article > .wh-rtd__tablewrap
{
  max-width: 790px;
}
.wh-rtd__table td, .wh-rtd__table th
{
  vertical-align: top;
  text-align: left;
  padding: 5px 10px;
}
  .wh-rtd__table td > *:last-child
, .wh-rtd__table th > *:last-child
{
  margin-bottom: 0;
}
  .wh-rtd--hascolheader > *
, .wh-rtd--hasrowheader th
{
  font-weight: bold;
}
.wh-rtd__table tr > *
{
  border-bottom: 1px solid #181f4b;
}
.wh-rtd__table .wh-rtd--hascolheader > *
{
  border-bottom: 2px solid #181f4b;
}
