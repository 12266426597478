.campaignpage-header {
  background-color: #f3f6d9;
  border-radius: 4px;
  min-height: 450px;
  padding-top: 30px;
  margin-bottom: 50px;
  position: relative;
}
.campaignpage-header--blue {
  background-color: #e6f1f9;
}
.campaignpage-header--orange {
  background-color: #fbf2dd;
}
.campaignpage-header--coldgreen {
  background-color: #f1f7f3;
}
.campaignpage-header--purple {
  background-color: #eeedf5;
}
@media (max-width: 1189px) {
  .campaignpage-header {
    max-width: 100%;
  }
}
.campaignpage-header__content {
  display: flex;
  justify-content: space-between;
}
.campaignpage-header__text {
  padding-top: 20px;
  flex: none;
  width: 44%;
}
.campaignpage-header__image {
  margin-bottom: -70px;
  flex: none;
  width: 44%;
  border-radius: 50%;
  position: relative;
  align-self: flex-start;
}
.campaignpage-header__image img {
  width: 100%;
  height: auto;
  border-radius: 50%;
  vertical-align: bottom;
}
.campaignpage-header__video {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
}
.campaignpage-header__video::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.campaignpage-header__video iframe {
  height: 100%;
  aspect-ratio: 16/9;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
}
.campaignpage-header__videobtn {
  position: absolute;
  top: 0;
  right: 0;
  width: 80px;
  height: 80px;
  transform: translate(-20px, 20px);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #acc000;
  cursor: pointer;
}
.campaignpage-header__videobtn::before {
  content: "";
  display: block;
  border-top: 12px solid transparent;
  border-left: 22px solid #fff;
  border-right: 0;
  border-bottom: 12px solid transparent;
  margin-left: 5px;
}
.campaignpage-header--blue .campaignpage-header__videobtn {
  background-color: #599dd7;
}
.campaignpage-header--orange .campaignpage-header__videobtn {
  background-color: #e3a51a;
}
.campaignpage-header--coldgreen .campaignpage-header__videobtn {
  background-color: #9fc8ad;
}
.campaignpage-header--purple .campaignpage-header__videobtn {
  background-color: #8d84bb;
}
.campaignpage-header__button {
  margin-bottom: 35px;
}
.campaignpage-header__button a {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  max-width: 100%;
}
.campaignpage-header__button a i {
  flex: none;
  font-size: 24px;
  margin-left: 5px;
  height: 42px;
  width: 42px;
  line-height: 42px;
  border-radius: 50%;
  text-align: center;
  color: #6e7a00;
  background-color: #fff;
  transition: background-color 300ms;
  display: inline-block;
}
@media (max-width: 400px) {
  .campaignpage-header__button a i {
    display: none;
  }
}
.campaignpage-header__button a:hover .button {
  background-color: #acc000;
}
.campaignpage-header__button a:hover i {
  background-color: #f9f9f9;
}
@media (min-width: 751px) {
  html.showmobilenav .campaignpage-header {
    background-color: transparent;
    min-height: 0;
    padding-top: 0;
    margin-bottom: 30px;
  }
  html.showmobilenav .campaignpage-header__content {
    display: block;
  }
  html.showmobilenav .campaignpage-header__text {
    width: 60%;
  }
  html.showmobilenav .campaignpage-header__image {
    margin-bottom: 0;
    width: 492px;
    position: absolute;
    top: -100px;
    right: 0;
    z-index: 0;
    transform: translate(35%, -25%);
  }
  html.showmobilenav .campaignpage-header__videobtn {
    right: auto;
    top: auto;
    bottom: 0;
    left: 0;
    transform: translate(20px, -20px);
  }
  html.showmobilenav .campaignpage-header__button {
    margin-bottom: 0;
  }
  html.showmobilenav .campaignpage-header__button i {
    background-color: #f3f6d9;
  }
  html.showmobilenav .campaignpage-header__button a:hover i {
    background-color: #e3e6c9;
  }
}
@media (max-width: 750px) {
  .campaignpage-header {
    background-color: transparent;
    min-height: 0;
    padding-top: 0;
    margin-bottom: 0;
    overflow: hidden;
  }
  .campaignpage-header__content {
    display: block;
  }
  .campaignpage-header__text {
    padding-top: 402px;
    width: 100%;
  }
  .campaignpage-header__image {
    margin-bottom: 0;
    width: 492px;
    max-width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;
    transform: translate(35%, -20%);
  }
  .campaignpage-header__videobtn {
    right: auto;
    top: auto;
    bottom: 0;
    left: 0;
    transform: translate(20px, -20px);
  }
  .campaignpage-header__button {
    margin-bottom: 0;
  }
  .campaignpage-header__button i {
    background-color: #f3f6d9;
  }
  .campaignpage-header__button a:hover i {
    background-color: #e3e6c9;
  }
}
@media (max-width: 492px) {
  .campaignpage-header__text {
    padding-top: 83vw;
  }
}