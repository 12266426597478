.widget-bijbelblog {
  margin-top: 60px;
  position: relative;
  line-height: 25px;
}
.widget-bijbelblog__columns {
  display: flex;
}
.widget-bijbelblog__columns a {
  color: #4376a1;
}
.widget-bijbelblog__columns__col {
  flex: 1 0 33.33333%;
  max-width: 33.33333%;
  border-bottom: 1px solid #e7e7ec;
  padding-bottom: 45px;
}
.widget-bijbelblog__columns__col:first-child {
  border-left: 1px solid #e7e7ec;
  border-radius: 4px 0 0 4px;
}
.widget-bijbelblog__columns__col:first-child > div:first-child, .widget-bijbelblog__columns__col:first-child > div:first-child > img {
  border-radius: 4px 0 0 0;
}
.widget-bijbelblog__columns__col:last-child {
  border-right: 1px solid #e7e7ec;
  border-radius: 0 4px 4px 0;
}
.widget-bijbelblog__columns__col:last-child > div:first-child, .widget-bijbelblog__columns__col:last-child > div:first-child > img {
  border-radius: 0 4px 0 0;
}
.widget-bijbelblog__columns__col--infotext {
  text-align: center;
  font-family: "Noto Serif", sans-serif, serif, Arial;
  background-color: #ECC160;
  font-size: 18px;
  font-style: italic;
  padding: 30px 30px 45px;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  border-bottom: 0;
}
.widget-bijbelblog__columns__col--infotext p {
  max-width: 268px;
  margin: 0 auto;
}
@media (max-width: 900px) {
  .widget-bijbelblog__columns__col {
    flex-basis: 50%;
    max-width: 50%;
  }
  .widget-bijbelblog__columns__col:nth-child(3n) {
    display: none;
  }
  .widget-bijbelblog__columns__col--infotext p {
    max-width: 300px;
  }
}
@media (max-width: 600px) {
  .widget-bijbelblog__columns {
    display: block;
  }
  .widget-bijbelblog__columns__col {
    max-width: 100%;
    border-radius: 4px;
  }
  .widget-bijbelblog__columns__col div:first-child > img {
    border-radius: 4px 4px 0 0 !important;
  }
  .widget-bijbelblog__columns__col:nth-child(1n) {
    border: 1px solid #e7e7ec;
  }
  .widget-bijbelblog__columns__col:nth-child(2n) {
    margin-top: 20px;
  }
}
.widget-bijbelblog__image {
  position: relative;
}
.widget-bijbelblog__image--item {
  background: #6F7490 url(../../web/img/fallback.png) no-repeat 50% 50%;
  background-size: contain;
}
.widget-bijbelblog__image--item::before {
  content: "";
  display: block;
  padding-top: 50%;
}
.widget-bijbelblog__image--item img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.widget-bijbelblog__image img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}
.widget-bijbelblog__image--person {
  padding-right: 30px;
  text-align: right;
}
.widget-bijbelblog__image--person span {
  display: inline-block;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  margin-top: -32px;
  overflow: hidden;
}
.widget-bijbelblog__title {
  font-weight: 700;
  font-size: 18px;
  padding: 42px 30px 0;
}
.widget-bijbelblog__image--person + .widget-bijbelblog__title {
  padding-top: 0;
}
.widget-bijbelblog__author {
  padding: 0 30px;
}
.widget-bijbelblog__description {
  padding: 15px 30px 0;
}
.widget-bijbelblog__beeldmerk {
  width: 48px;
  height: 48px;
  margin: 30px auto 0;
}
.widget-bijbelblog__beeldmerk svg {
  width: 100%;
  height: auto;
  fill: #aa7c14;
}