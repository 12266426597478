.people-filters {
  background-color: #EEEEF1;
  border-radius: 4px;
  padding: 20px;
  margin: 40px 0;
  position: relative;
}
.people-filters__toggle {
  position: absolute;
  right: 35px;
  top: 45px;
  display: flex;
  cursor: pointer;
  transition: color 0.3s;
}
.people-filters__toggle:hover {
  color: #687500;
}
.people-filters__toggle .icon {
  flex: none;
  margin-left: 15px;
  font-size: 24px;
}
.people-filters__toggle .icon--close {
  display: none;
  transform: rotate(45deg);
}
.people-filters--advanced .icon--show {
  display: none;
}
.people-filters--advanced .icon--close {
  display: block;
}
.people-filters__search {
  background-color: #fff;
  border-radius: 4px;
  margin-right: 130px;
  height: 70px;
  padding: 20px;
  display: flex;
}
.people-filters__search button, .people-filters__search input {
  background-color: transparent;
  border: 0 none !important;
}
.people-filters__search input {
  flex: 1 1 auto;
  padding: 0 10px !important;
  min-height: 30px !important;
  max-width: calc(100% - 25px) !important;
}
.people-filters__search button {
  font-size: 24px;
  color: #687500;
  flex: none !important;
  margin-top: 3px;
}
.people-filters__advanced {
  height: 0;
  overflow: hidden;
  transition: height 0.3s;
}
.people-filters__advanced__inner {
  display: flex;
  flex-wrap: wrap;
  padding: 40px 0 20px;
}
.people-filters__advanced .wh-form__fieldgroup {
  padding-left: 20px;
  flex: 1 1 50%;
  max-width: 50%;
  margin-bottom: 0;
}
.people-filters__advanced .wh-form__fieldgroup > .wh-form__label, .people-filters__advanced .wh-form__fieldgroup > .wh-form__fields {
  flex: none !important;
  max-width: 100% !important;
  min-width: 100% !important;
}
.people-filters--advanced .people-filters__advanced {
  height: auto;
}
.people-filters input[type=radio]:checked ~ .wh-form__optiondata {
  font-weight: 700;
}
.people-filters .wh-form__fieldgroup--pulldown {
  padding-top: 25px;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  align-items: flex-start;
}
.people-filters .wh-form__fieldgroup--pulldown > .wh-form__label {
  padding-bottom: 7px;
  padding-right: 0;
}
@media (max-width: 600px) {
  .people-filters {
    margin-left: -35px;
    margin-right: -35px;
    border-radius: 0;
    padding: 20px 35px;
  }
  .people-filters__search {
    margin-right: 55px;
    padding: 5px 10px;
    height: 50px;
  }
  .people-filters__toggle {
    top: 35px;
  }
  .people-filters__toggle .label {
    display: none;
  }
  .people-filters__advanced {
    margin-left: -20px;
  }
  .people-filters__advanced__inner {
    padding: 30px 0 0;
  }
  .people-filters .wh-form__fieldgroup--pulldown {
    padding-top: 15px;
  }
}
@media (max-width: 500px) {
  .people-filters__advanced__inner {
    display: block;
  }
  .people-filters__advanced__inner .wh-form__fieldgroup {
    max-width: 100%;
  }
  .people-filters__advanced__inner .wh-form__fieldgroup + .wh-form__fieldgroup {
    padding-top: 15px;
  }
}
@media (max-width: 465px) {
  .people-filters {
    margin-left: -20px;
    margin-right: -20px;
    padding: 20px;
  }
  .people-filters__search {
    margin-right: 45px;
  }
  .people-filters__toggle {
    right: 20px;
  }
}

.people-results ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.people-results .person {
  display: flex;
  text-decoration: none;
  color: inherit;
  padding: 10px 30px;
  background-color: #fff;
  transition: background-color 0.3s;
  border-radius: 4px;
  align-items: center;
}
.people-results .person:hover {
  background-color: #f3f6d9;
}
.people-results .person__image {
  flex: none;
  width: 100px;
  position: relative;
  background: #EEEEF1 url(../../../pthu2020/web/img/fallback.png) no-repeat 50% 50%;
  background-size: 80% 80%;
  border-radius: 50%;
  overflow: hidden;
}
.people-results .person__image[data-loadimage], .people-results .person__image.image-loaded {
  background-image: none;
}
@media (max-width: 800px) {
  .people-results .person__image {
    width: 12.5vw;
  }
}
@media (max-width: 480px) {
  .people-results .person__image {
    width: 60px;
  }
}
.people-results .person__image::before {
  content: "";
  display: block;
  padding-top: 100%;
}
.people-results .person__image > img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.people-results .person__info {
  flex: 1 1 auto;
  padding-left: 30px;
  padding-right: 40px;
  position: relative;
}
.people-results .person .name {
  display: block;
  font-size: 18px;
  font-weight: 700;
  line-height: 145%;
}
.people-results .person .position {
  display: block;
  line-height: 165%;
}
.people-results .person .email {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 24px;
  color: #4376a1;
  transition: color 0.3s;
}
.people-results .person .email:hover {
  color: #181f4b;
}
@media (max-width: 600px) {
  .people-results .person {
    padding: 10px 0;
  }
  .people-results .person .name {
    font-size: 16px;
  }
}
@media (max-width: 465px) {
  .people-results .person__info {
    padding-left: 20px;
    padding-right: 35px;
  }
}