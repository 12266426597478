/* Some general widgets styling for elements used in multiple widgets */
html.wh-widgetpreview {
  background-color: #f0f1f2;
}
html.wh-widgetpreview .widget {
  background-color: #fff;
  max-width: 350px;
  margin: 20px auto;
}
html.wh-widgetpreview .widget[data-cols="2"] {
  max-width: 730px;
}
html.wh-widgetpreview .widget[data-cols="50%"] {
  max-width: 540px;
}
html.wh-widgetpreview .widget[data-cols="3"], html.wh-widgetpreview .widget[data-cols="100%"] {
  max-width: 1120px;
}

.widget__title {
  color: inherit;
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  margin-bottom: 0;
  padding-bottom: 20px;
  position: relative;
}
.widget__title > a {
  color: inherit;
  text-decoration: none;
  display: inline-block;
}
.widget__title > .arrow, .widget__title > a > .arrow {
  background-color: rgba(172, 192, 0, 0.15);
  width: 42px;
  height: 42px;
  display: inline-block;
  border-radius: 50%;
  font-size: 24px;
  line-height: 42px;
  text-align: center;
  margin-left: 15px;
  position: relative;
  top: 4px;
  transition: background-color 0.2s;
}
.widget__title:hover > .arrow, .widget__title > a:hover > .arrow {
  background-color: rgba(172, 192, 0, 0.3);
}
.widget a.widget__title {
  text-decoration: none;
  display: inline-block;
}