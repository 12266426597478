/* css styled pulldown */
.wh-form__pulldown-styled {
  position: relative;
  flex: 1;
}
.wh-form__pulldown-styled > select {
  padding-right: 40px !important;
  width: 100%;
  min-width: 140px;
}
.wh-form__pulldown-styled > select:disabled {
  opacity: 0.4;
}
.wh-form__pulldown-styled > select:disabled + .arrow {
  opacity: 0.4;
}
.wh-form__pulldown-styled select::-ms-expand {
  display: none; /* hide select arrow in IE */
}
.wh-form__pulldown-styled .arrow {
  font-size: 12px;
  position: absolute;
  right: 1px;
  top: 1px;
  bottom: 1px;
  width: 40px;
  color: #6F7490;
  background-color: #F4F4F7;
  border-left: 1px solid #8F93A8;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s, background-color 0.2s, border-color 0.2s;
}
.wh-form__pulldown-styled select:focus + .arrow,
.wh-form__pulldown-styled select:hover + .arrow {
  border-color: #599dd7;
  background-color: #599dd7;
  color: #fff;
}
.wh-form__pulldown-styled select[disabled] + .arrow {
  opacity: 0.2;
}