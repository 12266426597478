.navigation {
  position: absolute;
  right: 35px;
  top: 30px;
  border-radius: 4px;
}
html.showmobilenav .navigation {
  visibility: hidden;
  z-index: -1;
}
.navigation ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.navigation li > * {
  text-decoration: none;
  display: block;
  padding: 20px;
  white-space: nowrap;
  transition: color 0.2s;
}
.navigation li.active > * {
  text-shadow: 0 0 1px #181f4b;
}
.navigation li:not(.active) > *:hover, .navigation li:not(.active) > *:focus {
  color: #687500;
}
.navigation .navigation__items {
  display: flex;
  padding-left: 20px;
  overflow: hidden;
  border-radius: 4px;
  height: 70px;
  line-height: 30px;
  box-shadow: 0 15px 30px 0 rgba(24, 31, 75, 0.2);
  background-color: #fff;
}
.navigation .navigation__btn {
  margin-left: 20px;
  position: relative;
  cursor: pointer;
}
.navigation .navigation__btn:hover {
  z-index: 1;
}
.navigation .navigation__btn:last-child > * {
  padding-right: 20px;
}
.navigation .navigation__btn > * {
  position: relative;
  font-size: 24px;
  color: #fff !important;
  background-color: #6F7490;
  padding-right: 11px;
}
.navigation .navigation__btn > *::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100px;
  height: 100px;
  margin: -50px 0 0 -50px;
  background-color: #fff;
  transform: scale(0.01, 0.01);
  border-radius: 50%;
  opacity: 0;
  transform-origin: 50% 50%;
  transition: opacity 0.3s ease, transform 0.3s ease;
  pointer-events: none;
}
.navigation .navigation__btn > * i {
  position: relative;
  display: inline-block;
  transition: color 0.2s;
}
.navigation .navigation__btn > *:hover::before {
  opacity: 1;
  transform: scale(1, 1);
}
.navigation .navigation__btn > *:hover i, .navigation .navigation__btn > *:hover sup {
  color: #181f4b;
}
.navigation .navigation__btn > * sup {
  display: block;
  font-size: 9px;
  position: absolute;
  right: 2px;
  top: 16px;
  text-transform: uppercase;
}
.navigation .navigation__btn > *:hover {
  text-shadow: 0 0 1px #fff;
}
.navigation .navigation__btn + .navigation__btn {
  margin-left: 0;
}
.navigation .navigation__btn + .navigation__btn > button {
  padding-left: 11px;
}
.navigation .navigation__btn:last-child > button {
  padding-right: 20px;
}
.navigation__submenu::after {
  content: "\e90a";
  display: inline-block;
  position: absolute;
  top: 10px;
  left: 10px;
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "PThU-iconfont-12px" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  font-size: 12px;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "PThU-iconfont-24px" !important;
  font-size: 24px;
  opacity: 0;
  transition: opacity 0.3s;
}
.navigation__submenu--loading::after {
  opacity: 1;
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
}
.navigation__submenu {
  position: absolute;
  top: 130px;
  right: 35px;
  left: 35px;
  border-radius: 4px;
  box-shadow: 0 15px 30px 0 rgba(24, 31, 75, 0.2);
  background-color: #fff;
  display: none;
  transform: translateY(-30px);
  opacity: 0;
  pointer-events: none;
  transition-property: transform, opacity;
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}
html:not(.showmobilenav) .navigation__submenu--onecolumn {
  max-width: 320px;
  left: auto;
  right: auto;
}
html:not(.showmobilenav) .navigation__submenu--onecolumn.navigation__submenu--cols > .submenu-container {
  display: block;
}
html:not(.showmobilenav) .navigation__submenu--onecolumn.navigation__submenu--cols > .submenu-container > div {
  width: auto;
}
.navigation__submenu__mobilemenuwrapper {
  display: flex;
  flex-flow: column;
}
.navigation__submenu__mobilemenuwrapper > * {
  background-color: #ffffff;
}
.navigation__submenu__mobilemenuwrapper > .submenu-container {
  flex: 1 1 auto;
}
.navigation__submenu__mobilemenuwrapper > .mobilemenu__footer {
  flex: none;
}
html.showmobilenav .navigation__submenu {
  border-radius: 0;
  display: flex;
  z-index: 10;
  position: fixed;
  overflow: hidden;
  overflow-y: auto;
  pointer-events: none;
}
html.showmobilenav .navigation__submenu.active {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: auto;
}
html.showmobilenav .navigation__submenu > div {
  flex: 1 1 50%;
  max-width: 50%;
}
.navigation__submenu .mobilecta {
  position: relative;
  z-index: 1;
  background-color: #acc000;
  padding: 160px 120px 35px 35px;
  padding-right: clamp(35px, 10vw, 120px);
}
.navigation__submenu .common-linklist li > a,
.navigation__submenu .common-linklist li > span[role],
.navigation__submenu .common-linklist li > button {
  display: block;
}
.navigation__submenu .submenu--mobilestart {
  display: flex;
  flex-flow: column;
}
.navigation__submenu .submenu__mainnav {
  flex: 1 1 auto;
}
.navigation__submenu .submenu__mainnav .common-linklist {
  margin-left: 4px;
}
.navigation__submenu--cols .submenu-container > .submenu--projects {
  width: 66.6666%;
}
.navigation__submenu .submenu__projects__header {
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
}
.navigation__submenu .submenu__projects__header .navigation__submenu__title {
  flex: 1 1 auto;
  width: auto;
}
.navigation__submenu .submenu__projects__header__link {
  color: #4376a1;
}
html.showmobilenav .navigation__submenu .submenu__projects__header__link {
  display: none;
}
.navigation__submenu .submenu__projects__footer {
  display: none;
  justify-content: flex-end;
  white-space: nowrap;
}
html.showmobilenav .navigation__submenu .submenu__projects__footer {
  display: flex;
}
.navigation__submenu .submenu__projects__footer__link {
  color: #4376a1;
}
.navigation__submenu .submenu__projects__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 590px;
  justify-content: space-between;
}
.navigation__submenu .submenu__projects__project {
  flex: none;
  display: block;
  margin-bottom: 30px;
  width: calc(50% - 20px);
}
.navigation__submenu .submenu__projects__project__link {
  display: block;
  color: inherit;
  text-decoration: none;
}
.navigation__submenu .submenu__projects__project__image {
  aspect-ratio: 16/9;
  border-radius: 4px;
  margin-bottom: 10px;
  overflow: hidden;
}
.navigation__submenu .submenu__projects__project__image img {
  position: relative;
  width: 100%;
  height: auto;
  vertical-align: bottom;
  border-radius: inherit;
  transition: transform 300ms;
  transform-origin: center;
}
.navigation__submenu .submenu__projects__project:hover {
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
}
.navigation__submenu .submenu__projects__project:hover img {
  transform: scale(1.1);
}
@media (max-width: 750px) {
  .navigation__submenu .submenu__projects__list {
    display: block;
  }
  .navigation__submenu .submenu__projects__project {
    display: flex;
    margin-bottom: 20px;
    width: 100%;
    align-items: center;
  }
  .navigation__submenu .submenu__projects__project__image {
    flex: none;
    margin-right: 15px;
    aspect-ratio: 16/9;
    margin-bottom: 0;
    width: 33.33%;
  }
  .navigation__submenu .submenu__projects__project__title {
    font-weight: 600;
  }
}
.navigation__submenu .mobilemenu__footer {
  flex: none;
  list-style-type: none;
  margin: 0;
  padding: 0;
  border-top: 1px solid #F4F4F7;
  padding: 20px 35px 35px;
}
@media (max-width: 465px) {
  .navigation__submenu .mobilemenu__footer {
    margin-top: 20px;
    padding: 5px 20px 20px;
  }
}
.navigation__submenu .mobilemenu__footer__item {
  display: flex;
  align-items: center;
  margin-top: 15px;
  max-width: 100%;
  overflow: hidden;
}
.navigation__submenu .mobilemenu__footer__item[role] {
  cursor: pointer;
}
.navigation__submenu .mobilemenu__footer__item--language {
  flex-wrap: wrap;
}
.navigation__submenu .mobilemenu__footer .divider {
  display: inline-block;
  padding: 0 10px;
}
.navigation__submenu .mobilemenu__footer a {
  color: inherit;
  text-decoration: none;
}
.navigation__submenu .mobilemenu__footer a.active {
  font-weight: 700;
}
.navigation__submenu .mobilemenu__footer .icon {
  margin-right: 10px;
  font-size: 24px;
}
.navigation__submenu .submenu__close {
  font-size: 24px;
  transform: rotate(45deg);
  position: absolute;
  top: 50px;
  right: 60px;
  cursor: pointer;
}
.navigation__submenu .submenu__mobileheader {
  height: 90px;
  margin-left: -35px;
  margin-right: -35px;
  position: relative;
  flex: none;
  border-bottom: 1px solid #F4F4F7;
  margin-bottom: 35px;
}
.navigation__submenu .submenu__mobileheader .submenu__close {
  right: 35px;
  top: 15px;
  display: none;
}
@media (max-width: 600px) {
  .navigation__submenu .submenu__mobileheader .submenu__close {
    display: block;
  }
}
.navigation__submenu .submenu__mobileheader .back {
  margin-left: 35px;
  line-height: 30px;
  cursor: pointer;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 5px 10px 10px;
  position: relative;
  z-index: 0;
  transition: color 0.3s;
}
.navigation__submenu .submenu__mobileheader .back::before {
  content: "";
  z-index: -1;
  position: absolute;
  left: 10px;
  top: 10px;
  border-radius: 15px;
  width: 30px;
  height: 30px;
  background-color: rgba(172, 192, 0, 0.05);
  transition: width 0.3s, background-color 0.3s;
}
.navigation__submenu .submenu__mobileheader .back:hover::before {
  width: 100%;
  color: #fff;
  background-color: #acc000;
}
.navigation__submenu .submenu__mobileheader .back:hover {
  color: #fff;
}
.navigation__submenu .submenu__mobileheader .back .arrow {
  display: inline-block;
  width: 30px;
  height: 30px;
  font-size: 24px;
  line-height: 30px;
  transform: rotate(180deg);
}
.navigation__submenu .submenu__mobileheader .back .arrow + span {
  padding-left: 10px;
}
.navigation__submenu .submenu__mobileheader .logo {
  margin-top: -35px;
  max-width: calc(100% - 70px);
}
@media (max-width: 600px) {
  html.showmobilenav .navigation__submenu {
    display: block;
  }
  html.showmobilenav .navigation__submenu > div {
    max-width: 100%;
    height: 100%;
  }
  .navigation__submenu .mobilecta {
    display: none;
  }
}
@media (max-width: 600px) {
  .navigation__submenu .submenu__mobileheader .logo {
    margin-top: -5px;
  }
}
.navigation__submenu[data-id=profile], .navigation__submenu[data-id=language] {
  left: auto;
}
.navigation__submenu .cta li.active a {
  text-shadow: 0 0 1px #181f4b;
}
.navigation__submenu .cta li:not(.active) a:hover {
  color: #181f4b;
  text-decoration: underline;
}
.navigation__submenu .cta li::before {
  background-color: #181f4b;
}
.navigation__submenu.active {
  display: block;
  transform: translateY(0);
  opacity: 1;
  pointer-events: auto;
}
.navigation__submenu__title {
  font-weight: 700;
  font-size: 18px;
  width: 350px;
  max-width: 100%;
  padding-bottom: 20px;
  display: block;
}
.navigation__submenu__title > * {
  vertical-align: top;
}
.navigation__submenu a.navigation__submenu__title {
  color: inherit;
  text-decoration: none;
  padding-bottom: 0;
  display: inline-flex;
  justify-content: flex-start;
}
.navigation__submenu a.navigation__submenu__title .arrow {
  background-color: rgba(172, 192, 0, 0.05);
  display: inline-block;
  flex: none;
  margin-top: -8px;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  font-size: 24px;
  text-align: center;
  line-height: 42px;
  margin-left: 10px;
  transition: background-color 0.2s;
}
.navigation__submenu a.navigation__submenu__title:hover .arrow {
  background-color: rgba(172, 192, 0, 0.2);
}
.navigation__submenu ul.common-linklist li.active {
  position: relative;
  z-index: 0;
}
.navigation__submenu ul.common-linklist li.active::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: -30px;
  background-color: rgba(172, 192, 0, 0.2);
  z-index: -1;
}
.navigation__submenu ul.common-linklist li.active a {
  text-shadow: none;
}
.navigation__submenu .submenu-container {
  padding: 30px;
}
.navigation__submenu--cols {
  overflow: hidden;
}
html.showmobilenav .navigation__submenu--cols {
  background-color: #acc000;
}
.navigation__submenu--cols .submenu-container {
  display: flex;
  padding: 0;
  transform: translateX(0);
  transition: transform 200ms;
}
html:not(.showmobilenav) .navigation__submenu--cols .submenu-container {
  max-height: calc(100vh - 150px);
  overflow-y: auto;
}
.navigation__submenu--cols .submenu-container > div {
  flex: none;
  width: 33.33333333%;
  padding: 35px;
  background-color: #fff;
}
html.showmobilenav .navigation__submenu--cols .submenu-container > div {
  width: 100%;
}
.navigation__submenu--cols .submenu-container > div.cta {
  background-color: #acc000;
}