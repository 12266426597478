.togglesearch {
  cursor: pointer;
}

.topsearch {
  width: 0;
  height: 70px;
  position: absolute;
  right: 35px;
  top: 30px;
  transition: width 300ms;
  z-index: 11;
}
html.show-topsearch .topsearch {
  width: calc(100% - 70px);
}
@media (max-width: 600px) {
  .topsearch {
    top: 0;
    left: 0;
  }
  html.show-topsearch .topsearch {
    width: 100%;
  }
}
.topsearch__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  box-shadow: 0 15px 30px 0 rgba(24, 31, 75, 0.2);
  border-radius: 4px;
  overflow: hidden;
}
.topsearch input, .topsearch button {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  height: 45px;
  border: 0 none;
  font-size: 16px;
  border-radius: 0;
  font: inherit;
  color: inherit;
  padding: 20px;
  margin: 0;
  background-color: #fff;
  height: 100%;
}
.topsearch input {
  flex: 1 1 auto;
  line-height: 20px;
  font-weight: bold;
  padding-left: 0;
  max-width: calc(100% - 155px);
}
.topsearch button {
  flex: none;
  cursor: pointer;
}
.topsearch .topsearch__submit {
  font-size: 24px;
  color: #687500;
  display: block;
  width: 70px;
  flex: none;
  text-align: center;
  border-radius: 4px 0 0 4px;
}
.topsearch .topsearch__close {
  background-color: #6F7490;
  color: #fff;
  border-radius: 0 4px 4px 0;
  transition: background-color 0.2s;
  min-width: 90px;
}
.topsearch .topsearch__close:hover {
  background-color: #454b6e;
}
html.showsearchoverlay .topsearch .topsearch__close {
  background-color: #687500;
}
html.showsearchoverlay .topsearch .topsearch__close:hover {
  background-color: #617000;
}

.wh-autocomplete-values {
  list-style-type: none;
  display: block;
  margin: 0;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: 30px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 15px 30px 0 rgba(24, 31, 75, 0.2);
  z-index: 1;
  padding: 40px 30px;
}
.wh-autocomplete-values > li {
  line-height: 28px;
  cursor: pointer;
}
.wh-autocomplete-values > li.noresults {
  font-style: italic;
  cursor: default;
}
.wh-autocomplete-values > li.suggestion:focus, .wh-autocomplete-values > li.suggestion:hover {
  outline: 1px solid #f1f1f1;
}
.wh-autocomplete-values > li .match {
  font-weight: bold;
}

html.showsearchoverlay body {
  overflow: hidden;
}

html.showsearchoverlay .navigation, html.showsearchoverlay header .logo {
  display: none;
}

.searchoverlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  overflow: hidden;
  overflow-y: auto;
  width: 100vw;
  background-color: #fff;
  display: none;
}
html.showsearchoverlay .searchoverlay {
  display: block;
}
.searchoverlay__header {
  background-color: rgba(172, 192, 0, 0.7);
}
.searchoverlay__header h1 {
  padding: 200px 30px 60px;
  max-width: 1000px;
  margin: 0 auto;
}
.searchoverlay__body {
  background-color: #fff;
  max-width: 1000px;
  margin: 0 auto;
  border-radius: 4px;
}
.searchoverlay__body button {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  background-color: transparent;
  font-size: inherit;
  color: inherit;
  opacity: 0.5;
  transition: opacity 0.2s;
  cursor: pointer;
  margin-left: 25px;
  margin-bottom: 5px;
}
.searchoverlay__body button:hover {
  opacity: 1;
}
.searchoverlay__body button[aria-selected=true] {
  text-shadow: 0 0 1px #181f4b;
  opacity: 1;
}
.searchoverlay__body__tabs {
  border-radius: 4px;
  margin-top: -42px;
  min-height: 50px;
  padding: 25px 30px 25px 5px;
}
.searchoverlay__body__content {
  padding: 25px 0;
}
.searchoverlay__body__content .panel > * {
  padding-left: 30px;
  padding-right: 30px;
  display: block;
}
.searchoverlay__body__content .panel__noresults {
  font-style: italic;
}
.searchoverlay__body__content .panel__resultscount {
  padding-bottom: 10px;
}
.searchoverlay__body__content .panel__result {
  text-decoration: none;
  color: inherit;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 4px;
  background-color: #fff;
  transition: background-color 0.2s;
  display: flex;
}
.searchoverlay__body__content .panel__result:hover {
  background-color: #f3f6d9;
}
.searchoverlay__body__content .panel__result__image {
  flex: none;
  width: 150px;
  align-self: flex-start;
  margin-right: 20px;
  overflow: hidden;
  display: block;
  position: relative;
  border-radius: 4px;
}
.searchoverlay__body__content .panel__result__image::before {
  content: "";
  display: block;
  padding-top: 56.25%;
}
.searchoverlay__body__content .panel__result__image > img {
  position: absolute;
  top: 0;
  right: -9999px;
  bottom: 0;
  left: -9999px;
  margin: 0 auto;
}
.searchoverlay__body__content .panel__result__text {
  flex: 1 1 auto;
}
.searchoverlay__body__content .panel__result > span, .searchoverlay__body__content .panel__result__text > span {
  display: block;
}
.searchoverlay__body__content .panel__result__title {
  padding-bottom: 3px;
}
.searchoverlay__body__content .panel__result__title b {
  font-size: 20px;
  font-weight: 700;
  line-height: 125%;
}
.searchoverlay__body__content .panel__result__title .filetype {
  display: inline-block;
  padding-left: 10px;
  opacity: 0.6;
}
.searchoverlay__body__content .panel__result__summary {
  line-height: 165%;
}
.searchoverlay__body__content .panel__result .consilio--highlight {
  font-weight: 700;
}
.searchoverlay__body__content .panel__result__nextpage {
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;
}
.searchoverlay__body__content .panel__result__nextpage .loading {
  display: none;
}
.searchoverlay__body__content .panel__result__nextpage--loading .button {
  display: none;
}
.searchoverlay__body__content .panel__result__nextpage--loading .loading {
  display: block;
}
@media (max-width: 600px) {
  .searchoverlay__body__content .panel__result__title b {
    font-size: 16px;
  }
  .searchoverlay__body__content .panel__result__image {
    width: 60px;
  }
  .searchoverlay__body__content .panel__result__image::before {
    padding-top: 100%;
  }
}