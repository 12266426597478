.peoplepage-employeedetails {
  margin-bottom: 60px;
}
.peoplepage-employeedetails .position {
  font-size: 18px;
  margin-top: -10px;
}
.peoplepage-employeedetails .contact {
  display: flex;
  padding-top: 30px;
  margin-left: -30px;
  line-height: 24px;
}
.peoplepage-employeedetails .contact a {
  text-decoration: none;
  color: inherit;
}
.peoplepage-employeedetails .contact a[href^="tel:"] {
  transition: color 0.3s;
}
.peoplepage-employeedetails .contact a[href^="tel:"]:hover {
  color: #687500;
}
.peoplepage-employeedetails .contact__col {
  padding-left: 30px;
  flex: 1 1 30%;
  max-width: 30%;
}
.peoplepage-employeedetails .contact__col--buttons {
  text-align: right;
  flex: 1 1 40%;
  max-width: 40%;
}
.peoplepage-employeedetails .contact__col--buttons a {
  display: inline-block;
  text-align: center;
  padding: 12px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: rgba(172, 192, 0, 0.15);
  transition: background-color 0.3s;
  margin-bottom: 15px;
  margin-left: 15px;
}
.peoplepage-employeedetails .contact__col--buttons a .icon {
  color: #6e7a00;
}
.peoplepage-employeedetails .contact__col--buttons a:hover {
  background-color: rgba(172, 192, 0, 0.5);
}
@media (max-width: 800px) {
  .peoplepage-employeedetails .contact__col:nth-child(2) {
    flex: 1 1 auto;
    max-width: none;
  }
  .peoplepage-employeedetails .contact__col--buttons {
    flex: none;
    width: 160px;
    margin-right: 20px;
  }
}
@media (max-width: 800px) {
  .peoplepage-employeedetails .contact {
    display: block;
  }
  .peoplepage-employeedetails .contact__col {
    max-width: none;
  }
  .peoplepage-employeedetails .contact__col--buttons {
    padding-left: 15px;
    text-align: left;
    width: 100%;
    margin-right: 0;
    margin-top: 20px;
  }
}
.peoplepage-employeedetails .contact__row {
  display: flex;
  align-items: flex-start;
  padding-top: 10px;
}
.peoplepage-employeedetails .contact .icon {
  flex: none;
  width: 35px;
  font-size: 24px;
  color: #687500;
}
.peoplepage-employeedetails .contact .icon--pure {
  font-size: 20px;
}