.widget-projectevents__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-top: 20px;
}
.widget-projectevents__item {
  margin-bottom: clamp(20px, 5vw, 50px);
}
.widget-projectevents__item__link {
  display: flex;
  text-decoration: none;
  color: inherit;
}
.widget-projectevents__item__shortdate {
  font-family: "Noto Serif", sans-serif, serif, Arial;
  font-weight: 700;
  line-height: 100%;
  color: #8F93A8;
  font-size: clamp(32px, 4.2vw, 42px);
  flex: none;
  width: 21.5%;
  margin-right: 35px;
}
.widget-projectevents__item__content {
  flex: 1 1 auto;
  line-height: 165%;
}
.widget-projectevents__item__date {
  font-size: 12px;
  color: #6F7490;
}
.widget-projectevents__item__title {
  font-size: clamp(16px, 1.8vw, 18px);
  font-weight: 700;
}
@media (max-width: 600px) {
  .widget-projectevents__item__description {
    display: none;
  }
}