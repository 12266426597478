@media (max-width: 800px) {
  h1 {
    font-size: 5.25vw;
  }
}
@media (max-width: 570px) {
  h1 {
    font-size: 29.925px;
  }
}

@media (max-width: 800px) {
  h2 {
    font-size: 3.75vw;
  }
}
@media (max-width: 570px) {
  h2 {
    font-size: 21.375px;
  }
}