.datetime {
  position: relative;
  display: inline-flex;
  flex-flow: row;
  align-items: center;
  flex: none;
  padding: 0 5px 0 10px;
}
.datetime--replaced {
  position: absolute !important;
  width: 0;
  height: 0;
  overflow: hidden;
  -webkit-appearance: none;
  -moz-appearance: none;
  opacity: 0;
}
.datetime__sep {
  display: inline-block;
  padding-left: 3px;
  padding-right: 3px;
}
.datetime__part {
  background: transparent;
  border: 0;
  min-width: 29px;
  width: calc(2ch + 10px);
  text-align: center;
  font: inherit;
  padding: 0;
  margin: 0;
  box-shadow: none !important;
  border: 0 none !important;
  padding: 0 !important;
  max-height: 41px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: textfield !important;
}
.datetime__part::-webkit-outer-spin-button, .datetime__part::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.datetime__year {
  min-width: 45px;
  width: calc(4ch + 8px);
  text-align: left;
}
.datetime__year::-webkit-input-placeholder {
  letter-spacing: 3px;
}
.datetime__year::-moz-placeholder {
  letter-spacing: 3px;
}
.datetime__year:-ms-input-placeholder {
  letter-spacing: 3px;
}
.datetime__year:-moz-placeholder {
  letter-spacing: 3px;
}
.datetime__msec {
  min-width: 36px;
  width: calc(3ch + 8px);
}
.datetime__controls {
  margin-left: 5px;
  color: #acc000;
}
.datetime__controls > span {
  display: inline-block;
  margin-left: 5px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  text-align: center;
  font-size: 24px;
}
.datetime__togglepicker {
  vertical-align: middle;
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "PThU-iconfont-12px" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  font-size: 12px;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "PThU-iconfont-24px" !important;
  font-size: 24px;
  margin-top: 2px;
}
.datetime__togglepicker::before {
  content: "\e905";
}
.datetime__reset {
  vertical-align: middle;
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "PThU-iconfont-12px" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  font-size: 12px;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-bottom: 2px;
}
.datetime__reset::before {
  content: "\e907";
  font-size: 16px;
}
.datetime__picker {
  position: fixed;
  border: 0 none;
  background-color: #fff;
  border-radius: 0;
  box-shadow: 0 2px 10px 7px rgba(0, 0, 0, 0.1);
}
.datetime__picker select, .datetime__picker input {
  border: 0 none;
}
.datetime__picker__previous, .datetime__picker__next {
  display: none;
}
.datetime__picker__days {
  margin: 20px;
}
.datetime__picker select, .datetime__picker input {
  height: 24px;
  font-size: inherit;
}
.datetime__picker input {
  padding-left: 10px;
}
.datetime__picker__weekday {
  padding: 5px 5px 10px;
  color: #a3a5b7;
}
.datetime__picker__day {
  cursor: pointer;
  outline: 0 none;
  padding: 0 5px;
}
.datetime__picker__day--othermonth {
  opacity: 0.5;
}
.datetime__picker__day span {
  position: relative;
  display: block;
  padding: 7px;
  text-align: center;
  transition: color 0.2s;
}
.datetime__picker__day span::before {
  content: "";
  opacity: 0;
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  left: -4px;
  top: -2px;
  border-radius: 50%;
  background-color: #acc000;
  z-index: -1;
  transition: opacity 0.2s;
}
.datetime__picker__day--selected > span, .datetime__picker__day:hover > span {
  color: #fff;
}
.datetime__picker__day--selected > span::before, .datetime__picker__day:hover > span::before {
  opacity: 1;
}
.datetime__picker__day:hover > span, .datetime__picker__day:focus > span {
  border-color: #599dd7;
}
.datetime__picker__header {
  background-color: #599dd7;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}