.widget-quote {
  border: 1px solid #8F93A8;
  border-radius: 4px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  background-color: #fff;
}
.widget-quote__description {
  font-style: italic;
  font-family: "Noto Serif", sans-serif, serif, Arial;
  font-size: 18px;
  line-height: 150%;
  padding: 35px 30px;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
}
.widget-quote img {
  vertical-align: bottom;
  width: 100%;
  height: auto;
}
.widget-quote__person {
  margin-top: 30px;
  border-top: 1px solid #8F93A8;
  padding: 0 35px 40px;
  line-height: 25px;
  background-color: #EEEEF1;
  border-radius: 0 0 4px 4px;
  flex: none;
}
.widget-quote__person__image {
  width: 64px;
  height: 64px;
  margin-top: -32px;
  border-radius: 50%;
  overflow: hidden;
}
.widget-quote__person__name {
  margin-top: 35px;
  font-weight: bold;
  font-size: 18px;
}
.widget-quote__person__image + .widget-quote__person__name {
  margin-top: 15px;
}
.widget-quote__person__description a {
  color: #4376a1;
}
.widget-quote__person__description > *:last-child {
  margin-bottom: 0;
}
.widget-quote--inverted {
  background-color: #EEEEF1;
}
.widget-quote--inverted .widget-quote__person {
  background-color: #fff;
}