.rpc__busyoverlay {
  display: none;
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  background-color: rgba(111, 116, 144, 0.5);
}
html.dompack--busymodal .rpc__busyoverlay {
  display: block;
}
.rpc__busyoverlay .busyindicator {
  display: block;
  position: absolute;
  width: 80px;
  height: 80px;
  top: 50%;
  left: 50%;
  margin-top: -40px;
  margin-left: -40px;
  opacity: 0.8;
}

.pthu-dialog__modalbg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  white-space: nowrap;
  vertical-align: middle;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pthu-dialog__modalbg:before {
  display: inline-block;
  content: "";
  vertical-align: middle;
  line-height: 100%;
  height: 100%;
  width: 0;
}
.pthu-dialog__holder {
  text-align: left;
  display: inline-block;
  white-space: normal;
  font-family: inherit;
  color: inherit;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}
.pthu-dialog__holder {
  background: #ffffff;
  padding: 20px;
  max-width: 930px;
  border-radius: 4px;
  min-height: 200px;
  max-height: calc(100vh - 70px);
  max-width: calc(100vw - 70px);
  box-shadow: 0 15px 30px 0 rgba(24, 31, 75, 0.2);
}
.pthu-dialog--infodialog {
  width: 730px;
  padding: 50px 45px;
}
.pthu-dialog--infodialog::before {
  content: "";
  top: 0;
  left: 0;
  width: 7px;
  height: 75%;
  max-height: 160px;
  position: absolute;
  display: block;
  border-radius: 4px 0 0 0;
  background-color: #acc000;
}
.pthu-dialog--videodialog {
  width: 930px;
  max-width: 100%;
}
.pthu-dialog--videodialog .pthu-dialog__content {
  position: relative;
}
.pthu-dialog--videodialog .pthu-dialog__content::before {
  content: "";
  display: block;
  padding-top: 56.25%;
}
.pthu-dialog--videodialog .pthu-dialog__content > iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.pthu-dialog__modalbg {
  background-color: rgba(111, 116, 144, 0.15);
  z-index: 1001;
}
.pthu-dialog__content {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: calc(100vh - 170px);
}
.pthu-dialog__holder {
  overflow: visible;
}
.pthu-dialog__close {
  border-radius: 50%;
  cursor: pointer;
  width: 70px;
  height: 70px;
  color: #fff;
  background-color: #acc000;
  position: absolute;
  right: 0;
  top: 0;
  margin: -35px -35px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pthu-dialog__close::before {
  display: inline-block;
  width: 25px;
  height: 25px;
  border: 1px solid #fff;
  line-height: 25px;
  text-align: center;
  border-radius: 50%;
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "PThU-iconfont-12px" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  font-size: 12px;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
  content: "\e907";
}