.footer__newsletterform .wh-form__prefillarea, .widget-newsletter .wh-form__prefillarea {
  display: none;
}
.footer__newsletterform p, .widget-newsletter p {
  margin-bottom: 0;
}
.footer__newsletterform .wh-form, .widget-newsletter .wh-form {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}
.footer__newsletterform .wh-form__page, .widget-newsletter .wh-form__page {
  flex: 1 1 auto;
}
.footer__newsletterform .wh-form div:empty, .widget-newsletter .wh-form div:empty {
  display: none;
}
.footer__newsletterform .wh-form__buttongroup, .widget-newsletter .wh-form__buttongroup {
  flex: none;
  padding: 0;
  margin: 0;
  border: 0 none;
}
.footer__newsletterform .wh-form__fieldgroup, .widget-newsletter .wh-form__fieldgroup {
  margin: 0;
}
.footer__newsletterform .wh-form__fieldgroup .wh-form__fields, .widget-newsletter .wh-form__fieldgroup .wh-form__fields {
  width: 100%;
  max-width: none;
}
.footer__newsletterform .wh-form button, .widget-newsletter .wh-form button {
  text-align: center;
}
.footer__newsletterform .wh-form__fieldgroup > .wh-form__label, .widget-newsletter .wh-form__fieldgroup > .wh-form__label {
  min-width: 110px;
  flex-basis: 110px;
}
.footer__newsletterform h4, .widget-newsletter h4 {
  margin-bottom: 15px;
}
@media (max-width: 450px) {
  .footer__newsletterform .wh-form, .widget-newsletter .wh-form {
    display: block;
  }
  .footer__newsletterform .wh-form__buttongroup, .widget-newsletter .wh-form__buttongroup {
    margin-top: 15px;
    margin-left: -15px;
  }
  .footer__newsletterform .wh-form__fieldgroup, .widget-newsletter .wh-form__fieldgroup {
    padding-right: 0;
  }
  .footer__newsletterform button, .widget-newsletter button {
    width: calc(100% - 15px);
  }
}