.widget-widgetgroup__list__item .widget-opendays__wrapper {
  height: 100%;
}

.sectionstartpage-intro + .widget-opendays {
  margin-top: -70px;
}

.widget-opendays__wrapper {
  position: relative;
}
.widget-opendays__arrow {
  position: absolute;
  width: 42px;
  height: 42px;
  left: 0;
  margin-left: -21px;
  margin-top: -21px;
  top: 50%;
  border-radius: 50%;
  background-color: #fff;
  text-align: center;
  line-height: 42px;
  font-size: 12px;
  padding-right: 6px;
  opacity: 0;
  transition: opacity 0.3s;
}
.widget-opendays__arrow i {
  display: inline-block;
  transition: transform 0.3s;
}
.widget-opendays__arrow:hover > i {
  transform: translateX(-2px);
}
.widget-opendays--enableprevious .widget-opendays__arrow[data-action=previous] {
  opacity: 1;
  cursor: pointer;
}
.widget-opendays--enablenext .widget-opendays__arrow[data-action=next] {
  opacity: 1;
  cursor: pointer;
}
.widget-opendays__arrow + .widget-opendays__arrow {
  padding-left: 12px;
  left: 100%;
}
.widget-opendays__arrow + .widget-opendays__arrow:hover > i {
  transform: translateX(2px);
}
.widget-opendays a {
  color: inherit;
  text-decoration: none;
}
.widget-opendays__container {
  overflow: hidden;
  max-width: 100%;
  height: 100%;
  border-radius: 4px;
}
.widget-opendays__container > ul {
  transform: translateX(0);
  transition: transform 0.3s;
}
.widget-opendays ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  background-color: #454b6e;
  color: #fff;
  border-radius: 4px;
  display: flex;
}
.widget-opendays a b {
  text-decoration: underline;
}
.widget-opendays__item {
  display: flex;
  padding: 40px 20px;
  flex: 1 0 33.33333%;
}
.widget-opendays__item__icon {
  display: block;
  font-size: 24px;
  flex: none;
  width: 35px;
}
.widget-opendays__item__details {
  display: block;
}

.main__col--left .widget-opendays__item {
  flex-basis: 100%;
}

.widget-widgetgroup__list__item .widget-opendays {
  position: relative;
}
.widget-widgetgroup__list__item .widget-opendays .widget__title {
  position: absolute;
  bottom: 100%;
  left: 0;
  padding-bottom: 15px;
}
.widget-widgetgroup__list__item .widget-opendays ul, .widget-widgetgroup__list__item .widget-opendays li {
  height: 100%;
}