p + .widget-compactlinklist {
  margin-top: -20px;
}

.widget-compactlinklist {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-bottom: 25px;
}
.widget-compactlinklist__item + .widget-compactlinklist__item {
  margin-top: 5px;
}
.widget-compactlinklist__link {
  display: inline-flex;
  text-decoration: none;
  align-items: baseline;
  font-size: 18px;
  line-height: 165%;
}
.widget-compactlinklist__link__arrow {
  font-size: 24px;
  flex: none;
  position: relative;
  top: 4px;
  color: #6e7a00;
  margin-right: 10px;
}
.widget-compactlinklist__link:hover .widget-compactlinklist__link__title {
  text-shadow: 0 0 1px #181f4b;
}

.widget-linklist .widget__title {
  padding-bottom: 5px;
  min-height: 40px;
}
.widget-linklist .widget__title a {
  text-decoration: none;
  color: inherit;
  display: inline-flex;
  padding-right: 50px;
}
.widget-linklist .widget__title a .arrow {
  flex: none;
  margin-left: 10px;
  margin-top: -8px;
  background-color: rgba(172, 192, 0, 0.15);
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  transform: rotate(180deg);
  width: 42px;
  height: 42px;
  line-height: 42px;
  transition: background-color 0.2s;
}
.widget-linklist .widget__title a:hover .arrow {
  background-color: rgba(172, 192, 0, 0.3);
}

.main__col--left .widget-linklist {
  margin-bottom: 40px;
}