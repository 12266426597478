/*
Put commonly used styling for multiple elements here
*/
/* Used for visualy hidden items but required for accessibilty */
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/* default styling for plain link list with green bullets and green hover color */
ul.common-linklist {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
ul.common-linklist li {
  position: relative;
  padding-left: 30px;
}
ul.common-linklist li::before {
  content: "";
  display: block;
  background-color: #acc000;
  border-radius: 50%;
  width: 4px;
  height: 4px;
  position: absolute;
  top: 16px;
  left: 0;
}
ul.common-linklist li a, ul.common-linklist li span[role], ul.common-linklist li button {
  display: inline-block;
  text-decoration: none;
  color: #181f4b;
  padding: 7px 0;
  line-height: 165%;
  transition: color 0.2s;
  cursor: pointer;
}
ul.common-linklist li:not(.active) a:hover, ul.common-linklist li span[role]:hover, ul.common-linklist li:not(.active) a:focus {
  color: #687500;
}
ul.common-linklist li.active a {
  text-shadow: 0 0 1px #181f4b;
}
ul.common-linklist .description {
  display: block;
  line-height: 145%;
  padding-bottom: 3px;
}
ul.common-linklist--lines {
  border-top: 1px solid #c4d24c;
}
ul.common-linklist--lines li {
  padding-left: 0;
  border-bottom: 1px solid #c4d24c;
}
ul.common-linklist--lines li::before {
  display: none;
}
ul.common-linklist--lines li:not(.active) a:hover {
  color: inherit;
}
ul.common-linklist--lines li a {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 0;
}
ul.common-linklist--lines li a .icon {
  flex: none;
  font-size: 24px;
  width: 50px;
  text-align: center;
}
ul.common-linklist--lines li a .filesize {
  text-transform: uppercase;
}
ul.common-linklist--lines li a b, ul.common-linklist--lines li a::after {
  transition: color 0.2s;
}
ul.common-linklist--lines li a:hover b, ul.common-linklist--lines li a:hover::after {
  color: #687500;
}

.main__col--left ul.common-linklist li::before {
  background-color: transparent !important;
  color: #687500;
  border-radius: 0;
  width: auto;
  height: 12px;
  position: absolute;
  top: 7px;
  left: 0;
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "PThU-iconfont-12px" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  font-size: 12px;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "PThU-iconfont-24px" !important;
  font-size: 24px;
  content: "\e90f";
}

.common-contentlist {
  margin-top: 50px;
}
@media (max-width: 600px) {
  .common-contentlist {
    margin-top: 25px;
  }
}
.common-contentlist--twocol > ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-left: -35px !important;
}
.common-contentlist--twocol > ul > li {
  flex: 1 1 50%;
  max-width: 50%;
  padding-left: 35px;
}
@media (max-width: 1000px) {
  .common-contentlist--twocol > ul {
    display: block;
  }
  .common-contentlist--twocol > ul > li {
    max-width: 100%;
  }
}
.common-contentlist--wide {
  max-width: 1000px !important;
  margin-left: auto;
  margin-right: auto;
}
.common-contentlist ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  border-top: 1px solid #8F93A8;
  padding-top: 50px;
}
.common-contentlist__filter + ul {
  padding-top: 0;
}
.common-contentlist--wide ul {
  border-top: 0 none;
}
.common-contentlist--programmes ul {
  border-top: 0 none;
  padding-top: 0;
}
.common-contentlist__filter {
  background-color: #EEEEF1;
  margin-bottom: 35px;
  padding: 20px;
  border-radius: 4px;
}
.common-contentlist__filter__years, .common-contentlist__filter__advanced {
  background-color: #fff;
  line-height: 25px;
  padding: 20px 30px 20px 0;
  border-radius: 4px;
}
.common-contentlist__filter__years > a, .common-contentlist__filter__advanced > a {
  display: inline-block;
  margin-left: 30px;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}
.common-contentlist__filter__years > a.active, .common-contentlist__filter__advanced > a.active {
  font-weight: 700;
}
@media (max-width: 600px) {
  .common-contentlist__filter {
    padding: 20px 35px;
    margin: 0 -35px 35px;
    bordder-radius: 0;
  }
  .common-contentlist__filter__years {
    padding-right: 15px;
  }
  .common-contentlist__filter__years > a {
    margin-left: 20px;
  }
}
@media (max-width: 465px) {
  .common-contentlist__filter {
    margin: 0 -20px 20px;
    padding: 20px;
  }
}
.common-contentlist__filter__advanced {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.common-contentlist__filter__advanced__title {
  padding-left: 30px;
  font-weight: 700;
  font-size: 18px;
}
.common-contentlist__filter__advanced dl {
  margin: 0;
  padding: 0;
}
.common-contentlist__filter__advanced dt, .common-contentlist__filter__advanced dd {
  display: inline-block;
  margin: 0;
  padding: 0;
}
.common-contentlist__filter__advanced dt {
  margin-left: 30px;
  padding-right: 7px;
}
.common-contentlist__filter__advanced dd {
  font-style: italic;
}
.common-contentlist__orderby + ul {
  border-top: 0;
  padding-top: 35px;
}
.common-contentlist__orderby {
  background-color: #EEEEF1;
  margin-bottom: 35px;
  padding: 15px;
  padding-left: calc(33.333% + 47px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
}
.common-contentlist__orderby__label {
  font-weight: 700;
  padding-right: 15px;
}
.common-contentlist__orderby select {
  min-height: 40px;
  padding: 10px 15px;
  flex: 1;
  border: 1px solid #8F93A8;
  font-family: inherit;
  line-height: 20px;
  color: #A3A5B7;
  border-radius: 0;
  background-color: #fff;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
}
.common-contentlist__orderby__pulldown {
  max-width: 260px;
}
@media (max-width: 600px) {
  .common-contentlist__orderby {
    justify-content: flex-end;
    padding-left: 35px;
    margin: 0 -35px;
    bordder-radius: 0;
  }
}
@media (max-width: 465px) {
  .common-contentlist__orderby {
    padding-left: 20px;
    margin: -20px;
  }
  .common-contentlist__orderby .wh-form__pulldown-styled > select {
    min-width: 0;
  }
}
.common-contentlist__item {
  margin-bottom: 35px;
}
.common-contentlist__item a {
  display: flex;
  text-decoration: none;
  align-items: stretch;
}
.common-contentlist__item__image {
  flex: none;
  width: calc(33.333% + 11px);
  position: relative;
  margin-right: 35px;
  background: #6F7490 url(../web/img/fallback.png) no-repeat 50% 50%;
  background-size: contain;
  border-radius: 4px;
  overflow: hidden;
  min-height: 100%;
}
.common-contentlist__item__image[data-loadimage], .common-contentlist__item__image.image-loaded {
  background-image: none;
}
.common-contentlist__item__image::before {
  content: "";
  display: block;
  padding-top: 56.25%;
}
.common-contentlist__item__image > img {
  position: absolute;
  top: 0;
  height: 100%;
  right: -9999px;
  left: -9999px;
  margin: 0 auto;
}
.common-contentlist__item a:hover img {
  opacity: 0.8 !important;
}
.common-contentlist__item__text {
  flex: 1 1 66.666%;
  line-height: 165%;
  display: flex;
  flex-flow: column;
}
.common-contentlist__item__text > span {
  display: block;
  flex: none;
}
.common-contentlist__item__text .description {
  flex: 1 1 auto;
}
.common-contentlist__item__text .meta {
  color: #6F7490;
  padding-bottom: 5px;
  margin-left: -12px;
}
.common-contentlist__item__text .meta > span {
  display: inline-block;
  padding-left: 12px;
}
.common-contentlist__item__text .meta > span + span::before {
  content: "|";
  display: inline-block;
  padding-right: 12px;
}
.common-contentlist__item__text .title {
  font-size: 20px;
  line-height: 125%;
  font-weight: 700;
  padding-bottom: 2px;
}
.common-contentlist__item__text .viewmore {
  text-decoration: underline;
  color: #2d789e;
  text-align: right;
}
.common-contentlist__item__text .footer {
  background-color: rgba(172, 192, 0, 0.15);
  color: #687500;
  font-size: 12px;
  line-height: 135%;
  padding: 10px 15px 10px 5px;
  margin-top: 10px;
}
.common-contentlist__item__text .footer--grey {
  color: #181f4b;
  background-color: rgba(143, 147, 168, 0.15);
}
.common-contentlist__item__text .footer > span {
  display: inline-block;
  padding-left: 10px;
}
.common-contentlist__item__text .footer > span > i {
  display: inline-block;
  font-size: 12px;
  padding-right: 10px;
  position: relative;
  top: 1px;
}
.common-contentlist__item__text .footer > span + span::before {
  content: "|";
  display: inline-block;
  padding-right: 10px;
}
@media (max-width: 600px) {
  .common-contentlist__item__text .meta {
    padding-bottom: 0;
    font-size: 14px;
  }
  .common-contentlist__item__text .title {
    font-size: 16px;
  }
  .common-contentlist__item__image {
    width: 60px;
    margin-right: 20px;
  }
  .common-contentlist__item__image::before {
    padding-top: 100%;
  }
}
@media (max-width: 465px) {
  .common-contentlist__item__text > .description {
    display: none;
  }
}
.common-contentlist--twocol .common-contentlist__item__image {
  align-self: flex-start;
}
.common-contentlist--twocol .common-contentlist__item__text .title {
  font-size: 18px;
}
@media (max-width: 600px) {
  .common-contentlist--twocol .common-contentlist__item__text .title {
    font-size: 16px;
  }
}
.common-contentlist--programmes .common-contentlist__item {
  border: 1px solid #8F93A8;
  border-radius: 4px;
  margin-bottom: 20px;
}
.common-contentlist--programmes .common-contentlist__item__image {
  border-radius: 4px 0 0 4px;
  border-right: 1px solid #8F93A8;
}
.common-contentlist--programmes .common-contentlist__item__image[data-loadimage], .common-contentlist--programmes .common-contentlist__item__image.image-loaded {
  background-image: none;
}
.common-contentlist--programmes .common-contentlist__item__image::before {
  padding-top: 68%;
}
.common-contentlist--programmes .common-contentlist__item__text {
  padding: 5px 15px 10px 0;
  position: relative;
}
@media (min-width: 1000px) {
  .common-contentlist--programmes .common-contentlist__item .viewmore {
    position: absolute;
    right: 15px;
    top: 5px;
  }
}
@media (min-width: 680px) and (max-width: 800px) {
  .common-contentlist--programmes .common-contentlist__item .viewmore {
    position: absolute;
    right: 15px;
    top: 5px;
  }
}
@media (min-width: 400px) and (max-width: 600px) {
  .common-contentlist--programmes .common-contentlist__item .viewmore {
    position: absolute;
    right: 15px;
    top: 5px;
  }
}
@media (max-width: 600px) {
  .common-contentlist--programmes .common-contentlist__item__image {
    display: none;
  }
  .common-contentlist--programmes .common-contentlist__item__text {
    padding-left: 15px;
  }
}
@media (max-width: 465px) {
  .common-contentlist--programmes .common-contentlist__item__text > .description {
    display: block;
  }
}

.main--hasleftcolumn .common-contentlist--twocol > ul {
  display: block;
}
.main--hasleftcolumn .common-contentlist--twocol > ul > li {
  max-width: 100%;
}

/* prevent occacional double click from selecting text */
.preventselection {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

/* Loading spinner */
.anim-spin {
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}