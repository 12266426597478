.widget-insetblock__inner {
  border-radius: 4px;
  background-color: rgba(172, 192, 0, 0.7);
  padding: 30px;
}
.widget-insetblock__inner ul.unordered, .widget-insetblock__inner ol.ordered {
  padding: 0;
}
.widget-insetblock__inner > *:last-child {
  margin-bottom: 0;
}
.widget-insetblock--bggreen-light .widget-insetblock__inner {
  background-color: #e8eebd;
}
.widget-insetblock--bgblue-light .widget-insetblock__inner {
  background-color: #e3eef7;
}