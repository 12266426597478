.widget-newsagenda {
  position: relative;
  margin-top: 60px;
}
.widget-newsagenda__columns {
  display: flex;
}
.widget-newsagenda__columns__col {
  list-style-type: none;
  margin: 0;
  padding: 0;
  flex: 1 0 50%;
  max-width: 50%;
  padding-right: 17px;
}
.widget-newsagenda__columns__col + .widget-newsagenda__columns__col {
  padding-left: 17px;
  padding-right: 0;
}
.widget-newsagenda__columns--onetype {
  display: block;
}
.widget-newsagenda__columns--onetype .widget-newsagenda__heading {
  flex: none;
  width: 100%;
}
.widget-newsagenda__columns--onetype .widget-newsagenda__columns__col {
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-right: 0;
}
.widget-newsagenda__columns--onetype .widget-newsagenda__item {
  flex: 1 0 50%;
  max-width: 50%;
  padding-right: 17px;
}
.widget-newsagenda__columns--onetype .widget-newsagenda__item:nth-child(3) {
  margin-top: 0;
}
.widget-newsagenda__columns--onetype .widget-newsagenda__item:nth-child(2n+1) {
  padding-left: 17px;
  padding-right: 0;
}
.widget-newsagenda__item + .widget-newsagenda__item {
  margin-top: 30px;
}
.widget-newsagenda__item__link {
  display: flex;
  justify-content: space-between;
  color: inherit;
  text-decoration: none;
}
.widget-newsagenda__item__shortdate, .widget-newsagenda__item__image {
  font-family: "Noto Serif", sans-serif, serif, Arial;
  font-weight: 700;
  flex: none;
  width: 29%;
  color: #8F93A8;
  font-size: 42px;
  align-self: flex-start;
  max-width: 29%;
  display: block;
}
.widget-newsagenda__item__shortdate {
  line-height: 100%;
}
.widget-newsagenda__item__image {
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  background: #6F7490 url(../../web/img/fallback.png) no-repeat 50% 50%;
  background-size: contain;
}
.widget-newsagenda__item__image::before {
  content: "";
  display: block;
  padding-top: 56.25%;
}
.widget-newsagenda__item__image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.widget-newsagenda__item__shortdate {
  padding-top: 10px;
}
.widget-newsagenda__item__textcontainer {
  display: block;
  padding-left: 35px;
  flex: 1 1 auto;
  line-height: 25px;
}
.widget-newsagenda__item__textcontainer > span {
  display: block;
}
.widget-newsagenda__item__textcontainer .date {
  font-size: 12px;
  line-height: 16px;
  color: #6F7490;
  padding-bottom: 4px;
}
.widget-newsagenda__item__textcontainer .title {
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 4px;
}
@media (max-width: 900px) {
  .widget-newsagenda__columns {
    display: block;
  }
  .widget-newsagenda__columns__col {
    max-width: none;
    padding-right: 0;
  }
  .widget-newsagenda__columns--onetype .widget-newsagenda__columns__col {
    display: block;
  }
  .widget-newsagenda__columns__col + .widget-newsagenda__columns__col {
    margin-top: 30px;
    padding-left: 0;
  }
  .widget-newsagenda__columns--onetype .widget-newsagenda__item {
    max-width: 100%;
    padding-right: 0;
  }
  .widget-newsagenda__columns--onetype .widget-newsagenda__item:nth-child(2n+1) {
    padding-left: 0;
  }
  .widget-newsagenda__columns--onetype .widget-newsagenda__item + .widget-newsagenda__item {
    margin-top: 30px;
  }
}
@media (max-width: 600px) {
  .widget-newsagenda__item__textcontainer > span.description {
    display: none;
  }
  .widget-newsagenda__item__textcontainer .title {
    font-size: 15px;
  }
}
@media (max-width: 500px) {
  .widget-newsagenda__item__image, .widget-newsagenda__item__shortdate {
    max-width: 60px;
  }
  .widget-newsagenda__item__image::before {
    padding-top: 100%;
  }
  .widget-newsagenda__item__image img {
    width: auto;
    left: -9990px;
    right: -9999px;
    margin: 0 auto;
  }
  .widget-newsagenda__item__shortdate {
    font-size: 32px;
    padding-top: 0;
  }
  .widget-newsagenda__item__textcontainer {
    padding-left: 15px;
  }
}