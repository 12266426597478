@charset "UTF-8";
.hightlightedprojects__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-top: 50px;
  margin-bottom: 50px;
}
.hightlightedprojects__project {
  display: flex;
  text-decoration: none;
  border-radius: 4px;
  border: 1px solid #D1D2DB;
  position: relative;
  background-color: #fff;
  transition: background-color 300ms, border-color 300ms;
  margin-bottom: 20px;
}
.hightlightedprojects__project:hover {
  background-color: #f3f6d9;
  border-color: #c4d24c;
}
.hightlightedprojects__project__image {
  border-radius: 4px 0 0 4px;
  overflow: hidden;
  width: 37%;
  flex: none;
  border: 1px solid rgba(24, 31, 75, 0.2);
  min-height: 190px;
}
.hightlightedprojects__project__text {
  flex: 1 1 auto;
  line-height: 165%;
  margin: 30px 35px;
  display: flex;
  align-items: center;
}
@media (max-width: 1000px) {
  .hightlightedprojects__project__text {
    margin: clamp(25px, 3vw, 30px);
  }
}
.hightlightedprojects__project__text__wrapper {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}
.hightlightedprojects__project__title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 5px;
}
.hightlightedprojects__project__arrow {
  position: absolute;
  right: 24px;
  bottom: 15px;
  color: #6e7a00;
  font-size: 20px;
}

.projectspage__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-bottom: 50px;
}
.projectspage__list p.intro {
  font-size: 15px;
  line-height: 165%;
}
.projectspage__list__item {
  border-bottom: 1px solid #D1D2DB;
}
.projectspage__list__item .wh-details__container__inner {
  padding-bottom: clamp(20px, 4vw, 40px);
}
.projectspage__list__item__link {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
}
.projectspage__list__item__link i {
  font-size: 24px;
  margin-right: 15px;
  color: #6e7a00;
}
.projectspage__list__item__link:hover > span {
  text-decoration: underline;
  text-underline-position: under;
}

.projectpage-header {
  background-color: #fbf2dd;
  border-radius: 4px;
  min-height: 450px;
  padding-top: 30px;
  margin-bottom: 50px;
  position: relative;
}
@media (max-width: 1189px) {
  .projectpage-header {
    max-width: 100%;
  }
}
.projectpage-header__content {
  display: flex;
  justify-content: space-between;
}
.projectpage-header__text {
  padding-top: 20px;
  flex: none;
  width: 44%;
}
.projectpage-header__image {
  margin-bottom: -70px;
  flex: none;
  width: 44%;
  border-radius: 50%;
  position: relative;
  align-self: flex-start;
}
.projectpage-header__image img {
  width: 100%;
  height: auto;
  border-radius: 50%;
  vertical-align: bottom;
}
.projectpage-header__image svg {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: auto;
}
.projectpage-header__download {
  margin-bottom: 35px;
}
.projectpage-header__download__link {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  max-width: 100%;
}
.projectpage-header__download__link__icon {
  flex: none;
  font-size: 24px;
  margin-left: 5px;
  height: 42px;
  width: 42px;
  line-height: 42px;
  border-radius: 50%;
  text-align: center;
  color: #6e7a00;
  background-color: #fff;
  transition: background-color 300ms;
  display: inline-block;
}
@media (max-width: 400px) {
  .projectpage-header__download__link__icon {
    display: none;
  }
}
.projectpage-header__download__link:hover .projectpage-header__download__link__button {
  background-color: #acc000;
}
.projectpage-header__download__link:hover .projectpage-header__download__link__icon {
  background-color: #f9f9f9;
}
@media (min-width: 751px) {
  html.showmobilenav .projectpage-header {
    background-color: transparent;
    min-height: 0;
    padding-top: 0;
    margin-bottom: 30px;
  }
  html.showmobilenav .projectpage-header__content {
    display: block;
  }
  html.showmobilenav .projectpage-header__text {
    width: 60%;
  }
  html.showmobilenav .projectpage-header__image {
    margin-bottom: 0;
    width: 492px;
    position: absolute;
    top: -100px;
    right: 0;
    z-index: -1;
    transform: translate(35%, -25%);
  }
  html.showmobilenav .projectpage-header__image svg {
    display: none;
  }
  html.showmobilenav .projectpage-header__download {
    margin-bottom: 0;
  }
  html.showmobilenav .projectpage-header__download__link__icon {
    background-color: #f3f6d9;
  }
  html.showmobilenav .projectpage-header__download__link:hover .projectpage-header__download__link__icon {
    background-color: #e3e6c9;
  }
}
@media (max-width: 750px) {
  .projectpage-header {
    background-color: transparent;
    min-height: 0;
    padding-top: 0;
    margin-bottom: 0;
    overflow: hidden;
  }
  .projectpage-header__content {
    display: block;
  }
  .projectpage-header__text {
    padding-top: 402px;
    width: 100%;
  }
  .projectpage-header__image {
    margin-bottom: 0;
    width: 492px;
    max-width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    transform: translate(35%, -20%);
  }
  .projectpage-header__image svg {
    display: none;
  }
  .projectpage-header__download {
    margin-bottom: 0;
  }
  .projectpage-header__download__link__icon {
    background-color: #f3f6d9;
  }
  .projectpage-header__download__link:hover .projectpage-header__download__link__icon {
    background-color: #e3e6c9;
  }
}
@media (max-width: 492px) {
  .projectpage-header__text {
    padding-top: 83vw;
  }
}

.projectpage__subnav {
  margin-bottom: 40px;
  padding-right: 0;
  padding-bottom: 4px;
  border-radius: 4px;
  max-width: 100%;
  position: relative;
  overflow: hidden;
  overflow-x: auto;
}
.projectpage__subnav .wh-anchor {
  position: absolute;
  top: 0;
}
.projectpage__subnav__bar {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: clamp(5px, 3vw, 15px);
  background-color: #f4f4f7;
}
.projectpage__subnav__item {
  color: inherit;
  display: inline-block;
  text-decoration: none;
  line-height: 30px;
  padding: 15px clamp(5px, 3vw, 15px);
  position: relative;
  white-space: nowrap;
}
.projectpage__subnav__item::after {
  content: "";
  background-color: #c4d24c;
  opacity: 0;
  transition: opacity 300ms;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  position: absolute;
  bottom: -3px;
  left: 50%;
  transform: translateX(-50%);
}
.projectpage__subnav__item:hover {
  text-shadow: 0 0 1px #181f4b;
}
.projectpage__subnav__item--active {
  font-weight: 700;
}
.projectpage__subnav__item--active::after {
  opacity: 1;
}
@media (max-width: 800px) {
  .projectpage__subnav {
    display: block;
    margin-left: -35px;
    margin-right: -35px;
    max-width: calc(100% + 70px);
  }
  .projectpage__subnav::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 4px;
    background-color: #f4f4f7;
  }
}
@media (max-width: 465px) {
  .projectpage__subnav {
    margin-left: -20px;
    margin-right: -20px;
    max-width: calc(100% + 40px);
  }
}

.projectpage-sidenav {
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-size: 15px;
}
.projectpage-sidenav__item {
  transition: background-color 300ms;
}
.projectpage-sidenav__item:hover {
  background-color: rgba(251, 242, 221, 0.4);
}
.projectpage-sidenav__item--selected {
  font-weight: 700;
  background-color: #FBF2DD;
}
.projectpage-sidenav__link {
  display: flex;
  text-decoration: none;
  color: inherit;
  padding: 5px 10px;
  line-height: 25px;
}
.projectpage-sidenav__link::before {
  display: inline-block;
  width: 20px;
  flex: none;
  content: "•";
  color: #ACC000;
}

.projectpage__subpagetitle {
  margin-bottom: clamp(15px, 2.5vw, 25px);
}

.projectpage-members {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-bottom: 80px;
}
.projectpage-members__member__name, .projectpage-members__member__position {
  padding-right: 10px;
}
.projectpage-members__member__summary {
  display: flex;
  align-items: center;
  white-space: initial;
}
.projectpage-members__member.wh-details {
  margin-bottom: 16px;
}
.projectpage-members__member.wh-details .projectpage-members__member__summary {
  padding-bottom: 0;
}
.projectpage-members__member.wh-details .projectpage-members__member__summary::before, .projectpage-members__member.wh-details .projectpage-members__member__summary::after {
  width: 44px;
  height: 44px;
  background-color: #f3f6d9;
  border-radius: 50%;
  text-align: center;
  line-height: 44px;
  margin-top: -22px;
}
.projectpage-members__member.wh-details .projectpage-members__member__summary::before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "PThU-iconfont-12px" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  font-size: 12px;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "PThU-iconfont-24px" !important;
  font-size: 24px;
  content: "\e903";
  font-size: 18px;
}
.projectpage-members__member.wh-details .projectpage-members__member__summary::after {
  content: "\e907";
}
.projectpage-members__member__photo {
  width: 100px;
  flex: none;
  margin-right: 35px;
}
@media (max-width: 534px) {
  .projectpage-members__member__photo {
    margin-right: 20px;
    width: 18.75vw;
  }
}
@media (max-width: 320px) {
  .projectpage-members__member__photo {
    width: 60px;
  }
}
.projectpage-members__member__photo img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
  border-radius: 50%;
}
.projectpage-members__member__position {
  font-weight: 400;
}
.projectpage-members__member__link {
  text-decoration: none;
  display: inline-flex;
  align-items: center;
}
.projectpage-members__member__link i {
  color: #6e7a00;
  margin-right: 10px;
  font-size: 24px;
}
.projectpage-members__member__link:hover span {
  text-decoration: underline;
  text-underline-position: under;
}
.projectpage-members__member__details {
  padding-left: 135px;
}
.projectpage-members__member__details p:not(:last-child) {
  margin-bottom: 20px;
}
@media (max-width: 534px) {
  .projectpage-members__member__details {
    padding-left: calc(20px + 18.75vw);
  }
}
@media (max-width: 320px) {
  .projectpage-members__member__details {
    padding-left: 80px;
  }
}
.projectpage-members__partners {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-top: 35px;
  margin-bottom: 80px;
}
.projectpage-members__partners__item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.projectpage-members__partners__item__link {
  display: flex;
  align-items: center;
  color: inherit;
  text-decoration: none;
}
.projectpage-members__partners__item__image {
  width: 100px;
  height: 100px;
  flex: none;
  border-radius: 50%;
  background-color: #F3F4F6;
  margin-right: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.projectpage-members__partners__item__image img {
  width: 90%;
  height: auto;
  vertical-align: bottom;
}
.projectpage-members__partners__item__name {
  flex: 1 1 auto;
}

.projectpage-contacts {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.projectpage-contacts__item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.projectpage-contacts__item__photo {
  width: 100px;
  flex: none;
  margin-right: 35px;
}
.projectpage-contacts__item__photo img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
  border-radius: 50%;
}
.projectpage-contacts__item__person {
  flex: 1 1 auto;
}
.projectpage-contacts__item__person__email {
  text-decoration: none;
  color: inherit;
  opacity: 0.7;
  display: inline-block;
  font-size: 24px;
  transition: opacity 300ms;
}
.projectpage-contacts__item__person__email:hover {
  opacity: 1;
}
.projectpage-contacts__item__person__name {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.projectpage-updates__resultcount {
  margin-left: 30px;
  margin-top: 15px;
  font-style: italic;
}
.projectpage-updates__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 800px) {
  .projectpage-updates .projectpage__subpagetitle {
    margin-bottom: 0;
  }
  .projectpage-updates__resultcount {
    margin-top: -10px;
  }
}
.projectpage-updates__mobilefilter {
  display: none;
  flex: none;
  position: relative;
}
.projectpage-updates__mobilefilter button {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font: inherit;
  font-size: 24px;
  display: inline-flex;
  width: 48px;
  height: 48px;
  line-height: 48px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: background-color 300ms;
  background-color: #f3f6d9;
}
.projectpage-updates__mobilefilter:hover button {
  background-color: #e3e6c9;
}
.projectpage-updates__mobilefilter__panel {
  display: block;
  width: 248px;
  border-radius: 4px;
  padding: 30px 20px;
  position: absolute;
  right: -20px;
  top: -20px;
  background-color: #fff;
  opacity: 0;
  transform: scale(0.001);
  transform-origin: top right;
  box-shadow: 0 15px 30px 0 rgba(24, 31, 75, 0.2);
  pointer-events: none;
  transition: opacity 300ms, transform 300ms;
}
.projectpage-updates__mobilefilter__panel__icon {
  font-size: 24px;
  display: inline-block;
  position: absolute;
  right: 30px;
  top: 30px;
}
.projectpage-updates__mobilefilter__button:focus + .projectpage-updates__mobilefilter__panel, .projectpage-updates__mobilefilter:focus-within .projectpage-updates__mobilefilter__panel {
  transform: scale(1);
  opacity: 1;
  pointer-events: inherit;
}
.projectpage-updates__mobilefilter__option {
  display: flex;
  text-decoration: none;
  color: inherit;
  padding: 5px 3px;
  margin: 1px 0;
  align-items: baseline;
  border-radius: 4px;
  transition: background-color 300ms;
}
.projectpage-updates__mobilefilter__option::before {
  flex: none;
  width: 25px;
  padding-left: 5px;
  content: "•";
  color: #acc000;
}
.projectpage-updates__mobilefilter__option:hover, .projectpage-updates__mobilefilter__option--active {
  text-shadow: 0 0 1px #181f4b;
  background-color: #f3f6d9;
}
.projectpage-updates__filters__toggles {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #D1D2DB;
  padding-left: 15px;
  white-space: nowrap;
}
.projectpage-updates__filters__button {
  display: inline-block;
  text-decoration: none;
  color: #747993;
  padding: 15px;
  line-height: 30px;
  transition: color 300ms;
  position: relative;
}
.projectpage-updates__filters__button b {
  display: block;
  visibility: hidden;
  color: #181f4b;
}
.projectpage-updates__filters__button b[hidden] {
  visibility: hidden;
  overflow: hidden;
  height: 0;
}
.projectpage-updates__filters__button::after {
  content: "";
  background-color: #c4d24c;
  opacity: 0;
  transition: opacity 300ms;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  position: absolute;
  bottom: -4px;
  left: 50%;
  transform: translateX(-50%);
}
.projectpage-updates__filters__button:hover {
  color: #181f4b;
  text-shadow: 0 0 1px #181f4b;
}
.projectpage-updates__filters__button--active::after {
  opacity: 1;
}
.projectpage-updates__filters__button--active span {
  display: none;
}
.projectpage-updates__filters__button--active b[hidden] {
  height: auto;
  visibility: visible;
}
@media (max-width: 800px) {
  .projectpage-updates__filters {
    display: none;
  }
  .projectpage-updates .projectpage__subpagetitle {
    margin-bottom: 0;
  }
  .projectpage-updates__resultcount {
    margin-left: 0;
  }
  .projectpage-updates__mobilefilter {
    display: block;
  }
}
.projectpage-updates__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-top: 50px;
}
.projectpage-updates__item {
  margin-bottom: 30px;
}
.projectpage-updates__item__wrapper {
  text-decoration: none;
  display: flex;
  align-items: flex-start;
}
.projectpage-updates__item--update .projectpage-updates__item__wrapper {
  align-items: center;
  padding: 20px 0;
}
@media (max-width: 800px) {
  .projectpage-updates__item--update {
    margin-left: -35px;
    margin-right: -35px;
  }
  .projectpage-updates__item--update .projectpage-updates__item__image {
    margin-left: 25px;
    margin-right: 40px;
  }
}
.projectpage-updates__item--update .projectpage-updates__item__wrapper {
  background-color: #f3f6d9;
}
.projectpage-updates__item__image {
  flex: none;
  width: 100px;
  margin-right: 30px;
  color: #4376a1;
  overflow: hidden;
  display: flex;
  justify-content: center;
  aspect-ratio: 1/1;
  border-radius: 4px;
}
@media (max-width: 533px) {
  .projectpage-updates__item__image {
    width: 18.75vw;
  }
}
@media (max-width: 320px) {
  .projectpage-updates__item__image {
    width: 60px;
  }
}
.projectpage-updates__item__image i[class^=PThU-icon-24px-] {
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 3/4;
  background-color: #E6F1F9;
  border-radius: 4px;
  width: 100%;
}
.projectpage-updates__item__image img {
  width: auto;
  height: 100%;
  vertical-align: bottom;
  border-radius: 4px;
}
.projectpage-updates__item--update .projectpage-updates__item__image {
  aspect-ratio: auto;
}
.projectpage-updates__item--publication .projectpage-updates__item__image {
  aspect-ratio: 3/4;
}
.projectpage-updates__item--update .projectpage-updates__item__image {
  display: flex;
  justify-content: flex-start;
  padding-left: 20px;
}
@media (max-width: 800px) {
  .projectpage-updates__item--update .projectpage-updates__item__image {
    justify-content: center;
  }
}
.projectpage-updates__item--update .projectpage-updates__item__image > img {
  border-radius: 50%;
  width: 60px;
  max-width: 100%;
}
.projectpage-updates__item__content {
  flex: 1 1 auto;
}
.projectpage-updates__item--update .projectpage-updates__item__content {
  padding-right: 20px;
}
.projectpage-updates__item__header, .projectpage-updates__item__footer {
  font-size: 12px;
  color: #747993;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}
.projectpage-updates__item__header {
  margin-bottom: 5px;
}
.projectpage-updates__item__header > div + div {
  margin-left: 10px;
}
.projectpage-updates__item__footer {
  margin-top: 5px;
  align-items: center;
}
.projectpage-updates__item__type {
  color: #4376a1;
}
.projectpage-updates__item__title {
  font-size: clamp(16px, 1.8vw, 18px);
  font-weight: 700;
}
.projectpage-updates__item__authors {
  display: flex;
  justify-content: flex-start;
  position: relative;
  z-index: 0;
  color: #4376a1;
  flex: none;
  margin-right: 7px;
}
.projectpage-updates__item__authors__thumb {
  margin-left: -2px;
  width: 34px;
  height: 34px;
  border: 2px solid #FFF;
  position: relative;
  z-index: 3;
  overflow: hidden;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  background-color: #E6F1F9;
}
.projectpage-updates__item__authors__thumb + .projectpage-updates__item__authors__thumb {
  margin-left: -7px;
  z-index: 2;
}
.projectpage-updates__item__authors__thumb + .projectpage-updates__item__authors__thumb + .projectpage-updates__item__authors__thumb {
  z-index: 1;
}
.projectpage-updates__item__authors__thumb i[class^=PThU-icon-24px-] {
  font-size: 18px;
  line-height: 30px;
}
.projectpage-updates__item__authors__thumb img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}
@media (max-width: 600px) {
  .projectpage-updates__item__description {
    display: none;
  }
}
@media (max-width: 450px) {
  .projectpage-updates__item__authors {
    display: none;
  }
  .projectpage-updates__item__meta b {
    font-weight: 400;
  }
}
.projectpage-updates__list[data-filter=news] .projectpage-updates__item--news .projectpage-updates__item__image, .projectpage-updates__list[data-filter=event] .projectpage-updates__item--event .projectpage-updates__item__image, .projectpage-updates__list[data-filter=blog] .projectpage-updates__item--blog .projectpage-updates__item__image {
  width: 195px;
  aspect-ratio: 16/9;
}
@media (max-width: 1040px) {
  .projectpage-updates__list[data-filter=news] .projectpage-updates__item--news .projectpage-updates__item__image, .projectpage-updates__list[data-filter=event] .projectpage-updates__item--event .projectpage-updates__item__image, .projectpage-updates__list[data-filter=blog] .projectpage-updates__item--blog .projectpage-updates__item__image {
    width: 18.75vw;
  }
}
@media (max-width: 320px) {
  .projectpage-updates__list[data-filter=news] .projectpage-updates__item--news .projectpage-updates__item__image, .projectpage-updates__list[data-filter=event] .projectpage-updates__item--event .projectpage-updates__item__image, .projectpage-updates__list[data-filter=blog] .projectpage-updates__item--blog .projectpage-updates__item__image {
    width: 60px;
  }
}