.widget-mailus {
  border-radius: 4px;
  background-color: rgba(89, 157, 215, 0.15);
  padding: 20px;
}
.widget-mailus a {
  white-space: nowrap;
  text-decoration: none;
  color: #4376a1;
}
.widget-mailus a i {
  font-size: 20px;
  vertical-align: middle;
  line-height: 25px;
  display: inline-block;
  padding-right: 10px;
  opacity: 0.7;
  transition: opacity 0.2s;
}
.widget-mailus a span {
  text-decoration: underline;
}
.widget-mailus a:hover i {
  opacity: 1;
}