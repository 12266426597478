.wh-details__toggle {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font: inherit;
}
.wh-details summary {
  position: relative;
  cursor: pointer;
  list-style-type: none;
}
.wh-details summary::-webkit-details-marker {
  display: none;
}
.wh-details summary::marker {
  display: none;
}
.wh-details__summary {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font: inherit;
  text-align: left;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 16px 50px 16px 0;
  cursor: pointer;
  line-height: 140%;
  font-weight: 700;
  outline: none;
  width: 100%;
}
.wh-details__summary h2 {
  margin: 0;
  line-height: 140%;
  white-space: normal;
  font-size: inherit;
}
.wh-details__summary::before, .wh-details__summary::after {
  position: absolute;
  top: 50%;
  margin-top: -9px;
  right: 10px;
  width: 20px;
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "PThU-iconfont-12px" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  font-size: 12px;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #6F7490;
}
.wh-details__summary::before {
  display: inline-block;
  content: "\e904"; /* PThU-icon-12px-plus */
}
.wh-details__summary::after {
  display: none;
  content: "\e905"; /* PThU-icon-12px-min */
}
.wh-details__summary::-webkit-details-marker {
  display: none;
}
.wh-details__container {
  height: 0;
  overflow: hidden;
}
.wh-details__container__inner {
  padding: 0 0 15px;
}
.wh-details__container__inner > * {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.wh-details__container__inner > *:first-child, .wh-details__container__inner > .wh-anchor:first-child + * {
  margin-top: 0;
}
.wh-details__container__inner > *:last-child {
  margin-bottom: 0;
}
.wh-details[open] > .wh-details__summary::before, .wh-details--open > .wh-details__summary::before {
  display: none;
}
.wh-details[open] > .wh-details__summary::after, .wh-details--open > .wh-details__summary::after {
  display: inline-block;
}
.wh-details[open] > .wh-details__container, .wh-details--open > .wh-details__container {
  height: auto;
}