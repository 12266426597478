.widget-projectblogs {
  position: relative;
  z-index: 0;
  pointer-events: none;
}
.widget-projectblogs__heading h3 {
  margin-left: 33.333%;
  padding-left: 12px;
  margin-bottom: 35px;
}
.widget-projectblogs__container {
  position: relative;
  min-height: 450px;
}
.widget-projectblogs__bgimage {
  border-radius: 4px;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 450px;
  z-index: -1;
}
.widget-projectblogs__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-left: 33.333%;
  padding-left: 12px;
  padding-top: 100px;
  pointer-events: initial;
}
.widget-projectblogs__item {
  margin-bottom: 20px;
}
.widget-projectblogs__item__link {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  text-decoration: none;
  padding: 30px 20px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #D1D2DB;
}
.widget-projectblogs__item__content {
  flex: 1 1 auto;
}
.widget-projectblogs__item__content__header {
  font-size: 12px;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}
.widget-projectblogs__item__content__header span + span::before {
  content: "|";
  display: inline-block;
  padding: 0 12px;
}
.widget-projectblogs__item__authorphoto {
  flex: none;
  width: 64px;
  aspect-ratio: 1/1;
  margin-right: 30px;
  border-radius: 50%;
  overflow: hidden;
}
.widget-projectblogs__item__authorphoto img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}
.widget-projectblogs__item__author {
  font-weight: 700;
}
.widget-projectblogs__item__date {
  opacity: 0.6;
}
.widget-projectblogs__item__image {
  flex: none;
  width: clamp(150px, 15vw, 224px);
  margin-left: 30px;
  border-radius: 4px;
  aspect-ratio: 16/9;
}
.widget-projectblogs__item:first-child .widget-projectblogs__item__image {
  display: none;
}
.widget-projectblogs__item__title {
  font-size: 18px;
  font-weight: 700;
}
.widget-projectblogs__item__description {
  margin-top: 4px;
}
.widget-projectblogs__item__more {
  display: none;
  margin-top: 3px;
  color: #599dd7;
  text-decoration: underline;
  text-underline-position: under;
}
@media (max-width: 800px) {
  .widget-projectblogs.fullwidth {
    margin-left: -35px;
    margin-right: -35px;
  }
  .widget-projectblogs__heading h3, .widget-projectblogs__list {
    margin-left: 0;
    padding-left: 0;
  }
}
@media (max-width: 600px) {
  .widget-projectblogs__container {
    min-height: 0;
  }
  .widget-projectblogs__bgimage {
    display: none;
  }
  .widget-projectblogs__heading h3 {
    margin-bottom: 20px;
  }
  .widget-projectblogs__list {
    padding-top: 0;
  }
  .widget-projectblogs__item:first-child .widget-projectblogs__item__image {
    display: block;
  }
  .widget-projectblogs__item--withimage .widget-projectblogs__item__link {
    flex-flow: column;
    padding: 0;
    position: relative;
  }
  .widget-projectblogs__item--withimage .widget-projectblogs__item__content {
    padding: 40px 20px 20px;
    display: flex;
    flex-flow: column;
  }
  .widget-projectblogs__item__title {
    order: -1;
  }
  .widget-projectblogs__item--withimage .widget-projectblogs__item__authorphoto {
    position: absolute;
    right: 0;
    top: calc(32vw - 35px);
  }
  .widget-projectblogs__item__author {
    font-weight: 400;
    font-size: 15px;
    margin-bottom: 10px;
  }
  .widget-projectblogs__item__date {
    display: none;
  }
  .widget-projectblogs__item__image {
    order: -1;
    margin-left: 0;
    width: 100%;
    aspect-ratio: 27/10;
  }
  .widget-projectblogs__item__more {
    display: block;
  }
}
@media (max-width: 465px) {
  .widget-projectblogs.fullwidth > .centercontent {
    padding-left: 35px;
    padding-right: 35px;
  }
}