html.wh-widgetpreview .widget-location {
  padding: 20px;
}

.widget-location {
  display: flex;
  align-items: flex-start;
}
.widget-location .widget__title {
  padding-top: 25px;
  padding-bottom: 0;
}
.widget-location__image {
  flex: none;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  overflow: hidden;
  margin-right: 35px;
  background: #EEEEF1 url(../../web/img/fallback.png) 50% 50% no-repeat;
  background-size: 85%;
}
.widget-location__image img {
  width: 100%;
  height: 100%;
}
.widget-location p {
  line-height: 165%;
  margin-bottom: 15px;
}
.widget-location p:last-child {
  margin-bottom: 0;
}