.basicslideshow {
  outline: none;
  position: relative;
  z-index: 0;
}
.basicslideshow__slide {
  opacity: 0;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.6s;
}
.basicslideshow__slide.active {
  z-index: 1;
  opacity: 1;
}