.widget-issuu__container {
  position: relative;
  overflow: hidden;
  border-radius: 4px;
}
.widget-issuu__container__content {
  border: 0 none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.widget-issuu__container::after {
  content: "";
  display: block;
  padding-top: 75%;
}