.widget-projectrelated__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.widget-projectrelated > h3 {
  margin-bottom: clamp(20px, 3.5vw, 35px);
}
.widget-projectrelated__item {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: clamp(30px, 5vw, 50px);
}
.widget-projectrelated__item__image {
  flex: none;
  width: clamp(60px, 15vw, 157px);
  margin-right: clamp(20px, 3vw, 35px);
  border-radius: 4px;
}
.widget-projectrelated__item__image img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
  border-radius: 4px;
}
.widget-projectrelated__item__content {
  flex: 1 1 auto;
}
.widget-projectrelated__item__suptitle {
  font-style: italic;
  margin-bottom: 3px;
  opacity: 0.6;
}
.widget-projectrelated__item__title {
  font-weight: 700;
  font-size: 18px;
}
.widget-projectrelated__item__description {
  margin-top: 10px;
}
@media (max-width: 600px) {
  .widget-projectrelated__item__description {
    display: none;
  }
}
.widget-projectrelated__item__footer {
  margin-top: 15px;
  display: flex;
  align-items: center;
}
.widget-projectrelated__item__footer a {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
}
.widget-projectrelated__item__footer a:hover > span {
  text-shadow: 0 0 1px #181f4b;
}
.widget-projectrelated__item__footer a:hover > i {
  text-shadow: 0 0 1px #6e7a00;
}
.widget-projectrelated__item__footer a i {
  font-size: 24px;
  color: #6e7a00;
  margin-right: 10px;
}
.widget-projectrelated__item__footer a + a {
  margin-left: clamp(30px, 5vw, 50px);
}
@media (max-width: 400px) {
  .widget-projectrelated__item__footer {
    margin-top: 10px;
    flex-flow: column;
    align-items: flex-start;
  }
  .widget-projectrelated__item__footer a + a {
    margin-left: 0;
    margin-top: 5px;
  }
}