.wh-form__fieldgroup:not(.wh-form__fieldgroup--hidden) {
  display: flex;
}

.wh-form__fieldgroup {
  align-items: baseline;
}

.wh-form__label, .wh-form__optiondata,
.wh-form__optionlabel,
.wh-form__subfieldlabel {
  display: inline-block;
}

.wh-form__label {
  flex-shrink: 0;
}

.wh-form__fields {
  flex: 1 0 0px;
}

.wh-form__fieldline {
  width: 100%;
  align-items: baseline;
  flex-wrap: wrap;
}

.wh-form__fieldline:not(.wh-form__fieldline--hidden) {
  display: inline-flex;
}

.wh-form__fieldline--nowrap:not(.wh-form__fieldline--hasinfotext) {
  flex-wrap: nowrap;
}

.wh-form__fieldgroup--horizontal .wh-form__fields {
  display: flex;
  flex-wrap: wrap;
}
.wh-form__fieldgroup--horizontal .wh-form__fieldline {
  flex: none;
  width: auto;
  max-width: 100%;
}

.wh-form__infotextwrapper {
  flex: 0 0 100%;
}

.wh-form__optiondata {
  display: inline-flex;
  width: 100%;
  align-items: baseline;
  flex-grow: 1;
  flex-wrap: wrap;
}
.wh-form__optiondata--vertical {
  width: 0;
}

.wh-form__subfield {
  display: flex;
  flex-grow: 1;
  align-items: baseline;
}

.wh-form__fieldline > input[type=checkbox],
.wh-form__fieldline > input[type=radio] {
  flex-shrink: 0;
}

.wh-form__fieldline > textarea {
  resize: vertical;
}

::-webkit-input-placeholder {
  opacity: 0.5;
  color: inherit;
}

::-moz-placeholder {
  opacity: 0.5;
  color: inherit;
}

:-ms-input-placeholder {
  opacity: 0.5;
  color: inherit;
}

input:-moz-placeholder {
  opacity: 0.5;
  color: inherit;
}

html.webform main > *:not(.fullwidth) {
  max-width: 804px;
}
html.webform main > *:not(.fullwidth).main__col {
  max-width: 66.6666%;
}
html.webform main > *:not(.fullwidth).wh-form {
  max-width: 1189px;
}
html.webform main > *:not(.fullwidth).wh-form .wh-form__buttongroup, html.webform main > *:not(.fullwidth).wh-form .wh-form__page > * {
  margin-left: auto;
  margin-right: auto;
  max-width: 734px;
}
@media (max-width: 800px) {
  html.webform main > *:not(.fullwidth) {
    max-width: 100% !important;
  }
}
html.webform--striped .wh-form__buttongroup {
  border-top: 0 none;
  margin-top: 0;
  padding: 0 35px 35px;
}
html.webform--striped .wh-form__page > .groupcontainer {
  max-width: 1189px !important;
  border-radius: 4px;
  padding: 50px 35px 30px;
}
html.webform--striped .wh-form__page > .groupcontainer > * {
  margin-left: auto;
  margin-right: auto;
  max-width: 734px;
}
html.webform--striped .groupcontainer:nth-child(2n+1) {
  background-color: #f4f4f7;
}
html.webform--striped .groupcontainer:nth-child(2n+1):last-child {
  margin-bottom: 30px;
}

/* general form layout */
.wh-styledinput {
  font-size: 15px;
}
.wh-styledinput .wh-form__richtext .centercontent {
  padding-right: 0;
  padding-left: 0;
}
.wh-styledinput .wh-anchor {
  scroll-margin-top: 120px;
  margin-top: -120px;
}
.wh-styledinput hr {
  display: block;
  border: 0 none;
  border-top: 1px solid #C5C5C5;
  margin: 30px 0 25px;
}
.wh-styledinput h3 {
  font-size: 21px;
}
.wh-styledinput h2 {
  margin-bottom: 0;
}
.wh-styledinput .wh-form__fieldgroup {
  margin-bottom: 15px;
}
.wh-styledinput .wh-form__optionlabel p {
  margin-bottom: 15px;
}
.wh-styledinput .wh-form__optionlabel p:last-child {
  margin-bottom: 0;
}
.wh-styledinput .wh-form__fieldgroup--required .wh-form__optionlabel p:last-child {
  display: inline;
}
.wh-styledinput .wh-form__fieldgroup--textarea > .wh-form__label,
.wh-styledinput .wh-form__fieldgroup--custom > .wh-form__label {
  align-self: flex-start;
  margin-top: 4px;
}
.wh-styledinput .wh-form__fieldgroup--required > .wh-form__label::after,
.wh-styledinput .wh-form__fieldgroup--required.wh-form__fieldgroup--checkbox .wh-form__optionlabel::after {
  display: inline-block;
  content: "*";
}
.wh-styledinput .wh-form__prefix {
  padding-right: 10px;
  align-self: center;
  font-size: 15px;
}
.wh-styledinput .wh-form__suffix {
  padding-left: 10px;
  align-self: center;
  font-size: 15px;
}
.wh-styledinput .wh-form__optionlabel, .wh-styledinput .wh-form__subfield > label {
  padding-right: 15px;
  font-size: 15px;
}
.wh-styledinput .wh-form__label:empty::after {
  display: none;
}
.wh-styledinput .wh-form__fieldgroup > .wh-form__label {
  flex: 0 0 285px;
  max-width: 285px;
  min-width: 285px;
  font-size: 15px;
  padding-right: 10px;
  font-weight: 700;
}
.wh-styledinput .wh-form__fieldgroup > .wh-form__fields {
  max-width: calc(100% - 285px);
}
.wh-styledinput .wh-form__fieldgroup--richtext > .wh-form__fields,
.wh-styledinput .wh-form__fieldgroup--group > .wh-form__fields {
  max-width: 100%;
}
.wh-styledinput .wh-form__fieldgroup--richtext > .wh-form__fields .wh-form__fieldline,
.wh-styledinput .wh-form__fieldgroup--group > .wh-form__fields .wh-form__fieldline {
  display: block;
}
.wh-styledinput .wh-form__fieldgroup--checkbox > .wh-form__fields {
  max-width: 100%;
}
@media (max-width: 550px) {
  .wh-styledinput .wh-form__fieldgroup {
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
    align-items: flex-start;
  }
  .wh-styledinput .wh-form__fieldgroup > .wh-form__label {
    padding-bottom: 3px;
    padding-right: 0;
  }
  .wh-styledinput .wh-form__fieldgroup .wh-form__subfield {
    flex-wrap: wrap;
  }
  .wh-styledinput .wh-form__fieldgroup > .wh-form__label, .wh-styledinput .wh-form__fieldgroup > .wh-form__fields {
    flex: none !important;
    max-width: calc(100vw - 40px) !important;
    min-width: 0 !important;
  }
  .wh-styledinput .wh-form__fields {
    width: 100%;
  }
}
.wh-styledinput select + .wh-form__subfield > label,
.wh-styledinput .wh-form__pulldown-styled + .wh-form__subfield > label {
  padding-left: 10px;
}
.wh-styledinput input, .wh-styledinput select:not(.wh-rtd__toolbarstyle),
.wh-styledinput textarea {
  min-height: 40px;
  padding: 9px 15px;
  flex: 1;
}
.wh-styledinput input, .wh-styledinput select:not(.wh-rtd__toolbarstyle),
.wh-styledinput textarea,
.wh-styledinput .wh-form__imgedit,
.wh-styledinput .datetime {
  border: 1px solid #8F93A8;
  font-family: inherit;
  font-size: 15px;
  line-height: 20px;
  max-width: 100%;
  color: inherit;
  border-radius: 0;
  background-color: #fff;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
}
.wh-styledinput input:disabled, .wh-styledinput input[data-wh-form-disabled], .wh-styledinput select:not(.wh-rtd__toolbarstyle):disabled, .wh-styledinput select:not(.wh-rtd__toolbarstyle)[data-wh-form-disabled],
.wh-styledinput textarea:disabled,
.wh-styledinput textarea[data-wh-form-disabled],
.wh-styledinput .wh-form__imgedit:disabled,
.wh-styledinput .wh-form__imgedit[data-wh-form-disabled],
.wh-styledinput .datetime:disabled,
.wh-styledinput .datetime[data-wh-form-disabled] {
  color: #C5C5C5;
  border-color: #C5C5C5;
  background-color: #F2F2F2;
  box-shadow: none;
}
.wh-styledinput input:focus-within, .wh-styledinput select:not(.wh-rtd__toolbarstyle):focus-within,
.wh-styledinput textarea:focus-within,
.wh-styledinput .wh-form__imgedit:focus-within,
.wh-styledinput .datetime:focus-within {
  border-color: #599dd7;
  box-shadow: none;
}
.wh-styledinput input:focus, .wh-styledinput input:hover, .wh-styledinput select:not(.wh-rtd__toolbarstyle):focus, .wh-styledinput select:not(.wh-rtd__toolbarstyle):hover,
.wh-styledinput textarea:focus,
.wh-styledinput textarea:hover,
.wh-styledinput .wh-form__imgedit:focus,
.wh-styledinput .wh-form__imgedit:hover,
.wh-styledinput .datetime:focus,
.wh-styledinput .datetime:hover {
  border-color: #599dd7;
  box-shadow: none;
}
.wh-styledinput textarea {
  min-height: 140px;
}
.wh-styledinput .wh-form__fieldgroup--error input, .wh-styledinput .wh-form__fieldgroup--error select,
.wh-styledinput .wh-form__fieldgroup--error textarea,
.wh-styledinput .wh-form__fieldgroup--error .wh-form__imgedit,
.wh-styledinput .wh-form__fieldgroup--error .wh-form__dateinputgroup,
.wh-styledinput .wh-form__fieldgroup--error .wh-form__timeinputgroup {
  border-color: #c0004c;
  color: #c0004c;
}
.wh-styledinput .wh-form__fieldgroup--error > .wh-form__label {
  color: #c0004c;
}
.wh-styledinput .wh-form__error {
  display: none;
  flex: none;
  width: 100%; /* force error text below item */
  color: #c0004c;
  padding: 8px 0 0;
  font-size: 15px;
  font-style: italic;
}
.wh-styledinput .wh-form__fieldgroup--error .wh-form__error {
  display: block;
}