.inlinewidget-infopopup-text {
  position: relative;
  cursor: pointer;
  display: inline-block;
}
.inlinewidget-infopopup-text::after {
  content: "";
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  position: absolute;
  background-color: #e3a51a;
}
.inlinewidget-infopopup-text__tip {
  padding: 4px 20px 5px;
  position: absolute;
  bottom: 100%;
  left: 10px;
  opacity: 0;
  transition: transform 0.2s, opacity 0.2s;
  transform-origin: center bottom;
  transform: scale(0.01, 0.01);
  background-color: #181f4b;
  color: #fff;
  border-radius: 4px;
  white-space: nowrap;
}
.inlinewidget-infopopup-text:hover .inlinewidget-infopopup-text__tip {
  transform: scale(1, 1);
  opacity: 1;
}

.inlinewidget-infopopup-popup__content h4 {
  margin-bottom: 15px;
}