.widget-contact ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding-bottom: 30px;
}
.widget-contact li + li {
  padding-top: 10px;
}
.widget-contact a {
  display: flex;
  text-decoration: none;
  color: inherit;
}
.widget-contact a:hover > .title {
  text-decoration: underline;
}
.widget-contact .icon {
  font-size: 24px;
  flex: none;
  width: 24px;
  text-align: center;
  margin-right: 10px;
  color: #6e7a00;
}