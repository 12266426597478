.widget-factfigures__box {
  background-color: #c4d24c;
}
.widget-factfigures__scrollarea {
  padding-top: 55px;
  padding-bottom: 55px;
  position: relative;
  overflow: hidden;
}
@media (max-width: 1153px) {
  .widget-factfigures__box {
    border-radius: 4px 0 0 4px;
    border-right: 0 none;
  }
}
.widget-factfigures.fullwidth .widget-factfigures__contents {
  min-width: 1119px;
}
@media (max-width: 1153px) {
  .widget-factfigures.fullwidth .widget-factfigures__contents {
    padding-right: 0;
  }
}
.widget-factfigures__contents {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}
.widget-factfigures__contents__item {
  flex: 1 0 25%;
  max-width: 25%;
  padding: 15px 50px 0 0;
  text-align: center;
}
@media (max-width: 1153px) {
  .widget-factfigures__contents__item {
    padding-left: 0;
    padding-right: 60px;
  }
}
.widget-factfigures__contents__item .value {
  font-size: 42px;
  font-family: "Noto Serif", sans-serif, serif, Arial;
  font-weight: 700;
  line-height: 120%;
  padding-bottom: 5px;
}
.widget-factfigures__contents__item .title {
  font-size: 18px;
  font-weight: 700;
  line-height: 135%;
}
.widget-factfigures__contents__item .description {
  line-height: 165%;
}
.widget-factfigures--circles .widget-factfigures__box {
  background-color: transparent;
}
.widget-factfigures--circles .widget-factfigures__scrollarea {
  width: 100%;
}
.widget-factfigures--circles .scrollbar-horizontal.ps > .ps__rail-x {
  margin-left: 0;
}
.widget-factfigures--circles .widget-factfigures__contents {
  justify-content: space-between;
  flex-wrap: nowrap;
}
.widget-factfigures--circles .widget-factfigures__contents__item {
  min-width: clamp(100px, 14vw, 142px);
  position: relative;
  background-color: #c4d24c;
  flex: none;
  padding: 0;
  display: inline-flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1/1;
  border-radius: 50%;
}
.widget-factfigures--circles .widget-factfigures__contents__item::before {
  background-color: #c4d24c;
  content: "";
  position: absolute;
  border-radius: 50%;
  width: 14px;
  height: 14px;
}
.widget-factfigures--circles .widget-factfigures__contents__item:first-child::before {
  bottom: 0;
  left: 0;
}
.widget-factfigures--circles .widget-factfigures__contents__item:first-child::after {
  border: 2px solid #c4d24c;
  content: "";
  position: absolute;
  border-radius: 50%;
  width: 41px;
  height: 41px;
  top: 5%;
  left: 100%;
}
.widget-factfigures--circles .widget-factfigures__contents__item:nth-child(2)::before {
  bottom: 10px;
  left: 100%;
}
.widget-factfigures--circles .widget-factfigures__contents__item:nth-child(2)::after {
  border: 2px solid #c4d24c;
  content: "";
  position: absolute;
  border-radius: 50%;
  width: 4px;
  height: 4px;
  top: 50%;
  left: 100%;
  margin-left: 25%;
}
.widget-factfigures--circles .widget-factfigures__contents__item:nth-child(3)::before {
  bottom: 50%;
  left: 100%;
  margin-left: 75%;
}
.widget-factfigures--circles .widget-factfigures__contents__item:nth-child(4)::before {
  top: 50%;
  left: 0;
  width: 26px;
  height: 26px;
  transform: translateX(-60%);
}
.widget-factfigures--circles .widget-factfigures__contents__item:nth-child(2n) {
  margin-top: 20%;
}
.widget-factfigures--circles .widget-factfigures__contents__item .title, .widget-factfigures--circles .widget-factfigures__contents__item .value {
  display: block;
  padding: 0 30px;
}
.widget-factfigures--circles .widget-factfigures__contents__item .value {
  font-size: clamp(28px, 4.2vw, 42px);
}
.widget-factfigures--circles .widget-factfigures__contents__item .title {
  font-size: clamp(13px, 1.5vw, 15px);
}