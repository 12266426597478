h2 + .widget-contentlist,
h3 + .widget-contentlist,
h4 + .widget-contentlist {
  margin-top: 20px;
}

.widget-contentlist {
  position: relative;
  margin-top: 40px;
}
.widget-contentlist--withthumbs ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.widget-contentlist--withthumbs li {
  margin-bottom: 20px;
  border: 1px solid #8F93A8;
  border-radius: 4px;
}
.widget-contentlist--withthumbs .readmore {
  text-align: right;
  color: #2d789e;
  text-decoration: underline;
  flex: none;
}
.widget-contentlist--withthumbs .title {
  flex: none;
}
.widget-contentlist--withthumbs .description {
  flex: 1 1 auto;
}
.widget-contentlist--withthumbs .widget-contentlist__item {
  align-items: stretch;
}
.widget-contentlist--withthumbs .widget-contentlist__item__textcontainer {
  padding: 23px 35px;
  display: flex;
  flex-flow: column;
}
@media (max-width: 1167px) {
  .widget-contentlist--withthumbs .widget-contentlist__item__textcontainer {
    padding: 2vw 3vw;
  }
}
@media (max-width: 500px) {
  .widget-contentlist--withthumbs .widget-contentlist__item__textcontainer {
    padding: 10px 15px;
  }
}
.widget-contentlist__item {
  display: flex;
  justify-content: space-between;
  color: inherit;
  text-decoration: none;
}
.widget-contentlist__item + .widget-contentlist__item {
  margin-top: 30px;
}
.widget-contentlist__item__image {
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  background: #6F7490 url(../../web/img/fallback.png) no-repeat 50% 50%;
  background-size: contain;
  flex: none;
  width: 29%;
  max-width: 29%;
  display: block;
  border-radius: 4px 0 0 4px;
  border-right: 1px solid #8F93A8;
  min-height: 100%;
}
.widget-contentlist__item__image::before {
  content: "";
  display: block;
  padding-top: 56.25%;
}
.widget-contentlist__item__image img {
  position: absolute;
  top: 0;
  left: -9999px;
  right: -9999px;
  height: 100%;
  margin: 0 auto;
}
.widget-contentlist__item__textcontainer {
  display: block;
  padding-left: 35px;
  flex: 1 1 auto;
  line-height: 25px;
}
.widget-contentlist__item__textcontainer > span {
  display: block;
}
.widget-contentlist__item__textcontainer .title {
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 4px;
}
@media (max-width: 600px) {
  .widget-contentlist__item__textcontainer > span.description {
    display: none;
  }
  .widget-contentlist__item__textcontainer .title {
    font-size: 15px;
  }
}
@media (max-width: 500px) {
  .widget-contentlist__item__image {
    max-width: 60px;
  }
  .widget-contentlist__item__image::before {
    padding-top: 100%;
  }
  .widget-contentlist__item__image img {
    width: auto;
    left: -9990px;
    right: -9999px;
    margin: 0 auto;
  }
  .widget-contentlist__item__textcontainer {
    padding-left: 15px;
  }
}