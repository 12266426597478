.purepublications {
  display: block;
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-bottom: 30px;
}
.purepublications__year {
  border-bottom: 1px solid #C5C5C5;
}
.purepublications .wh-details__summary {
  font-size: 18px;
}
.purepublications__year__content, .purepublications__highlightedpublications {
  list-style-type: none;
  margin: 0;
  padding: 0;
  line-height: 140%;
}
.purepublications__year__content li, .purepublications__highlightedpublications li {
  padding-bottom: 20px;
}
.purepublications__highlightedpublications {
  margin-bottom: 20px;
}
.purepublications .publication a {
  text-decoration: none;
  color: inherit;
  display: block;
}
.purepublications .publication a:hover {
  text-decoration: underline;
}
.purepublications .publication__authors {
  font-style: italic;
  margin-bottom: 3px;
}
.purepublications .publication__title {
  font-weight: 700;
}
.purepublications .publication__meta {
  margin-top: 3px;
}
.purepublications .publication__highlighted {
  position: absolute;
  transform: translate(-18px, 2px);
  display: none;
}
.purepublications .wh-details--open .publication__highlighted {
  display: inline-block;
}