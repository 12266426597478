html.wh-widgetpreview .widget-persons {
  padding: 20px;
}

.widget-persons ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.widget-persons ul li {
  flex: 1 1 50%;
  max-width: 50%;
  padding: 20px 0 0;
}
.widget-persons .person {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 350px;
}
.widget-persons .person__fullname {
  padding-top: 25px;
  padding-bottom: 0;
  font-size: 18px;
  font-weight: 700;
  display: inline-block;
}
.widget-persons .person__contact {
  display: flex;
  text-decoration: none;
  color: inherit;
  align-items: center;
  justify-content: flex-start;
  margin-top: 5px;
}
.widget-persons .person__contact .icon {
  flex: none;
  width: 20px;
  font-size: 20px;
  margin-right: 10px;
  color: #6e7a00;
}
.widget-persons .person__contact:hover > .value {
  text-decoration: underline;
}
.widget-persons .person a {
  color: inherit;
  transition: color 0.3s;
  display: inline-block;
}
.widget-persons .person a .value {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.widget-persons .person a:hover {
  color: #687500;
}
.widget-persons .person__photo {
  flex: none;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  overflow: hidden;
  margin-right: 35px;
  background: #EEEEF1 url(../../web/img/fallback.png) 50% 50% no-repeat;
  background-size: 85%;
}
.widget-persons .person__photo img {
  width: 100%;
  height: 100%;
}
.widget-persons .person__text {
  max-width: calc(100% - 130px);
}