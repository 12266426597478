@font-face {
  font-family: 'PThU-iconfont-24px';
  src:
    url("fonts/PThU-iconfont-24px.woff?xogki") format("woff2"),
    url('fonts/PThU-iconfont-24px.woff?xogki') format('woff'),
    url('fonts/PThU-iconfont-24px.ttf?xogki') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="PThU-icon-24px-"], [class*=" PThU-icon-24px-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'PThU-iconfont-24px' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.PThU-icon-24px-profile:before {
  content: "\e900";
}
.PThU-icon-24px-search:before {
  content: "\e901";
}
.PThU-icon-24px-internet:before {
  content: "\e902";
}
.PThU-icon-24px-menu:before {
  content: "\e903";
}
.PThU-icon-24px-location:before {
  content: "\e904";
}
.PThU-icon-24px-calendar:before {
  content: "\e905";
}
.PThU-icon-24px-mail:before {
  content: "\e906";
}
.PThU-icon-24px-filter:before {
  content: "\e907";
}
.PThU-icon-24px-phone:before {
  content: "\e908";
}
.PThU-icon-24px-mobile:before {
  content: "\e909";
}
.PThU-icon-24px-duration:before {
  content: "\e90a";
}
.PThU-icon-24px-tag:before {
  content: "\e90b";
}
.PThU-icon-24px-briefcase:before {
  content: "\e90c";
}
.PThU-icon-24px-university:before {
  content: "\e90d";
}
.PThU-icon-24px-star:before {
  content: "\e90e";
}
.PThU-icon-24px-arrow:before {
  content: "\e90f";
}
.PThU-icon-24px-add:before {
  content: "\e910";
}
.PThU-icon-24px-facebook:before {
  content: "\e911";
}
.PThU-icon-24px-linkedin:before {
  content: "\e912";
}
.PThU-icon-24px-twitter:before {
  content: "\e913";
}
.PThU-icon-24px-check:before {
  content: "\e914";
}
.PThU-icon-24px-file-word:before {
  content: "\e915";
}
.PThU-icon-24px-file-excel:before {
  content: "\e916";
}
.PThU-icon-24px-file-powerpoint:before {
  content: "\e917";
}
.PThU-icon-24px-file-pdf:before {
  content: "\e918";
}
.PThU-icon-24px-file-download:before {
  content: "\e919";
}
.PThU-icon-24px-link-intern:before {
  content: "\e91b";
}
.PThU-icon-24px-link-extern:before {
  content: "\e91a";
}
.PThU-icon-24px-books:before {
  content: "\e91c";
}
.PThU-icon-24px-book-open:before {
  content: "\e91d";
}
.PThU-icon-24px-download:before {
  content: "\e91e";
}
.PThU-icon-24px-list:before {
  content: "\e91f";
}
