.widget-researchdetails .wh-details {
  background-color: #EEEEF1;
  border-radius: 4px;
  padding: 20px;
  line-height: 25px;
  min-height: 110px;
}
.widget-researchdetails .wh-details__summary::before {
  margin-top: -8px;
  right: 20px;
}
@media (min-width: 800px) and (max-width: 950px) {
  .widget-researchdetails .wh-details__summary::before {
    margin-top: 0;
    top: 58px;
  }
}
@media (max-width: 650px) {
  .widget-researchdetails .wh-details__summary::before {
    margin-top: 0;
    top: 58px;
  }
}
.widget-researchdetails .wh-details__container {
  position: relative;
  top: -70px;
}
.widget-researchdetails .wh-details__container__inner {
  padding-bottom: 0;
}
.widget-researchdetails__preview {
  background-color: #fff;
  border-radius: 4px;
  padding: 20px 50px 20px 30px;
  min-height: 70px;
}
.widget-researchdetails__preview__inner {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
  overflow: hidden;
  min-height: 30px;
  position: relative;
  padding-right: 10px;
}
.widget-researchdetails__preview__inner::after {
  content: "";
  display: block;
  width: 10px;
  position: absolute;
  right: 0;
  top: 0;
  height: 30px;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
}
@media (min-width: 800px) and (max-width: 950px) {
  .widget-researchdetails__preview__inner {
    padding-top: 25px;
  }
  .widget-researchdetails__preview__title {
    position: absolute;
    top: 0;
    left: 0;
  }
}
@media (max-width: 650px) {
  .widget-researchdetails__preview__inner {
    padding-top: 25px;
  }
  .widget-researchdetails__preview__title {
    position: absolute;
    top: 0;
    left: 0;
  }
}
.widget-researchdetails__preview__title {
  margin-right: 20px;
}
.widget-researchdetails__preview__item {
  padding-left: 10px;
  font-weight: 400;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.widget-researchdetails__preview__item + .widget-researchdetails__preview__item {
  margin-left: 20px;
}
@media (max-width: 1000px) {
  .widget-researchdetails__preview__item + .widget-researchdetails__preview__item {
    margin-left: 2vw;
  }
}
.widget-researchdetails__preview__item > span {
  overflow: hidden;
  text-overflow: ellipsis;
}
.widget-researchdetails__preview span {
  display: inline-block;
  line-height: 22px;
  vertical-align: top;
  padding-left: 10px;
}
.widget-researchdetails__preview .icon {
  flex: none;
  font-size: 24px;
  line-height: 25px;
  color: #6e7a00;
}
.widget-researchdetails__details {
  padding: 23px 30px 50px;
  background-color: #fff;
  border-radius: 4px;
  position: relative;
}
.widget-researchdetails__details__close {
  font-size: 24px;
  cursor: pointer;
  right: 25px;
  top: 25px;
  position: absolute;
  transform: rotate(45deg);
  width: 24px;
  height: 24px;
}
.widget-researchdetails__details dl {
  padding: 30px 0 0;
  margin: 0;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
}
.widget-researchdetails__details dt, .widget-researchdetails__details dd {
  margin: 0;
  padding: 7px 0;
}
.widget-researchdetails__details dt {
  font-weight: 700;
  text-align: right;
  flex: 1 0 35%;
  max-width: 35%;
  padding-right: 15px;
}
.widget-researchdetails__details dd {
  flex: 1 0 65%;
  max-width: 65%;
}
.widget-researchdetails__details a {
  color: inherit;
  transition: color 0.3s;
}
.widget-researchdetails__details a:hover {
  color: #687500;
}
@media (min-width: 800px) and (max-width: 850px) {
  .widget-researchdetails__details dl {
    display: block;
  }
  .widget-researchdetails__details dt {
    padding-bottom: 0;
  }
  .widget-researchdetails__details dd {
    padding-top: 0;
  }
  .widget-researchdetails__details dt, .widget-researchdetails__details dd {
    text-align: left;
  }
}
@media (max-width: 600px) {
  .widget-researchdetails__details dl {
    display: block;
  }
  .widget-researchdetails__details dt {
    padding-bottom: 0;
  }
  .widget-researchdetails__details dd {
    padding-top: 0;
  }
  .widget-researchdetails__details dt, .widget-researchdetails__details dd {
    text-align: left;
  }
}