footer {
  max-width: 100vw;
  overflow: hidden;
}

.footer__topbar {
  margin-top: 30px;
  padding-bottom: 30px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #8F93A8;
}
.footer__topbar .footer__logo {
  flex: none;
  display: flex;
  text-decoration: none;
  width: 350px;
  justify-content: flex-start;
  align-items: center;
}
.footer__topbar .footer__logo--small {
  display: none;
}
.footer__topbar .footer__logo img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}
@media (max-width: 700px) {
  .footer__topbar {
    display: block;
  }
  .footer__topbar .sharepage {
    margin-top: 20px;
  }
}
@media (max-width: 465px) {
  .footer__topbar .footer__logo {
    width: 116px;
  }
  .footer__topbar .footer__logo--default {
    display: none;
  }
  .footer__topbar .footer__logo--small {
    display: block;
  }
}

.footer__infobar {
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.footer__infobar__title {
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 10px;
}
.footer__infobar__locaties, .footer__infobar__quicklinks {
  flex: 1 0 25%;
  max-width: 25%;
  padding-left: 35px;
}
.footer__infobar__about {
  flex: 1 0 50%;
  max-width: 50%;
  padding-right: 35px;
}
@media (max-width: 800px) {
  .footer__infobar {
    flex-wrap: wrap;
  }
  .footer__infobar__about:empty {
    display: none;
  }
  .footer__infobar__about {
    flex: none;
    width: 100%;
    max-width: 100%;
    padding-right: 0;
    margin-bottom: 20px;
  }
  .footer__infobar__locaties {
    padding-right: 35px;
  }
  .footer__infobar__locaties, .footer__infobar__quicklinks {
    flex-basis: 50%;
    max-width: 50%;
    padding-left: 0;
  }
}
@media (max-width: 465px) {
  .footer__infobar {
    display: block;
  }
  .footer__infobar__locaties {
    padding-right: 0;
  }
  .footer__infobar__quicklinks {
    padding-top: 20px;
  }
  .footer__infobar__locaties, .footer__infobar__quicklinks {
    max-width: 100%;
  }
}

.footer__bottombar {
  background-color: #454b6e;
  color: #fff;
  margin-top: 30px;
}
.footer__bottombar a {
  color: inherit;
  text-underline-position: under;
  text-decoration: none;
}
.footer__bottombar a:hover {
  text-decoration: underline;
}
.footer__bottombar .footer__container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 30px;
  padding-bottom: 30px;
}
.footer__bottombar .footer__nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.footer__bottombar .footer__nav li {
  display: inline-block;
  margin-right: 30px;
}
.footer__bottombar .footer__nav li:last-child {
  margin-right: 0;
}
@media (max-width: 750px) {
  .footer__bottombar .footer__container {
    display: block;
    text-align: center;
  }
  .footer__bottombar .socialmedia {
    padding-bottom: 10px;
  }
  .footer__bottombar .copyright {
    padding-bottom: 15px;
  }
}

.socialmedia {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.socialmedia__item {
  margin: 0 7px;
}
.socialmedia__link {
  text-decoration: none;
  color: #fff;
  display: inline-block;
}
.socialmedia__icon {
  width: auto;
  height: 18px;
  transition: translate 0.3s;
}
.socialmedia__icon--twitter {
  position: relative;
  font-size: 20px;
  line-height: 20px;
  top: -2px;
  font-weight: 700;
  font-family: Arial-black;
}
.socialmedia__link:hover, .socialmedia__link:focus .socialmedia__icon {
  transform: scale(1.1);
  text-decoration: none !important;
}