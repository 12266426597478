.pureactivities {
  display: block;
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-bottom: 30px;
}
.pureactivities__year {
  border-bottom: 1px solid #C5C5C5;
}
.pureactivities .wh-details__summary {
  font-size: 18px;
}
.pureactivities__year__content {
  list-style-type: none;
  margin: 0;
  padding: 0;
  line-height: 140%;
}
.pureactivities__year__content li {
  padding-bottom: 20px;
}
.pureactivities .activity a {
  text-decoration: none;
  color: inherit;
  display: block;
}
.pureactivities .activity a:hover {
  text-decoration: underline;
}
.pureactivities .activity__title {
  font-weight: 700;
}
.pureactivities .activity__meta {
  margin-top: 3px;
}