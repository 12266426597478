html.nabpage--blog .main--hasleftcolumn .main__col--left, html.nabpage--news .main--hasleftcolumn .main__col--left, html.nabpage--event .main--hasleftcolumn .main__col--left {
  padding-right: 35px;
  width: 25%;
}

@media (max-width: 800px) {
  html.nabpage--event .main--hasleftcolumn {
    display: flex;
    flex-flow: column;
  }
  html.nabpage--event .main--hasleftcolumn .main__col--left {
    width: 100%;
    padding-right: 0;
    max-width: none;
    order: 2;
  }
  html.nabpage--event .main--hasleftcolumn .main__col--left > .widget-opendays {
    display: block;
  }
}

html.nabpage--blog .main__col--left {
  display: flex;
  flex-flow: column;
}
html.nabpage--blog .main__col--left > .widget {
  flex: none;
}
html.nabpage--blog .main__col--left .verticaloutfill {
  flex: 1 1 auto;
}
@media (max-width: 800px) {
  html.nabpage--blog .main--hasleftcolumn {
    display: flex;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
  }
  html.nabpage--blog .main__col--left {
    order: 2;
    width: 100% !important;
    max-width: none;
    padding-right: 0 !important;
  }
  html.nabpage--blog .main__col--left .widget-mailus, html.nabpage--blog .main__col--left .bibleblog-disclaimer {
    display: block;
  }
}

.bibleblog-disclaimer {
  border-radius: 4px;
  background-color: rgba(227, 165, 26, 0.15);
  padding: 20px;
}

.nabpage-introblock {
  font-size: 18px;
  line-height: 140%;
}
.nabpage-introblock__date {
  color: #6F7490;
  display: inline-block;
  float: left;
  padding-right: 15px;
}
.nabpage-introblock::after {
  display: block;
  content: "";
  clear: both;
}

.nabpage-author {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;
  margin-top: -10px;
}
.nabpage-author__image {
  flex: none;
  width: 64px;
  position: relative;
  background: #6F7490 url(../pthu2020/web/img/fallback.png) no-repeat 50% 50%;
  background-size: 80% 80%;
  border-radius: 50%;
  overflow: hidden;
}
.nabpage-author__image[data-loadimage], .nabpage-author__image.image-loaded {
  background-image: none;
}
.nabpage-author__image::before {
  content: "";
  display: block;
  padding-top: 100%;
}
.nabpage-author__image > img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.nabpage-author__info {
  padding-left: 20px;
}
.nabpage-author__info__author {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.nabpage-author .fullname {
  font-weight: 700;
}
.nabpage-author a {
  color: inherit;
  transition: color 0.3s;
}
.nabpage-author a:hover {
  color: #687500;
}
.nabpage-author .email {
  flex: none;
  padding-left: 12px;
  text-decoration: none;
  font-size: 24px;
  vertical-align: top;
  position: relative;
  top: -7px;
  height: 24px;
}

.nabpage-pagemetadata {
  margin-top: 30px;
}
.nabpage-pagemetadata__register {
  margin-bottom: 30px;
}
.nabpage-pagemetadata dl {
  margin: 0 0 30px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}
.nabpage-pagemetadata dt, .nabpage-pagemetadata dd {
  display: inline-block;
  margin: 0;
  padding: 0 0 10px;
}
.nabpage-pagemetadata dt {
  font-weight: 700;
  flex: none;
  width: 100px;
}
.nabpage-pagemetadata dd {
  flex-basis: calc(100% - 100px);
}
.nabpage-pagemetadata .value {
  display: inline-block;
  margin-left: 15px;
}
.nabpage-pagemetadata dd a {
  color: #6F7490;
}

.nabpage-contentfooter {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 30px;
  margin-bottom: 40px;
}
.nabpage-contentfooter .forumcommentcount {
  flex: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.nabpage-contentfooter .forumcommentcount > * {
  margin-top: 0;
}
.nabpage-contentfooter .forumcommentcount__count {
  margin-top: 4px;
  color: #6F7490;
  margin-right: 15px;
}
.nabpage-contentfooter .forumcommentcount__count:empty {
  display: none;
}
.nabpage-contentfooter .forumcommentcount__button {
  margin-top: 4px;
  margin-right: 15px;
}
.nabpage-contentfooter .forumcommentcount__button[hidden] {
  display: none;
}
.nabpage-contentfooter .alignhelper {
  flex: 1 1 auto;
}
.nabpage-contentfooter .sharepage {
  flex: none;
  display: flex;
  align-items: center;
  color: #6F7490;
}
.nabpage-contentfooter .sharepage__title {
  padding-right: 15px;
}
@media (min-width: 801px) and (max-width: 950px) {
  .nabpage-contentfooter .forumcommentcount {
    flex-flow: column;
  }
}
@media (max-width: 650px) {
  .nabpage-contentfooter .forumcommentcount {
    flex-flow: column;
  }
}
@media (max-width: 550px) {
  .nabpage-contentfooter {
    display: block;
  }
  .nabpage-contentfooter .forumcommentcount {
    flex-flow: row;
    margin-bottom: 15px;
    justify-content: flex-start;
  }
  .nabpage-contentfooter .forumcommentcount__button {
    margin-right: 0;
  }
  .nabpage-contentfooter .sharepage {
    justify-content: flex-start;
  }
}
@media (max-width: 400px) {
  .nabpage-contentfooter .sharepage__title {
    display: none;
  }
}

.nab-relateditems {
  background-color: #EEEEF1;
  padding: 60px 0;
}
.nab-relateditems h4 {
  padding-bottom: 20px;
}
.nab-relateditems ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  margin-left: -35px;
}
.nab-relateditems ul li {
  flex: 1 1 33.3333%;
  max-width: 33.3333%;
  padding-left: 35px;
}
@media (max-width: 800px) {
  .nab-relateditems ul {
    flex-wrap: wrap;
  }
  .nab-relateditems ul li {
    flex: 50%;
    max-width: 50%;
    margin-bottom: 35px;
  }
  .nab-relateditems ul li:last-child {
    margin-bottom: 0;
  }
}
@media (max-width: 550px) {
  .nab-relateditems ul {
    display: block;
  }
  .nab-relateditems ul li {
    max-width: 100%;
    margin-bottom: 35px;
  }
}
@media (max-width: 465px) {
  .nab-relateditems ul li:not(:last-child) {
    margin-bottom: 20px;
  }
}
.nab-relateditems .nab-relateditems__item {
  background-color: #fff;
  height: 100%;
  padding-bottom: 40px;
  display: block;
  text-decoration: none;
  color: inherit;
  border-radius: 4px;
  border: 1px solid #8F93A8;
}
.nab-relateditems .nab-relateditems__item > span {
  display: block;
}
.nab-relateditems .nab-relateditems__item__image {
  border-radius: 4px 4px 0 0;
  overflow: hidden;
  position: relative;
  background: #6F7490 url(../pthu2020/web/img/fallback.png) no-repeat 50% 50%;
  background-size: contain;
}
.nab-relateditems .nab-relateditems__item__image::before {
  content: "";
  display: block;
  padding-top: 50%;
}
.nab-relateditems .nab-relateditems__item__image img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.nab-relateditems .nab-relateditems__item__author-photocontainer {
  text-align: right;
  padding: 0 30px;
}
.nab-relateditems .nab-relateditems__item__author-photo {
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
  width: 64px;
  height: 64px;
  position: relative;
  margin-top: -32px;
}
.nab-relateditems .nab-relateditems__item__author-photo::before {
  content: "";
  display: block;
  padding-top: 100%;
}
.nab-relateditems .nab-relateditems__item__author-photo img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.nab-relateditems .nab-relateditems__item__title {
  font-weight: 700;
  font-size: 18px;
  padding: 42px 30px 0;
}
.nab-relateditems .nab-relateditems__item__author-photocontainer + .nab-relateditems__item__title {
  padding-top: 0;
}
.nab-relateditems .nab-relateditems__item__author {
  padding: 0 30px;
}
.nab-relateditems .nab-relateditems__item__description {
  padding: 15px 30px 0;
}
.nab-relateditems .nab-relateditems__item .readmore {
  color: #4376a1;
  text-decoration: underline;
}

.wh-forumcomments {
  margin-top: 60px;
}
.wh-forumcomments .wh-forumcomments__posts {
  padding: 0 100px 40px 33.3333%;
}
@media (max-width: 800px) {
  .wh-forumcomments .wh-forumcomments__postcontainer {
    padding-left: 0 !important;
  }
  .wh-forumcomments .wh-forumcomments__posts {
    padding: 0 0 20px;
  }
}
.wh-forumcomments .wh-forumcomments__posts__title {
  display: block;
  font-weight: 700;
  font-size: 30px;
  padding-bottom: 20px;
  padding-left: 12px;
}
.wh-forumcomments .wh-forumcomments__posts__title small {
  font-size: inherit;
  font-weight: 300;
  display: inline-block;
  padding-left: 10px;
}
.wh-forumcomments .wh-forumcomments__posts__title small::before {
  content: "(";
}
.wh-forumcomments .wh-forumcomments__posts__title small::after {
  content: ")";
}
.wh-forumcomments .wh-forumcomments__postcontainer {
  padding-left: 12px;
}
.wh-forumcomments .wh-forumcomments__postcontainer:nth-child(2n) > div {
  background-color: #EEEEF1;
  border-radius: 4px;
}
.wh-forumcomments .wh-forumcomments__post {
  padding: 25px 25px 10px;
  line-height: 165%;
}
.wh-forumcomments .wh-forumcomments__name {
  font-weight: 700;
}
.wh-forumcomments .wh-forumcomments__postdate {
  text-align: right;
  font-size: 12px;
  color: #6F7490;
}
.wh-forumcomments .wh-forumcomments__respond {
  background-color: #EEEEF1;
  border-radius: 4px;
  padding: 40px 100px 40px 33.3333%;
}
@media (max-width: 800px) {
  .wh-forumcomments .wh-forumcomments__respond {
    padding: 35px;
  }
}
@media (max-width: 465px) {
  .wh-forumcomments .wh-forumcomments__respond {
    padding: 20px;
  }
}
.wh-forumcomments .wh-forumcomments__respond .wh-form {
  padding-left: 12px;
}
.wh-forumcomments .wh-forumcomments__respond .wh-form.wh-styledinput .wh-form__fieldgroup > .wh-form__label {
  flex: 0 1 285px;
  min-width: 0 !important;
}
.wh-forumcomments .wh-forumcomments__respond .wh-form.wh-styledinput .wh-form__fieldgroup > .wh-form__fields {
  max-width: none;
  flex: 1 1 285px;
  min-width: 285px;
}
.wh-forumcomments .wh-forumcomments__respond .wh-form::before {
  display: block;
  content: "Laat je reactie achter";
  font-weight: 700;
  font-size: 20px;
  padding-bottom: 30px;
}
html[lang=en] .wh-forumcomments .wh-forumcomments__respond .wh-form::before {
  content: "Leave your message here";
}
.wh-forumcomments .wh-forumcomments__respond .wh-form__buttongroup {
  border-top: 0 none;
  margin: 0;
  padding-top: 10px;
  padding-bottom: 0;
}