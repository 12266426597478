header {
  position: relative;
  z-index: 1;
  pointer-events: none;
  /* prevent occacional double click from selecting text */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
header button {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font: inherit;
  height: 100%;
  font: inherit;
}
header > * {
  pointer-events: auto;
}
header .header__inner {
  height: 130px;
  position: relative;
}
header .header__logo {
  display: flex;
  position: absolute;
  left: 35px;
  top: 43px;
  text-decoration: none;
  width: 345px;
  justify-content: flex-start;
  align-items: center;
}
html.site--standalone header .header__logo {
  top: 7px;
  height: 116px;
}
header .header__logo--small {
  display: none;
}
header .header__logo img {
  max-width: 100%;
  height: auto;
}
header .togglemobilemenu {
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 15px 30px 0 rgba(24, 31, 75, 0.2);
  font-size: 24px;
  width: 70px;
  height: 70px;
  position: fixed;
  right: 35px;
  top: 30px;
  z-index: 1;
  display: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
html.showmobilenav:not(.showsearchoverlay) header {
  /* fullwidth fixed header bar*/
}
html.showmobilenav:not(.showsearchoverlay) header .togglemobilemenu {
  display: flex;
}
@media (max-width: 750px) {
  html.showmobilenav:not(.showsearchoverlay) header {
    position: fixed;
    min-height: 70px;
    top: 0;
    left: 0;
    right: 0;
  }
  html.showmobilenav:not(.showsearchoverlay) header .header__inner {
    background-color: #fff;
    height: 70px;
    border-radius: 0;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
  }
  html.showmobilenav:not(.showsearchoverlay) header.centercontent {
    width: auto;
  }
  html.showmobilenav:not(.showsearchoverlay) header .header__logo {
    width: 116px;
    left: 20px;
    top: 16px;
  }
  html.showmobilenav:not(.showsearchoverlay) header .togglemobilemenu {
    box-shadow: none;
    position: absolute;
    top: 0;
    right: 0;
    transform: none !important;
  }
  html.showmobilenav:not(.showsearchoverlay) header .header__logo--default {
    display: none;
  }
  html.showmobilenav:not(.showsearchoverlay) header .header__logo--small {
    display: block;
    height: 40px;
    width: auto;
  }
}
@media (max-width: 750px) {
  html.site--standalone.showmobilenav:not(.showsearchoverlay) header .header__logo {
    height: 40px;
  }
}

@media (min-width: 751px) {
  html.stickyheader:not(.showmobilenav) header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }
  html.stickyheader:not(.showmobilenav) header .header__logo {
    max-height: 45px;
  }
  html.stickyheader:not(.showmobilenav) header .header__logo .header__logo--default.header__logo--custom {
    display: none;
  }
  html.stickyheader:not(.showmobilenav) header .header__logo .header__logo--small.header__logo--custom {
    display: block;
    height: 40px;
    width: auto;
  }
  html.stickyheader:not(.showmobilenav) header .header__inner {
    border-radius: 0 0 4px 4px;
    background-color: #fff;
    height: 70px;
    box-shadow: 0 15px 30px 0 rgba(24, 31, 75, 0.2);
    max-width: 1159px;
  }
  html.stickyheader:not(.showmobilenav) header .header__logo {
    top: 12px;
    left: 20px;
  }
  html.stickyheader:not(.showmobilenav) header .navigation {
    top: 0;
    right: 0;
    border-radius: 0 0 4px 0;
  }
  html.stickyheader:not(.showmobilenav) header .navigation__items {
    border-radius: 0 0 4px 0;
  }
  html.stickyheader:not(.showmobilenav) header .navigation ul {
    box-shadow: none;
  }
  html.stickyheader:not(.showmobilenav) .sitewrapper {
    padding-top: 130px;
  }
}