.personalprojects {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.personalprojects__project {
  border-bottom: 1px solid #D1D2DB;
}
.personalprojects__project ul.unordered,
.personalprojects__project ol.ordered {
  margin-left: 0;
  margin-right: 0;
  padding-left: 10px;
  padding-right: 0;
}
.personalprojects__project__link {
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  font-size: 15px;
  text-underline-position: under;
  line-height: 24px;
}
.personalprojects__project__link i {
  margin-right: 10px;
  color: #6e7a00;
  font-size: 24px;
}
.personalprojects__project__link:hover > span {
  text-decoration: underline;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
}