/*
Note: style also use by .related-course-event in bibleblog
*/
.widget-cta > a {
  display: flex;
  height: 100%;
  background-color: #c4d24c;
  border-radius: 4px;
  color: inherit;
  position: relative;
  line-height: 25px;
  padding: 40px 100px 40px 40px;
  transition: background-color 0.2s;
  text-decoration: none;
}
.widget-cta > a > * {
  display: block;
}
.widget-cta > a:hover {
  background-color: #acc000;
}
.widget-cta__note {
  flex: none;
  width: calc(25% + 5px);
  font-size: 18px;
  margin-right: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 600px) {
  .widget-cta__note {
    margin-right: 0;
    margin-bottom: 20px;
    width: 100%;
  }
  .widget-cta > a {
    display: block;
  }
}
.widget-cta__text {
  flex: 1 1 auto;
}
.widget-cta__text > * {
  display: block;
}
.widget-cta__suptitle, .widget-cta__subtitle {
  font-size: 13px;
}
.widget-cta__suptitle {
  opacity: 0.6;
}
.widget-cta__title {
  font-size: 18px;
}
.widget-cta__arrow {
  width: 42px;
  height: 42px;
  flex: none;
  display: block;
  text-align: center;
  line-height: 42px;
  border-radius: 50%;
  background-color: #fff;
  font-size: 24px;
  color: #c4d24c;
}
.widget-cta__arrow--right {
  position: absolute;
  top: 50%;
  right: 20px;
  margin-top: -21px;
}
.widget-cta__arrow--left {
  margin-right: 15px;
}
.widget-cta--green-light > a {
  background-color: #e8eebd;
  padding: 30px 80px 30px 30px;
}
.widget-cta--green-light > a .widget-cta__arrow {
  color: #181F4B;
  border: 1px solid #181F4B;
}
.widget-cta--green-light > a:hover {
  background-color: #ddeb79;
}
.widget-cta--blue-light > a {
  background-color: #e3eef7;
  padding: 30px 80px 30px 30px;
}
.widget-cta--blue-light > a .widget-cta__arrow {
  color: #181F4B;
  border: 1px solid #181F4B;
}
.widget-cta--blue-light > a:hover {
  background-color: #c6e0f6;
}
.widget-cta--grey > a {
  background-color: #EEEEF1;
  padding: 20px 20px 20px 20px;
}
.widget-cta--grey > a:hover {
  background-color: #8F93A8;
}
.widget-cta--arrow-left > a {
  padding-left: 80px;
}
.widget-cta--arrow-left .widget-cta__arrow {
  position: absolute;
  top: 50%;
  left: 20px;
  margin-top: -21px;
}
.widget-cta--grey .widget-cta__arrow {
  color: #8F93A8;
}
.widget-cta__line {
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}
.widget-cta--adaptive > a {
  padding-right: 40px;
}
.widget-cta--adaptive b {
  font-size: 18px;
  margin-bottom: 5px;
}
.widget-cta--adaptive .widget-cta__button {
  margin-top: 15px;
  display: flex;
  border: 1px solid #6F7490;
  color: #fff;
  display: inline-flex;
}
.widget-cta--adaptive .widget-cta__button .arrowleft {
  display: inline-block;
  margin-left: -10px;
  margin-right: 10px;
  font-size: 24px;
  line-height: 20px;
  color: #8F93A8;
}
.widget-cta--white {
  background-color: #fff;
}
.widget-cta--white a {
  color: #181f4b;
  background-color: #f3f6d9;
}
.widget-cta--green.widget-cta--adaptive .widget-cta__button {
  border-color: transparent;
  background-color: #fff;
  color: #454b6e;
}
.widget-cta--green.widget-cta--adaptive .widget-cta__button .arrowleft {
  color: #c4d24c;
}
.widget-cta--green.widget-cta--adaptive a:hover {
  background-color: #c4d24c;
}
.widget-cta--green.widget-cta--adaptive a:hover .widget-cta__button {
  background-color: #6e7a00;
  color: #fff;
}
.widget-cta--green.widget-cta--adaptive a:hover .widget-cta__button .arrowleft {
  color: inherit;
}
.widget-cta--blue {
  background-color: #fff;
}
.widget-cta--blue .widget-cta__arrow {
  color: #454b6e;
}
.widget-cta--blue a {
  color: #fff;
  background-color: #454b6e;
}
.widget-cta--blue a:hover {
  background-color: #6F7490;
}
.widget-cta--blue.widget-cta--adaptive a:hover {
  background-color: #454b6e;
}
.widget-cta--blue.widget-cta--adaptive a:hover .widget-cta__button {
  background-color: #181f4b;
}

.widget-cta-header {
  background-color: #454b6e;
  position: relative;
  z-index: 2;
  box-shadow: 0 12.15px 30px 0 rgba(24, 31, 75, 0.2);
  overflow: hidden;
  max-height: 0;
  display: flex;
  align-items: flex-end;
  margin-bottom: 0 !important;
  color: #fff;
}
html.wh-widgetpreview .widget-cta-header {
  max-height: none;
  overflow: visible;
}
html.showheadercta .widget-cta-header {
  max-height: none;
}
.widget-cta-header__inner {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 15px 15px 15px 20px;
  color: inherit;
}
.widget-cta-header a {
  color: inherit;
  text-decoration: none;
}
.widget-cta-header__link {
  flex: none;
  margin-right: 15px;
  display: flex;
  border: 1px solid #6F7490;
}
.widget-cta-header__link .arrowleft {
  display: inline-block;
  margin-left: -10px;
  margin-right: 10px;
  font-size: 24px;
  line-height: 20px;
  color: #8F93A8;
}
.widget-cta-header.widget-cta--green {
  background-color: #c4d24c;
  color: #454b6e;
}
.widget-cta-header.widget-cta--green a.widget-cta-header__link {
  color: #181f4b;
  background-color: #fff;
  border-color: transparent;
}
.widget-cta-header.widget-cta--green a.widget-cta-header__link .arrowleft {
  color: #c4d24c;
}
.widget-cta-header.widget-cta--green a.widget-cta-header__link:hover {
  background-color: #6e7a00;
  color: #fff;
}
.widget-cta-header.widget-cta--green a.widget-cta-header__link:hover .arrowleft {
  color: #fff;
}
.widget-cta-header__content {
  flex: 1 1 auto;
  background-color: transparent !important;
}
.widget-cta-header h3, .widget-cta-header p {
  margin: 0;
}
.widget-cta-header__close {
  cursor: pointer;
  flex: none;
  width: 42px;
  height: 42px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 5px;
  line-height: 42px;
}
.widget-cta-header__close:hover > i, .widget-cta-header__close:focus > i {
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.6);
}
@media (max-width: 768px) {
  .widget-cta-header h3 {
    font-size: 18px;
  }
}
@media (max-width: 600px) {
  .widget-cta-header h3 {
    font-size: 15px;
  }
}
@media (max-width: 500px) {
  .widget-cta-header {
    /*
    p, h3
    {
      padding-left: 50px;
    }
    */
  }
  .widget-cta-header__inner {
    display: block;
    position: relative;
  }
  .widget-cta-header__link {
    margin-bottom: 10px;
    display: inline-flex;
    margin-right: 30px;
  }
  .widget-cta-header__close {
    position: absolute;
    right: 12px;
    top: 13px;
  }
}

.widget-cta-left {
  margin-right: -62px;
}
@media (max-width: 1189px) {
  .widget-cta-left {
    margin-right: -4.8vw;
  }
}
.widget-cta-left__container {
  overflow: hidden;
  max-width: 100%;
  border-radius: 4px;
}
.widget-cta-left__inner {
  display: flex;
  transition: transform 0.3s;
}
.widget-cta-left__inner > .widget-cta {
  position: relative;
  flex: none;
  width: 100%;
  margin-bottom: 0;
}
.widget-cta-left__inner > .widget-cta > a {
  padding: 25px 20px;
  border-radius: 0;
}
.widget-cta-left__inner > .widget-cta > a:hover {
  background-color: #454b6e;
}
.widget-cta-left__inner > .widget-cta > a:hover .widget-cta__button {
  background-color: #181f4b;
}
.widget-cta-left__inner > .widget-cta b {
  font-size: 18px;
  margin-bottom: 5px;
}
.widget-cta-left__inner > .widget-cta .widget-cta__button {
  margin-top: 15px;
  display: flex;
  border: 1px solid #6F7490;
  color: #fff;
  display: inline-flex;
}
.widget-cta-left__inner > .widget-cta .widget-cta__button .arrowleft {
  display: inline-block;
  margin-left: -10px;
  margin-right: 10px;
  font-size: 24px;
  line-height: 20px;
  color: #8F93A8;
}
.widget-cta-left__jmpbtns {
  padding-top: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: -8px;
}
.widget-cta-left__jmpbtns > span {
  margin-left: 8px;
  border-radius: 50%;
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #454b6e;
  cursor: pointer;
  border: 2px solid #fff;
  transition: border-color 0.3s;
}
.widget-cta-left__jmpbtns > span.active, .widget-cta-left__jmpbtns > span:hover {
  border-color: #454b6e;
}
.widget-cta-left__jmpbtns .next {
  font-size: 24px;
  cursor: pointer;
  color: #454b6e;
  margin-left: 10px;
  display: inline-block;
}
.widget-cta-left__jmpbtns .next:hover {
  text-shadow: 0 0 1px #454b6e;
}
.widget-cta-left.widget-cta--green .widget-cta > a {
  background-color: #c4d24c;
}
.widget-cta-left.widget-cta--green .widget-cta > a .widget-cta__button {
  color: #181f4b;
  background-color: #fff;
  border-color: transparent;
}
.widget-cta-left.widget-cta--green .widget-cta > a .widget-cta__button .arrowleft {
  color: #c4d24c;
}
.widget-cta-left.widget-cta--green .widget-cta > a:hover {
  background-color: #c4d24c;
}
.widget-cta-left.widget-cta--green .widget-cta > a:hover .widget-cta__button {
  background-color: #6e7a00;
  color: #fff;
}
.widget-cta-left.widget-cta--green .widget-cta > a:hover .widget-cta__button .arrowleft {
  color: #fff;
}
.widget-cta-left.widget-cta--green .widget-cta-left__jmpbtns > span {
  background-color: #c4d24c;
}
.widget-cta-left.widget-cta--green .widget-cta-left__jmpbtns > span.active, .widget-cta-left.widget-cta--green .widget-cta-left__jmpbtns > span:hover {
  border-color: #c4d24c;
}
.widget-cta-left.widget-cta--green .widget-cta-left__jmpbtns .next {
  font-size: 24px;
  color: #c4d24c;
}
.widget-cta-left.widget-cta--green .widget-cta-left__jmpbtns .next:hover {
  text-shadow: 0 0 1px #c4d24c;
}