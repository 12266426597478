/*
Themes green, blue, orange, coldgreen, purple
*/
html.theme--green {
  /* Note: button and menu colors are same as used default */
}
html.theme--green .navigation .navigation__btn > *,
html.theme--green .topsearch .topsearch__close,
html.theme--green .searchoverlay__header {
  background-color: #c4d24c;
  color: #181f4b !important;
}
html.theme--green.showsearchoverlay .topsearch .topsearch__close {
  background-color: #acc000;
}
html.theme--green.showsearchoverlay .topsearch .topsearch__close:hover {
  background-color: #6e7a00;
}

html.theme--blue .navigation .navigation__btn > *,
html.theme--blue .topsearch .topsearch__close,
html.theme--blue .searchoverlay__header {
  background-color: #8cbbe3;
  color: #181f4b !important;
}
html.theme--blue .searchoverlay__body__content .panel__result:hover {
  background-color: #e6f1f9;
}
html.theme--blue.showsearchoverlay .topsearch .topsearch__close {
  background-color: #599dd7;
}
html.theme--blue.showsearchoverlay .topsearch .topsearch__close:hover {
  background-color: #4376a1;
}
html.theme--blue .widget-contentlist__item__image {
  background-color: #599dd7;
}
html.theme--blue ul.common-linklist--lines {
  border-top-color: #599dd7;
}
html.theme--blue ul.common-linklist--lines li {
  border-bottom-color: #599dd7;
}
html.theme--blue .common-contentlist__item__text .meta {
  color: #599dd7;
}
html.theme--blue .common-contentlist__item__text .viewmore,
html.theme--blue .widget-contentlist .readmore,
html.theme--blue ul.common-linklist--lines li a:hover b,
html.theme--blue ul.common-linklist--lines li a:hover::after {
  color: #4376a1;
}
html.theme--blue .widget-studydetails__preview .icon,
html.theme--blue .widget-studydetails__preview::before, html.theme--blue .widget-studydetails__preview::after {
  color: #599dd7;
}
html.theme--blue .widget__title > .arrow,
html.theme--blue .widget-linklist .widget__title a .arrow,
html.theme--blue .navigation__submenu a.navigation__submenu__title .arrow {
  background-color: #e6f1f9;
}
html.theme--blue .widget__title > .arrow:hover,
html.theme--blue .widget-linklist .widget__title a .arrow:hover,
html.theme--blue .navigation__submenu a.navigation__submenu__title .arrow:hover {
  background-color: #8cbbe3;
}
html.theme--blue .button:not(.button--outline), html.theme--blue .wh-form__button:not(.wh-form__button--previous):not(.button--outline) {
  background-color: #8cbbe3;
}
html.theme--blue .button:not(.button--outline):hover, html.theme--blue .wh-form__button:not(.wh-form__button--previous):not(.button--outline):hover {
  background-color: #599dd7;
}
html.theme--blue p.quote::before {
  border-left-color: #599dd7;
}
html.theme--blue .widget-insetblock__inner, html.theme--blue .widget-factfigures__box {
  background-color: #8cbbe3;
}
html.theme--blue .widget-persons .person a:hover {
  color: #4376a1;
}
html.theme--blue .widget-persons .person__contact .icon {
  color: #599dd7;
}
html.theme--blue .widget-cta > a {
  background-color: #8cbbe3;
}
html.theme--blue .widget-cta > a:hover {
  background-color: #599dd7;
}
html.theme--blue .widget-cta > a .widget-cta__arrow {
  color: #599dd7;
}
html.theme--blue .navigation__submenu--cols .submenu-container > div.cta,
html.theme--blue .navigation__submenu .mobilecta, html.theme--blue.showmobilenav .navigation__submenu--cols {
  background-color: #8cbbe3;
}
html.theme--blue ul.common-linklist li::before {
  background-color: #599dd7;
}
html.theme--blue ul.common-linklist li:not(.active) a:hover, html.theme--blue ul.common-linklist li span[role]:hover,
html.theme--blue .navigation li:not(.active) > *:hover {
  color: #4376a1;
}
html.theme--blue .navigation__submenu ul.common-linklist li.active::after {
  background-color: #e6f1f9;
}

html.theme--orange .navigation .navigation__btn > *,
html.theme--orange .topsearch .topsearch__close,
html.theme--orange .searchoverlay__header {
  background-color: #ecc160;
  color: #181f4b !important;
}
html.theme--orange .searchoverlay__body__content .panel__result:hover {
  background-color: #fbf2dd;
}
html.theme--orange.showsearchoverlay .topsearch .topsearch__close {
  background-color: #e3a51a;
}
html.theme--orange.showsearchoverlay .topsearch .topsearch__close:hover {
  background-color: #aa7c14;
}
html.theme--orange .widget-contentlist__item__image {
  background-color: #e3a51a;
}
html.theme--orange ul.common-linklist--lines {
  border-top-color: #e3a51a;
}
html.theme--orange ul.common-linklist--lines li {
  border-bottom-color: #e3a51a;
}
html.theme--orange .common-contentlist__item__text .meta {
  color: #e3a51a;
}
html.theme--orange .common-contentlist__item__text .viewmore,
html.theme--orange .widget-contentlist .readmore,
html.theme--orange ul.common-linklist--lines li a:hover b,
html.theme--orange ul.common-linklist--lines li a:hover::after {
  color: #aa7c14;
}
html.theme--orange .widget-studydetails__preview .icon,
html.theme--orange .widget-studydetails__preview::before, html.theme--orange .widget-studydetails__preview::after {
  color: #e3a51a;
}
html.theme--orange .widget__title > .arrow,
html.theme--orange .widget-linklist .widget__title a .arrow,
html.theme--orange .navigation__submenu a.navigation__submenu__title .arrow {
  background-color: #fbf2dd;
}
html.theme--orange .widget__title > .arrow:hover,
html.theme--orange .widget-linklist .widget__title a .arrow:hover,
html.theme--orange .navigation__submenu a.navigation__submenu__title .arrow:hover {
  background-color: #ecc160;
}
html.theme--orange .button:not(.button--outline), html.theme--orange .wh-form__button:not(.wh-form__button--previous):not(.button--outline) {
  background-color: #ecc160;
}
html.theme--orange .button:not(.button--outline):hover, html.theme--orange .wh-form__button:not(.wh-form__button--previous):not(.button--outline):hover {
  background-color: #e3a51a;
}
html.theme--orange p.quote::before {
  border-left-color: #e3a51a;
}
html.theme--orange .widget-insetblock__inner, html.theme--orange .widget-factfigures__box {
  background-color: #ecc160;
}
html.theme--orange .widget-persons .person a:hover {
  color: #aa7c14;
}
html.theme--orange .widget-persons .person__contact .icon {
  color: #e3a51a;
}
html.theme--orange .widget-cta > a {
  background-color: #ecc160;
}
html.theme--orange .widget-cta > a:hover {
  background-color: #e3a51a;
}
html.theme--orange .widget-cta > a .widget-cta__arrow {
  color: #e3a51a;
}
html.theme--orange .navigation__submenu--cols .submenu-container > div.cta,
html.theme--orange .navigation__submenu .mobilecta, html.theme--orange.showmobilenav .navigation__submenu--cols {
  background-color: #ecc160;
}
html.theme--orange ul.common-linklist li::before {
  background-color: #e3a51a;
}
html.theme--orange ul.common-linklist li:not(.active) a:hover, html.theme--orange ul.common-linklist li span[role]:hover,
html.theme--orange .navigation li:not(.active) > *:hover {
  color: #aa7c14;
}
html.theme--orange .navigation__submenu ul.common-linklist li.active::after {
  background-color: #fbf2dd;
}

html.theme--coldgreen .navigation .navigation__btn > *,
html.theme--coldgreen .topsearch .topsearch__close,
html.theme--coldgreen .searchoverlay__header {
  background-color: #bdd9c6;
  color: #181f4b !important;
}
html.theme--coldgreen .searchoverlay__body__content .panel__result:hover {
  background-color: #f1f7f3;
}
html.theme--coldgreen.showsearchoverlay .topsearch .topsearch__close {
  background-color: #9fc8ad;
}
html.theme--coldgreen.showsearchoverlay .topsearch .topsearch__close:hover {
  background-color: #779682;
}
html.theme--coldgreen .widget-contentlist__item__image {
  background-color: #9fc8ad;
}
html.theme--coldgreen ul.common-linklist--lines {
  border-top-color: #9fc8ad;
}
html.theme--coldgreen ul.common-linklist--lines li {
  border-bottom-color: #9fc8ad;
}
html.theme--coldgreen .common-contentlist__item__text .meta {
  color: #9fc8ad;
}
html.theme--coldgreen .common-contentlist__item__text .viewmore,
html.theme--coldgreen .widget-contentlist .readmore,
html.theme--coldgreen ul.common-linklist--lines li a:hover b,
html.theme--coldgreen ul.common-linklist--lines li a:hover::after {
  color: #779682;
}
html.theme--coldgreen .widget-studydetails__preview .icon,
html.theme--coldgreen .widget-studydetails__preview::before, html.theme--coldgreen .widget-studydetails__preview::after {
  color: #9fc8ad;
}
html.theme--coldgreen .widget__title > .arrow,
html.theme--coldgreen .widget-linklist .widget__title a .arrow,
html.theme--coldgreen .navigation__submenu a.navigation__submenu__title .arrow {
  background-color: #f1f7f3;
}
html.theme--coldgreen .widget__title > .arrow:hover,
html.theme--coldgreen .widget-linklist .widget__title a .arrow:hover,
html.theme--coldgreen .navigation__submenu a.navigation__submenu__title .arrow:hover {
  background-color: #bdd9c6;
}
html.theme--coldgreen .button:not(.button--outline), html.theme--coldgreen .wh-form__button:not(.wh-form__button--previous):not(.button--outline) {
  background-color: #bdd9c6;
}
html.theme--coldgreen .button:not(.button--outline):hover, html.theme--coldgreen .wh-form__button:not(.wh-form__button--previous):not(.button--outline):hover {
  background-color: #9fc8ad;
}
html.theme--coldgreen p.quote::before {
  border-left-color: #9fc8ad;
}
html.theme--coldgreen .widget-insetblock__inner, html.theme--coldgreen .widget-factfigures__box {
  background-color: #bdd9c6;
}
html.theme--coldgreen .widget-persons .person a:hover {
  color: #779682;
}
html.theme--coldgreen .widget-persons .person__contact .icon {
  color: #9fc8ad;
}
html.theme--coldgreen .widget-cta > a {
  background-color: #bdd9c6;
}
html.theme--coldgreen .widget-cta > a:hover {
  background-color: #9fc8ad;
}
html.theme--coldgreen .widget-cta > a .widget-cta__arrow {
  color: #9fc8ad;
}
html.theme--coldgreen .navigation__submenu--cols .submenu-container > div.cta,
html.theme--coldgreen .navigation__submenu .mobilecta, html.theme--coldgreen.showmobilenav .navigation__submenu--cols {
  background-color: #bdd9c6;
}
html.theme--coldgreen ul.common-linklist li::before {
  background-color: #9fc8ad;
}
html.theme--coldgreen ul.common-linklist li:not(.active) a:hover, html.theme--coldgreen ul.common-linklist li span[role]:hover,
html.theme--coldgreen .navigation li:not(.active) > *:hover {
  color: #779682;
}
html.theme--coldgreen .navigation__submenu ul.common-linklist li.active::after {
  background-color: #f1f7f3;
}

html.theme--purple .navigation .navigation__btn > *,
html.theme--purple .topsearch .topsearch__close,
html.theme--purple .searchoverlay__header {
  background-color: #b0aad0;
  color: #181f4b !important;
}
html.theme--purple .searchoverlay__body__content .panel__result:hover {
  background-color: #eeedf5;
}
html.theme--purple.showsearchoverlay .topsearch .topsearch__close {
  background-color: #8d84bb;
}
html.theme--purple.showsearchoverlay .topsearch .topsearch__close:hover {
  background-color: #6a638c;
}
html.theme--purple .widget-contentlist__item__image {
  background-color: #8d84bb;
}
html.theme--purple ul.common-linklist--lines {
  border-top-color: #8d84bb;
}
html.theme--purple ul.common-linklist--lines li {
  border-bottom-color: #8d84bb;
}
html.theme--purple .common-contentlist__item__text .meta {
  color: #8d84bb;
}
html.theme--purple .common-contentlist__item__text .viewmore,
html.theme--purple .widget-contentlist .readmore,
html.theme--purple ul.common-linklist--lines li a:hover b,
html.theme--purple ul.common-linklist--lines li a:hover::after {
  color: #6a638c;
}
html.theme--purple .widget-studydetails__preview .icon,
html.theme--purple .widget-studydetails__preview::before, html.theme--purple .widget-studydetails__preview::after {
  color: #8d84bb;
}
html.theme--purple .widget__title > .arrow,
html.theme--purple .widget-linklist .widget__title a .arrow,
html.theme--purple .navigation__submenu a.navigation__submenu__title .arrow {
  background-color: #eeedf5;
}
html.theme--purple .widget__title > .arrow:hover,
html.theme--purple .widget-linklist .widget__title a .arrow:hover,
html.theme--purple .navigation__submenu a.navigation__submenu__title .arrow:hover {
  background-color: #b0aad0;
}
html.theme--purple .button:not(.button--outline), html.theme--purple .wh-form__button:not(.wh-form__button--previous):not(.button--outline) {
  background-color: #b0aad0;
}
html.theme--purple .button:not(.button--outline):hover, html.theme--purple .wh-form__button:not(.wh-form__button--previous):not(.button--outline):hover {
  background-color: #8d84bb;
}
html.theme--purple p.quote::before {
  border-left-color: #8d84bb;
}
html.theme--purple .widget-insetblock__inner, html.theme--purple .widget-factfigures__box {
  background-color: #b0aad0;
}
html.theme--purple .widget-persons .person a:hover {
  color: #6a638c;
}
html.theme--purple .widget-persons .person__contact .icon {
  color: #8d84bb;
}
html.theme--purple .widget-cta > a {
  background-color: #b0aad0;
}
html.theme--purple .widget-cta > a:hover {
  background-color: #8d84bb;
}
html.theme--purple .widget-cta > a .widget-cta__arrow {
  color: #8d84bb;
}
html.theme--purple .navigation__submenu--cols .submenu-container > div.cta,
html.theme--purple .navigation__submenu .mobilecta, html.theme--purple.showmobilenav .navigation__submenu--cols {
  background-color: #b0aad0;
}
html.theme--purple ul.common-linklist li::before {
  background-color: #8d84bb;
}
html.theme--purple ul.common-linklist li:not(.active) a:hover, html.theme--purple ul.common-linklist li span[role]:hover,
html.theme--purple .navigation li:not(.active) > *:hover {
  color: #6a638c;
}
html.theme--purple .navigation__submenu ul.common-linklist li.active::after {
  background-color: #eeedf5;
}