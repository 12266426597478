.homeslideshow + .widget-quicklinksbar .widget__title,
.homeslideshow + wh-beacon + .widget-quicklinksbar .widget__title {
  position: absolute;
  transform: translateY(-100%);
  padding-bottom: 15px;
}

.widget-quicklinksbar {
  position: relative;
}
.widget-quicklinksbar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  background-color: #EEEEF1;
  color: #fff;
  border-radius: 4px;
  line-height: 20px;
  padding: 30px 25px 20px;
}
@media (max-width: 600px) {
  .widget-quicklinksbar ul {
    margin: 0 -35px;
    border-radius: 0;
  }
}
@media (max-width: 465px) {
  .widget-quicklinksbar ul {
    margin: 0 -20px;
    padding: 30px 10px 10px;
  }
}
.widget-quicklinksbar ul li {
  vertical-align: top;
  display: inline-block;
  margin: 0 0 10px 10px;
  height: 32px;
}
.widget-quicklinksbar a {
  color: inherit;
  text-decoration: none;
  display: block;
  border-radius: 17px;
  height: 100%;
  padding: 5px 10px 5px 20px;
  color: #181f4b;
  background-color: #fff;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.widget-quicklinksbar a i {
  display: inline-block;
  padding-left: 5px;
  font-size: 12px;
  color: #8F93A8;
}
.widget-quicklinksbar a:hover {
  text-decoration: underline;
}
.widget-quicklinksbar__label {
  padding: 6px 15px 0 0;
  display: block;
}
.widget-quicklinksbar__scrollindicator {
  color: #6F7490;
  background-color: #fff;
  border-radius: 50%;
  width: 42px;
  height: 42px;
  position: absolute;
  top: 100%;
  left: 50%;
  font-size: 12px;
  line-height: 20px;
  padding-top: 10px;
  text-align: center;
  margin: -17px 0 0 -21px;
}
.widget-quicklinksbar__scrollindicator::before {
  display: inline-block;
  position: relative;
  animation: bounceindicator 20s ease-in-out infinite;
}
@media (max-width: 600px) {
  .widget-quicklinksbar__scrollindicator {
    display: none;
  }
}

@keyframes bounceindicator {
  0% {
    transform: translateY(0);
  }
  1.5% {
    transform: translateY(-8px);
  }
  3% {
    transform: translateY(0);
  }
  5% {
    transform: translateY(-5px);
  }
  7%, 100% {
    transform: translateY(0);
  }
}