.wh-counter
{
  display: inline-block;
  white-space: nowrap;
  position: absolute;
  bottom: 5px;
  right: 5px;
  padding: 0 7px;
  pointer-events: none;
  font-size: 80%;
  height: 20px;
  color: #000;
  background-color: #f1f1f1;
  border-radius: 10px;
  line-height: 20px;
  opacity: 0;
  transition: opacity .15s;
}

  .wh-counter__separator
, .wh-counter__limit
{
  display: none;
}

  .wh-counter--havelimit .wh-counter__separator
, .wh-counter--havelimit .wh-counter__limit
, .wh-counter--haveminvalue .wh-counter__separator
, .wh-counter--haveminvalue .wh-counter__limit
{
  display: inline;
}

.wh-counter__separator
{
  display: inline-block;
  padding: 0 3px;
}
.wh-counter--hasfocus
{
  opacity: 0.8;
}

.wh-counter.wh-counter--underflow
{
  background-color: #C22121;
  opacity: 1;
}
.wh-counter.wh-counter--overflow
{
  background-color: #C22121;
  opacity: 1;
}
.wh-rtd__editor .wh-counter
{
  right: 14px;
}

.wh-forumcomments .wh-form__fieldgroup--textarea .wh-form__fieldline
{
  position: relative;
}
