@font-face {
  font-family: 'PThU-iconfont-12px';
  src: 
    url("fonts/PThU-iconfont-12px.woff?xogki") format("woff2"),
    url('fonts/PThU-iconfont-12px.woff?xogki') format('woff'),
    url('fonts/PThU-iconfont-12px.ttf?xogki') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="PThU-icon-12px-"], [class*=" PThU-icon-12px-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'PThU-iconfont-12px' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.PThU-icon-12px-left:before {
  content: "\e900";
}
.PThU-icon-12px-right:before {
  content: "\e901";
}
.PThU-icon-12px-down:before {
  content: "\e902";
}
.PThU-icon-12px-up:before {
  content: "\e903";
}
.PThU-icon-12px-plus:before {
  content: "\e904";
}
.PThU-icon-12px-min:before {
  content: "\e905";
}
.PThU-icon-12px-check:before {
  content: "\e906";
}
.PThU-icon-12px-cross:before {
  content: "\e907";
}
