.widget-widgetgroup {
  margin-top: 60px;
}
.widget-widgetgroup.widget {
  margin-bottom: 0;
}
.widget-widgetgroup__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  margin-left: -35px;
}
.widget-widgetgroup__list__item {
  padding-left: 35px;
  margin-bottom: 60px;
  flex: 1 0 33.3333%;
  max-width: 33.3333%;
}
.widget-widgetgroup__list__item:empty {
  display: none;
}
.widget-widgetgroup__list__item[data-cols="50%"] {
  flex-basis: 50%;
  max-width: 50%;
}
.widget-widgetgroup__list__item[data-cols="2"] {
  flex-basis: 66.6666%;
  max-width: 66.6666%;
}
.widget-widgetgroup__list__item[data-cols="3"] {
  flex-basis: 100%;
  max-width: 100%;
}
.widget-widgetgroup__list__item > .widget {
  height: 100%;
  margin-bottom: 0;
}
@media (max-width: 850px) {
  .widget-widgetgroup__list__item {
    flex-basis: 50%;
    max-width: 50%;
  }
  .widget-widgetgroup__list__item[data-cols="2"], .widget-widgetgroup__list__item[data-cols="3"] {
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media (max-width: 550px) {
  .widget-widgetgroup__list__item {
    flex-basis: 100%;
    max-width: 100%;
  }
}

.main__col--right .widget-widgetgroup__list__item {
  flex: 1 0 50%;
  max-width: 50%;
}
@media (max-width: 900px) {
  .main__col--right .widget-widgetgroup__list__item {
    flex: none;
    max-width: 100%;
  }
}

.widget-widgetgroup + .widget {
  margin-top: 0;
}

.sectionstartpage-intro + .widget-widgetgroup {
  margin-top: -30px;
}