/* Most of the basic video styling is loaded by shared/rtd/rtd.es, @mod-publisher/js/richcontent/all */
.wh-video {
  margin-bottom: 30px;
}
.wh-video__innerframe {
  background-color: #000;
}
.wh-video__innerframe__preview::after {
  content: "";
  display: block;
  background-color: #000000;
  opacity: 0.2;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.2s;
}
.wh-video:hover .wh-video__innerframe__preview::after {
  opacity: 0;
}
.wh-video__playbutton {
  display: inline-block;
  background-color: #e3a51a;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  margin: -32px 0 0 -32px;
  position: absolute;
  left: 50%;
  top: 50%;
  transition: transform 0.2s;
}
.wh-video__playbutton::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -11px;
  margin-left: -6px;
  border-left: 18px solid #fff;
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
}
.wh-video:hover .wh-video__playbutton {
  transform: scale(1.1, 1.1);
}

.widget-video {
  border-radius: 4px;
  cursor: pointer;
}
.widget-video:not(.widget-video--compact) {
  border: 1px solid #8F93A8;
}
.widget-video img {
  vertical-align: bottom;
  width: 100%;
  height: auto;
  border-radius: 4px 4px 0 0;
}
.widget-video.widget-video--compact img {
  border-radius: 4px;
}
.widget-video__preview {
  text-align: right;
  position: relative;
}
.widget-video__playbutton {
  display: inline-block;
  background-color: #e3a51a;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  margin: -32px 30px 0;
  position: relative;
  transition: transform 0.2s;
}
.widget-video__playbutton::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -11px;
  margin-left: -6px;
  border-left: 18px solid #fff;
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
}
.widget-video.widget-video--compact .widget-video__playbutton {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -32px;
  margin-top: -32px;
}
.widget-video:hover .widget-video__playbutton {
  transform: scale(1.1, 1.1);
}
.widget-video__details {
  padding: 0 25px 40px;
  line-height: 25px;
}
.widget-video.widget-video--compact .widget-video__details {
  padding: 10px 0 0;
  text-align: right;
}
.widget-video__title {
  font-weight: 700;
  font-size: 18px;
}
.widget-video.widget-video--compact .widget-video__title {
  font-weight: 400;
  font-size: inherit;
  color: #6F7490;
}
.widget-video__subtitle {
  padding-top: 5px;
}
.widget-video__description {
  padding-top: 20px;
}