.widget-infotabs {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.widget-infotabs__images, .widget-infotabs__content {
  flex: none;
  width: calc(50% - 18px);
}
.widget-infotabs__images {
  display: flex;
  flex-flow: column;
  position: relative;
}
.widget-infotabs__images__image {
  display: inline-block;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
}
.widget-infotabs__images__image::before {
  content: "";
  display: block;
  padding-top: 100%;
}
.widget-infotabs__images__image:first-child {
  width: 31%;
  left: 14%;
}
.widget-infotabs__images__image:nth-child(2) {
  width: 36.5%;
  left: -7%;
}
.widget-infotabs__images__image:nth-child(3) {
  margin-top: 18px;
  width: 58.5%;
  left: -8%;
}
.widget-infotabs__images__image:nth-child(4) {
  position: absolute;
  width: 63%;
  left: 34%;
  top: 16%;
}
.widget-infotabs__images__image img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.widget-infotabs__tabs {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  white-space: nowrap;
}
.widget-infotabs__tabs__separator {
  color: #6e7a00;
  display: inline-block;
  padding: 0 clamp(10px, 2vw, 20px);
}
.widget-infotabs li + li::before {
  content: "|";
  color: #6e7a00;
  display: inline-block;
  padding: 0 clamp(10px, 2vw, 20px);
}
.widget-infotabs__tab button {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font: inherit;
  color: #6e7a00;
  text-decoration: none;
  white-space: nowrap;
  transition: color 300ms;
}
.widget-infotabs__tab:hover button, .widget-infotabs__tab--active button {
  color: #181f4b;
}
.widget-infotabs__tab--active button {
  text-shadow: 0 0 1px #181f4b;
}
.widget-infotabs__panels {
  display: flex;
  align-items: stretch;
}
.widget-infotabs__panel {
  width: 100%;
  display: block;
  background-color: #fff;
  transition: opacity 300ms;
}
.widget-infotabs__panel[hidden] {
  display: block;
  opacity: 0;
  z-index: -1;
  pointer-events: none;
}
.widget-infotabs__panel + .widget-infotabs__panel {
  margin-left: -100%;
}
@media (max-width: 900px) {
  .widget-infotabs {
    display: block;
  }
  .widget-infotabs__images, .widget-infotabs__content {
    width: 100%;
  }
  .widget-infotabs__images__image:first-child {
    width: 13%;
    left: 18%;
  }
  .widget-infotabs__images__image:nth-child(2) {
    margin-top: 1%;
    width: 18%;
    left: 6%;
  }
  .widget-infotabs__images__image:nth-child(3) {
    margin-top: -22%;
    width: 29%;
    left: 28%;
  }
  .widget-infotabs__images__image:nth-child(4) {
    width: 35%;
    left: 61%;
    top: 6%;
  }
  .widget-infotabs__content {
    margin-top: 30px;
  }
}