.purepressmedia {
  display: block;
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-bottom: 30px;
}
.purepressmedia__year {
  border-bottom: 1px solid #C5C5C5;
}
.purepressmedia .wh-details__summary {
  font-size: 18px;
}
.purepressmedia__year__content {
  list-style-type: none;
  margin: 0;
  padding: 0;
  line-height: 140%;
}
.purepressmedia__year__content li {
  padding-bottom: 20px;
}
.purepressmedia .pressmedia a {
  text-decoration: none;
  color: inherit;
  display: block;
}
.purepressmedia .pressmedia a:hover {
  text-decoration: underline;
}
.purepressmedia .pressmedia__title {
  font-weight: 700;
}
.purepressmedia .pressmedia__meta {
  margin-top: 3px;
}