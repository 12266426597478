.sharepage ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.sharepage ul li + li {
  margin-left: 15px;
}
.sharepage ul .wh-share {
  cursor: pointer;
  display: inline-block;
  color: #4376a1;
  width: 48px;
  height: 48px;
  line-height: 48px;
  border-radius: 50%;
  transition: background-color 0.2s;
  opacity: 1;
  text-align: center;
  background-color: rgba(89, 157, 215, 0.15);
  font-size: 24px;
}
.sharepage ul .wh-share:hover {
  background-color: rgba(89, 157, 215, 0.3);
}