.widget-infoblock {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.widget-infoblock__images, .widget-infoblock__content {
  flex: 1 1 50%;
  max-width: 50%;
}
.widget-infoblock__content {
  padding-left: 18px;
}
.widget-infoblock__images {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: flex-start;
  pointer-events: none;
}
.widget-infoblock__image {
  display: inline-block;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
}
.widget-infoblock__image::after {
  content: "";
  display: block;
  padding-top: 100%;
}
.widget-infoblock__image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.widget-infoblock__image--one {
  width: 300px;
  margin-top: -70px;
  margin-left: -7%;
}
.widget-infoblock__image--one img {
  transform: translateY(23%);
}
.widget-infoblock__image--two {
  width: 200px;
  margin-left: -15%;
}
.widget-infoblock__image--three {
  width: 400px;
  margin-left: 25%;
  margin-top: -45%;
}
@media (max-width: 1189px) {
  .widget-infoblock__image--one {
    margin-top: -5.5vw;
    width: 25vw;
    height: 25vw;
  }
  .widget-infoblock__image--two {
    width: 16.8vw;
    height: 16.8vw;
  }
  .widget-infoblock__image--three {
    width: 33.6vw;
    height: 33.6vw;
  }
}
.widget-infoblock__linklist {
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding-bottom: 10px;
  color: #687500;
}
.widget-infoblock__linklist a {
  text-decoration: none;
  color: inherit;
  transition: color 0.2s;
}
.widget-infoblock__linklist a:hover {
  color: #181f4b;
  text-shadow: 0 0 1px #181f4b;
}
.widget-infoblock__linklist li {
  display: inline-block;
}
.widget-infoblock__linklist li + li::before {
  content: "|";
  display: inline-block;
  padding: 0 5px;
}
@media (max-width: 900px) {
  .widget-infoblock {
    display: block;
  }
  .widget-infoblock__images {
    display: block;
    height: 50vw;
    margin-left: -35px;
    margin-right: -35px;
    position: relative;
    overflow: hidden;
  }
  .widget-infoblock__images, .widget-infoblock__content {
    max-width: none;
  }
  .widget-infoblock__image {
    position: absolute;
  }
  .widget-infoblock__image--one {
    margin-top: 0;
    margin-left: 0;
    right: 10vw;
    top: 2vw;
    width: 33.333vw;
    height: 33.333vw;
  }
  .widget-infoblock__image--one img {
    transform: translateY(0);
  }
  .widget-infoblock__image--two {
    margin-top: 0;
    margin-left: 0;
    left: 35vw;
    top: 25vw;
    width: 22.222vw;
    height: 22.222vw;
  }
  .widget-infoblock__image--three {
    margin-top: 0;
    margin-left: 0;
    left: -5vw;
    top: -10vw;
    width: 44.444vw;
    height: 44.444vw;
  }
}
@media (max-width: 465px) {
  .widget-infoblock__images {
    margin-left: -20px;
    margin-right: -20px;
  }
}