@charset "UTF-8";
html {
  background-color: #fff;
  min-height: 100%;
  display: flex;
}

body {
  -webkit-font-smoothing: antialiased;
  flex-direction: row; /* IE10/11 Workaround for child elements with flex-direction: column to outfill content area (main) */
  overflow: hidden;
  overflow-y: auto;
  width: 100vw;
  max-width: 100vw;
  min-height: 100vh;
  position: relative;
}
html.mobilelayout.showmobilemenu body, html.hidescroll body {
  overflow: hidden;
}

html.showsearchoverlay .sitewrapper > *:not(header),
html.mobilelayout.showmobilemenu .sitewrapper {
  visibility: hidden;
}

html.showsearchoverlay .header__inner > *:not(.topsearch) {
  visibility: hidden;
}

*:focus-visible {
  outline: 1px solid #181f4b;
  outline-offset: 2px;
}

.PThU-icon-24px-twitter::before {
  content: "𝕏" !important;
  font-size: 100%;
  font-weight: 400;
  font-family: Arial;
}

a.skipheader {
  position: absolute !important;
  height: 0;
  width: 0;
  clip: rect(1px, 1px, 1px, 1px);
  outline: none;
  left: calc(50% - 559px);
}
@media (max-width: 1250px) {
  a.skipheader {
    left: 35px;
  }
}
@media (max-width: 750px) {
  a.skipheader {
    left: 20px;
  }
}

a.skipheader.button:focus {
  clip: auto;
  height: 42px;
  width: auto;
  overflow: auto;
  top: 110px;
  border: 1px solid #fff;
  z-index: 2;
}

.wh-anchor {
  scroll-margin-top: 105px;
}

header, footer {
  flex: none;
}

html.beforeload * {
  transition-duration: 0s !important;
}

.adaptivecontainer-mobile {
  display: none;
}
.adaptivecontainer-mobile .widget-cta-left {
  margin-right: 0;
}
@media (max-width: 800px) {
  .adaptivecontainer-mobile {
    display: block;
  }
}

.sitewrapper {
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
}

main {
  flex: 1 0 auto; /* vertical (column) outfill */
  max-width: 100%;
  padding: 0;
  margin-top: 40px;
  position: relative;
}
main:not(.main--hasleftcolumn) > *:not(.fullwidth) {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1189px;
  padding-left: 35px;
  padding-right: 35px;
}
@media (max-width: 465px) {
  main:not(.main--hasleftcolumn) > *:not(.fullwidth) {
    padding-left: 20px;
    padding-right: 20px;
  }
}
html.mediumwidth main:not(.main--hasleftcolumn) > *:not(.fullwidth) {
  max-width: 1000px;
}

html.projectpage .main--hasleftcolumn .main__col--right > .fullwidthxxx {
  margin-left: -50%;
}

html.projectpage .main--hasleftcolumn {
  /*
    On projectpage, span fullwidth items over left column
  */
}
@media (min-width: 801px) {
  html.projectpage .main--hasleftcolumn .main__col--right > div.fullwidth {
    margin-right: -35px;
    margin-left: calc(-50% - 53px);
  }
}

.main--hasleftcolumn {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1189px;
  padding-left: 35px;
  padding-right: 35px;
}
@media (max-width: 465px) {
  .main--hasleftcolumn {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.main--hasleftcolumn .main__col--left {
  flex: none;
  width: 33.3333%;
  padding-right: 7.5%;
}
.main--hasleftcolumn .main__col--left .imageleft {
  width: 230px;
  max-width: calc(100% - 35px);
  margin: 0 0 60px auto;
}
.main--hasleftcolumn .main__col--left .imageleft__container {
  position: relative;
}
.main--hasleftcolumn .main__col--left .imageleft img {
  border-radius: 50%;
  width: 100%;
  height: auto;
}
.main--hasleftcolumn .main__col--left .imageleft__playvideo {
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: pointer;
  display: inline-block;
  background-color: #181f4b;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  transition: transform 0.2s;
}
.main--hasleftcolumn .main__col--left .imageleft__playvideo:hover {
  transform: scale(1.1, 1.1);
}
.main--hasleftcolumn .main__col--left .imageleft__playvideo::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -11px;
  margin-left: -6px;
  border-left: 18px solid #fff;
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
}
.main--hasleftcolumn .main__col--right {
  flex: 1 1 auto;
  max-width: 66.666%;
  padding-left: 12px;
}
@media (max-width: 800px) {
  .main--hasleftcolumn {
    display: block;
  }
  .main--hasleftcolumn .main__col--left {
    max-width: 0;
  }
  .main--hasleftcolumn .main__col--left > *:not(.imageleft) {
    display: none;
  }
  .main--hasleftcolumn .main__col--left .imageleft {
    width: 350px;
    position: absolute;
    top: -200px;
    right: -52px;
    margin: 0;
  }
  .main--hasleftcolumn .main__col--left .imageleft__playvideo {
    right: auto;
    bottom: auto;
    left: -8px;
    top: 96px;
    width: 45px;
    height: 45px;
  }
  .main--hasleftcolumn .main__col--left .imageleft__playvideo::after {
    margin-top: -9px;
    margin-left: -5px;
    border-left-width: 14px;
    border-top-width: 8px;
    border-bottom-width: 8px;
  }
  html.showmobilenav .main--hasleftcolumn .main__col--left .imageleft {
    top: -200px;
  }
  html.showmobilenav .main--hasleftcolumn .main__col--left .imageleft__playvideo {
    position: absolute;
    left: auto;
    right: 280px;
    top: 93px;
  }
  html.showmobilenav .main--hasleftcolumn .main__col.haspageimage + .main__col--right {
    margin-top: 120px;
  }
  .main--hasleftcolumn .main__col--right {
    background-color: #fff;
    position: relative;
    padding-top: 10px;
    border-radius: 4px;
    padding-left: 0;
    max-width: 100%;
  }
}
@media (max-width: 750px) {
  html.showmobilenav .main--hasleftcolumn .main__col--left .imageleft {
    top: -30px;
  }
}
@media (max-width: 600px) {
  html.showmobilenav .main--hasleftcolumn .main__col--left .imageleft {
    width: 58vw;
    top: calc(52px - 10vw);
    right: -6.5vw;
  }
  html.showmobilenav .main--hasleftcolumn .main__col--left .imageleft__playvideo {
    right: 47vw;
  }
}

.centercontent {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1189px;
  padding-left: 35px;
  padding-right: 35px;
}
@media (max-width: 465px) {
  .centercontent {
    padding-left: 20px;
    padding-right: 20px;
  }
}

/* breadcrumblist */
.breadcrumblist {
  margin-bottom: 20px;
}
.breadcrumblist ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  line-height: 20px;
  font-size: 12px;
}
.breadcrumblist li {
  display: inline-block;
}
.breadcrumblist li + li::before {
  color: #6F7490;
  content: "/";
  padding: 0 6px;
  display: inline-block;
}
.breadcrumblist span {
  color: #6F7490;
}
.breadcrumblist a {
  color: inherit;
  text-decoration: none;
}
.breadcrumblist a:hover {
  text-decoration: underline;
}

/* sectionstartpage-intro */
.sectionstartpage-intro {
  display: flex;
}
.sectionstartpage-intro__col {
  flex: 1 1 50%;
  max-width: 50%;
}
.sectionstartpage-intro__col p {
  font-size: 18px;
  line-height: 140%;
}
.sectionstartpage-intro__col--text {
  padding-right: 35px;
  padding-bottom: 100px;
}
.sectionstartpage-intro__col .image {
  width: 240px;
  position: relative;
  top: -11%;
}
.sectionstartpage-intro__col .image::before {
  content: "";
  display: block;
  padding-top: 100%;
}
.sectionstartpage-intro__col .image img {
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.sectionstartpage-intro__col--images {
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  justify-content: flex-start;
  position: relative;
  margin-top: -80px;
}
.sectionstartpage-intro__col--images::before {
  content: "";
  display: block;
  padding-top: 90%;
}
.sectionstartpage-intro__col--images .image {
  position: absolute;
  top: 0;
  left: 0;
}
.sectionstartpage-intro__col--images .image:first-child {
  transform: translate(30%, 0);
}
.sectionstartpage-intro__col--images .image:nth-child(2) {
  transform: translate(145%, 30%);
}
.sectionstartpage-intro__col--images .image:nth-child(3) {
  transform: translate(65%, 110%);
}
@media (max-width: 1189px) {
  .sectionstartpage-intro__col--images .image {
    width: 20.2vw;
  }
}
@media (max-width: 900px) {
  .sectionstartpage-intro__col--text {
    padding-right: 0;
  }
}
@media (max-width: 850px) {
  .sectionstartpage-intro__col--text {
    min-width: 390px;
    max-width: 100%;
  }
  .sectionstartpage-intro__col--images {
    left: -20px;
  }
}
@media (max-width: 750px) {
  .sectionstartpage-intro__col--images {
    margin-top: 0;
  }
}
@media (max-width: 550px) {
  .sectionstartpage-intro {
    flex-flow: column;
  }
  .sectionstartpage-intro__col {
    flex-flow: column;
  }
  .sectionstartpage-intro__col--text {
    padding-top: 10px;
    order: 1;
    min-width: 100%;
    border-radius: 4px;
    background-color: #fff;
    position: relative;
    z-index: 0;
  }
  .sectionstartpage-intro__col--images {
    order: 0;
    left: auto;
    justify-content: flex-end;
    flex-basis: auto;
    max-width: 100%;
    z-index: -1;
  }
  .sectionstartpage-intro__col--images::before {
    padding-top: 14.5vw;
  }
  .sectionstartpage-intro__col--images .image:first-child {
    transform: none;
    left: auto;
    right: -15vw;
    top: -10vw;
    width: 45vw;
  }
  .sectionstartpage-intro__col--images .image ~ .image {
    display: none;
  }
}

.fullwidthwrapper {
  position: relative;
  margin: 60px 0;
}
.fullwidthwrapper > .widget {
  position: absolute;
  top: 0;
  margin: 0;
}

.widget-widgetgroup + .fullwidthwrapper,
.widget-widgetgroup + .inlinewidget-infopopup-popup + .fullwidthwrapper {
  margin-top: 0;
}

/* consent message */
.cookieconsent-message {
  max-width: 665px;
}
.cookieconsent-message__buttons {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
}
.cookieconsent-message__preferences .cookieconsent-message__buttons {
  justify-content: center;
}
.cookieconsent-message__acceptbtn.button, .cookieconsent-message__acceptpreferences.button {
  font-weight: 700;
  margin-right: 20px;
}
.cookieconsent-message__acceptbtn.button {
  margin-bottom: 10px;
}
.cookieconsent-message__acceptpreferences.button {
  margin-top: 30px;
  margin-bottom: 10px;
}
.cookieconsent-message__settingslink {
  color: #4376A1;
  white-space: nowrap;
}
.cookieconsent-message__settingslink .icon {
  font-size: 24px;
}
.cookieconsent-message__settingslink .label {
  cursor: pointer;
  padding-left: 5px;
  text-decoration: underline;
}
.cookieconsent-message__group {
  padding: 15px 0;
  border-bottom: 1px solid #CDD966;
}
.cookieconsent-message .togglebutton__label::before {
  content: "Uit";
  display: block;
  position: absolute;
  top: 0;
  right: 10px;
}
html[lang=en] .cookieconsent-message .togglebutton__label::before {
  content: "Off";
}
.cookieconsent-message input[type=checkbox]:checked + .togglebutton__label::before {
  right: auto;
  left: 8px;
  content: "Aan";
}
html[lang=en] .cookieconsent-message input[type=checkbox]:checked + .togglebutton__label::before {
  content: "On";
  left: 10px;
}
.cookieconsent-message__group__row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.cookieconsent-message__group__row .text {
  flex: 1 1 auto;
  padding-right: 10px;
}
.cookieconsent-message__group__row .text > *:last-child {
  margin-bottom: 0;
}
.cookieconsent-message__group__row .togglebutton {
  flex: none;
}
@media (max-width: 450px) {
  .cookieconsent-message__group__row {
    flex-flow: column;
  }
  .cookieconsent-message__group__row .text {
    margin-bottom: 10px;
    padding-right: 0;
  }
  .cookieconsent-message__group__row .togglebutton {
    align-self: flex-end;
  }
}

/* small animation fx when an element with this class gets into view while scrolling */
.scrollfx {
  position: relative;
  transform: translate3d(0, 70px, 0);
  opacity: 0.1;
  transition-property: transform, opacity;
  transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}
.scrollfx--fromtop {
  transform: translate3d(0, -70px, 0);
}
html.wh-widgetpreview .scrollfx--done, .scrollfx--done {
  transform: translate3d(0, 0, 0);
  opacity: 0.999;
}

/* horizontal perfect slider scrollbar */
.scrollbar-horizontal.ps.nativescroll {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.scrollbar-horizontal.ps > .ps__rail-x {
  margin-left: 35px;
  background-color: rgba(104, 117, 0, 0.2) !important;
  opacity: 1 !important;
  height: 6px;
  border-radius: 0;
}
@media (max-width: 465px) {
  .scrollbar-horizontal.ps > .ps__rail-x {
    margin-left: 20px;
  }
}
.scrollbar-horizontal.ps > .ps__rail-x > .ps__thumb-x {
  bottom: 0;
  background-color: #687500;
  height: 6px;
  border-radius: 0;
}