.widget-fullwidthimage__container {
  margin: 40px auto 35px;
}
.widget-fullwidthimage__imagewrapper {
  position: relative;
}
.widget-fullwidthimage__image {
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}
.widget-fullwidthimage__image img {
  width: 100%;
  height: 100%;
  vertical-align: bottom;
}
.widget-fullwidthimage--circle .widget-fullwidthimage__image {
  border-radius: 50%;
}
.widget-fullwidthimage__caption {
  color: #6F7490;
  padding-top: 15px;
  text-align: right;
  font-size: 12px;
  line-height: 135%;
}
.widget-fullwidthimage--circle .widget-fullwidthimage__caption {
  text-align: center;
}